const { EChart } = require('/b2b/common/components/Charts');

import {
    BAR_CHART_TOOLTIP,
    COMMON_OPTIONS,
    TIME_XAXIS_OPTIONS,
    VERTICAL_YAXIS_OPTIONS,
} from '/b2b/dsarReporting/constants';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { makeActionItemFilters, makeCSV } from '../helpers';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { DSAR_ACTION_ITEM_STATUS_KEYS } from '/b2b/dsarActionItems/constants';
import PropTypes from 'prop-types';
import ReportingContext from '../Reporting.context';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchDSARReportingData } from '/b2b/dsarReporting/services';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'completedActionItems';

export const FIELDS = {
    automated: 'automated',
    manual: 'manual',
};

const LABELS = {
    [FIELDS.automated]: 'dsar.reporting.charts.completedActionItems.label.automated',
    [FIELDS.manual]: 'dsar.reporting.charts.completedActionItems.label.manual',
};

const staticOptions = {
    ...COMMON_OPTIONS,
    ...VERTICAL_YAXIS_OPTIONS,
    xAxis: {
        ...COMMON_OPTIONS.xAxis,
        ...TIME_XAXIS_OPTIONS.xAxis,
    },
    yAxis: {
        ...COMMON_OPTIONS.yAxis,
        ...VERTICAL_YAXIS_OPTIONS.yAxis,
    },
    tooltip: BAR_CHART_TOOLTIP,
};

const CompletedActionItems = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data = {}, error, load, loading } = useContext(ReportingContext);
    const reportData = data[REPORT_NAME] || [];
    const noResults = reportData.length === 0;
    const isLoading = loading || providedLoading;

    const theme = useTheme();

    const memoOption = useMemo(
        () =>
            merge({}, staticOptions, {
                grid: {
                    left: parseInt(theme.spacing(5.5), 10),
                    right: parseInt(theme.spacing(4.5), 10),
                },
                legend: {
                    data: Object.values(LABELS).map(translate),
                    left: theme.spacing(1.5),
                },
                dataset: {
                    source: reportData,
                },
                yAxis: {
                    name: translate('dsar.reporting.common.numberOfActionItems'),
                    nameGap: parseInt(theme.spacing(5.5), 10),
                    show: reportData.length > 0,
                },
                series: Object.entries(LABELS).map(([field, path]) => ({
                    dimensions: [
                        { name: 'date', type: 'time' },
                        { name: field, type: 'int' },
                    ],
                    name: translate(path),
                    stack: 'x',
                    type: 'bar',
                })),
            }),
        [reportData, shouldRetranslate]
    );

    const fetchData = useBoundCallback(
        filters => fetchDSARReportingData({ ...filters, reports: [REPORT_NAME] }),
        [filters]
    );

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData, filters]);

    const echartsInstance = useRef(null);
    const helpers = useMemo(
        () => ({
            makeCSV: () => makeCSV(reportData, LABELS),
            name: 'completed-action-items',
        }),
        [reportData]
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    const onDrilldownClick = useBoundCallback(
        drilldownFilters => {
            window.open(
                `${AuthenticatedPaths.DSAR_ACTION_ITEMS}?${makeActionItemFilters(drilldownFilters)}`,
                '_blank'
            );
        },
        [
            [
                {
                    ...filters,
                    status: DSAR_ACTION_ITEM_STATUS_KEYS.COMPLETED,
                },
                providedData,
            ],
        ]
    );
    const downloadFormats = [
        'csv',
        'png',
        { label: 'dsar.reporting.charts.actionItems.list', onClick: onDrilldownClick },
    ];
    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={downloadFormats}
            echartsInstance={echartsInstance}
            error={error}
            title="dsar.reporting.charts.completedActionItems.title"
            tooltip="dsar.reporting.charts.completedActionItems.tooltip"
        >
            <EChart
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </WidgetContainer>
    );
};

CompletedActionItems.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

export default withAsyncData(withRetranslate(CompletedActionItems), ReportingContext);
