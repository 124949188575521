import { makeResultsFormatter } from '/b2b/common/services/helpers';
import { v4 as uuid } from 'uuid';

const ASSIGNMENTS_BY_STATUS_FIELDS = {
    notstarted: 'notStarted',
    inprogress: 'inProgress',
    completed: 'completed',
    overdue: 'overdue',
};

function formatAssignmentStatusByAssignee(data) {
    return {
        ...data,
        id: uuid(),
    };
}

function formatCustomerTemplates(data) {
    const { name, templateId } = data;
    return {
        ...data,
        id: templateId,
        templateName: name,
    };
}

export function formatAssessmentsReportingParams(filters = {}) {
    const { dateRange, templates, reports } = filters;

    const queryStringParameters = {};

    if (dateRange) {
        if (dateRange[0]) {
            queryStringParameters.startDate = dateRange[0];
        }
        if (dateRange[1]) {
            queryStringParameters.endDate = dateRange[1];
        }
    }

    if (reports) {
        queryStringParameters.reports = JSON.stringify(reports);
    }

    if (templates?.length > 0) {
        const templateIds = templates.map(({ value }) => value);
        queryStringParameters.templateIds = JSON.stringify(templateIds);
    }

    return queryStringParameters;
}

export function formatAssessmentsReportingData(data) {
    const {
        assignmentsByStatus = {},
        assignmentStatusByAssignee = {},
        customerTemplates = {},
        privacyScoresVendorsAssessed = {},
    } = data;

    return {
        ...data,
        ...(Object.keys(assignmentsByStatus).length > 0 && {
            assignmentsByStatus: Object.entries(assignmentsByStatus).reduce(
                (accumulator, [key, value]) => {
                    accumulator.push({
                        name: ASSIGNMENTS_BY_STATUS_FIELDS[key],
                        value,
                    });

                    return accumulator;
                },
                []
            ),
        }),
        ...(Object.keys(privacyScoresVendorsAssessed).length > 0 && {
            privacyScoresVendorsAssessed: Object.entries(privacyScoresVendorsAssessed).reduce(
                (accumulator, [key, value]) => {
                    accumulator.push({
                        name: key,
                        value,
                    });

                    return accumulator;
                },
                []
            ),
        }),
        ...(Object.keys(assignmentStatusByAssignee).length && {
            assignmentStatusByAssignee: makeResultsFormatter(formatAssignmentStatusByAssignee)(
                assignmentStatusByAssignee
            ),
        }),
        ...(Object.keys(customerTemplates).length && {
            customerTemplates: makeResultsFormatter(formatCustomerTemplates)(customerTemplates),
        }),
    };
}
