import { applyMiddleware, compose, createStore } from 'redux';

import { BROWSE_CUSTOMER } from './common/services/customer';
import DBActions from './common/state/db/DB.actions';
import { types as NavActionTypes } from './common/state/nav/Nav.actions';
import customerSchema from './common/state/db/customer/Customer.schema';
import { formatCustomer } from './common/services/format';
import fromState from './common/state/selectors';
import merge from 'lodash/merge';
import middleware from './common/middleware';
import reduceReducers from 'reduce-reducers';
import reduxThunk from 'redux-thunk';
import rootReducer from './common/state/reducer';

const isDev = ['dev', 'development', 'ci'].includes(process.env.MODE);

const ignoredActions = ['Amplify'];
if (!isDev) {
    ignoredActions.push('ui/');
    ignoredActions.push('DB/UPDATE');
    ignoredActions.push('@@');
}

export const localStorageKey = 'state';

const getStorageState = () => {
    try {
        const serializedState = localStorage.getItem(localStorageKey);
        if (serializedState === null) {
            return {};
        }
        return JSON.parse(serializedState) || {};
    } catch (err) {
        return {};
    }
};

export const loadState = () => {
    try {
        const { persist = {}, ...newState } = getStorageState();
        // Get the stored customer ID
        const customerId = fromState.Auth.customer.getId(newState);
        if (customerId) {
            const customerPersist = persist[customerId];
            if (customerPersist) {
                return merge({}, newState, customerPersist);
            }
        }
        return newState;
    } catch (err) {
        return {};
    }
};

let savedState = '';
export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(localStorageKey, serializedState);
        savedState = serializedState;
    } catch {
        // ignore write errors
    }
};

const initialState = loadState();
const reducer = reduceReducers((state, action) => {
    // This reducer is used to recover customer persitent data after a customer switch
    switch (action.type) {
        case NavActionTypes.changeCustomer: {
            return merge({}, state, loadState());
        }
    }
    return state;
}, rootReducer);

/**
 * Initializes a new Redux store using provided initialState
 *
 * @param {Object} initialState
 * @return {import("redux").Store} A new Redux store
 */
export const configureStore = initialState => {
    const middlewares = [...middleware, reduxThunk];
    const composeEnhancers =
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && // add support for Redux dev tools
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                actionsDenylist: ignoredActions,
            })) ||
        compose;
    let store;
    try {
        store = createStore(
            reducer,
            initialState,
            composeEnhancers(applyMiddleware(...middlewares))
        );
    } catch (error) {
        // Bad local storage store
        store = createStore(reducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
    }

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./common/state/reducer', () => {
            const nextRootReducer = require('./common/state/reducer');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
};

const store = configureStore(initialState);

store.dispatch(
    DBActions.init({
        [customerSchema.key]: [BROWSE_CUSTOMER].map(formatCustomer),
    })
);

export const onStateUpdate = providedState => {
    const state = providedState || store.getState();
    const rememberMe = fromState.Auth.shouldRemember(state);
    const customerId = fromState.Auth.customer.getId(state);
    const { persist = {} } = getStorageState();
    if (customerId && !rememberMe) {
        // Clear customer specific data when rememberMe is disabled
        delete persist[customerId];
    } else if (customerId) {
        // Persist customer specific data inside this object. It will be merged with the main state upon customer switch
        persist[customerId] = {
            assessmentsReporting: {
                filters: fromState.AssessmentsReporting.getFilters(state),
            },
            dashboard: {
                preferences: fromState.Dashboard.getPreferences(state),
            },
            dsarActionItemList: {
                columnVisibility: fromState.DSARActionItemList.getColumnVisibility(state),
                columnOrder: fromState.DSARActionItemList.getColumnOrder(state),
            },
            dsarRequestList: {
                columnVisibility: fromState.DSARRequestList.getColumnVisibility(state),
                columnOrder: fromState.DSARRequestList.getColumnOrder(state),
                columnWidths: fromState.DSARRequestList.getColumnWidths(state),
                pinnedColumns: fromState.DSARRequestList.getPinnedColumns(state),
            },
            dsarReporting: {
                filters: fromState.DSARReporting.getFilters(state),
            },
            uc: {
                dashboard: {
                    filters: fromState.Uc.dashboard.getFilters(state),
                },
            },
        };
    }
    const newState = {
        auth: {
            customer: {
                id: customerId,
            },
            rememberMe,
            user: {
                id: fromState.Auth.user.getId(state),
            },
        },
        persist,
        ui: {
            preventWarning: fromState.UI.getPreventWarning(state),
            resultsView: fromState.UI.getResultsView(state),
        },
    };
    if (JSON.stringify(newState) !== savedState) {
        saveState(newState);
    }
};

store.subscribe(onStateUpdate);

export default store;
