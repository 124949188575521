const { GeoEChart } = require('/b2b/common/components/Charts');

import {
    Alert,
    Box,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Typography,
    lighten,
} from '@mui/material';
import { DSAR_STATUS, DSAR_STATUS_KEYS } from '/b2b/dsarRequests/constants';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { makeRequestFilters, makeRequestFiltersV2 } from '../helpers';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { ENABLE_DSAR_MULTI_SORTING } from '/b2b/common/components/FeatureFlag';
import InfoIcon from '@mui/icons-material/Info';
import Locale from '/b2b/common/components/Locale';
import LocalizedTooltip from '/b2b/common/components/LocalizedTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import ReportingContext from '../Reporting.context';
import { fetchDSARReportingData } from '/b2b/dsarReporting/services';
import { getMapData } from '/b2b/common/components/Charts/EChart.geo';
import { isFeatureEnabled } from '/b2b/common/helpers/FeatureFlags';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import palette from '/b2b/common/theme/palette';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useSelector } from 'react-redux';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'requestsByJurisdiction';

const colorArray = Array.from({ length: 50 });
const inRangeColors = colorArray
    .map((_, index) => lighten(palette.primary.main, (index + 1) / (colorArray.length * 1.1)))
    .reverse();

const staticOptions = {
    color: ['#fff'],
    geo: {
        animation: true,
        map: 'dsar-reporting.50m',
    },
    legend: {
        show: false,
    },
    tooltip: {},
    visualMap: {
        left: 'right',
        inRange: {
            color: inRangeColors,
        },
        calculable: true,
    },
};

function calculateCenterZoom(data) {
    const [minLon, minLat, maxLon, maxLat] = data
        .map(({ jurisdiction }) => getMapData(jurisdiction))
        .reduce(
            (acc, region) => {
                const [lon1, lat1, lon2, lat2] = region.area;
                return [
                    Math.min(acc[0], lon1),
                    Math.min(acc[1], lat1),
                    Math.max(acc[2], lon2),
                    Math.max(acc[3], lat2),
                ];
            },
            [Infinity, Infinity, -Infinity, -Infinity]
        );

    // center
    const centerLon = (minLon + maxLon) / 2;
    const centerLat = (minLat + maxLat) / 2;
    const center = [centerLon, centerLat];

    // zoom
    const lonRange = maxLon - minLon;
    const latRange = maxLat - minLat;
    const maxRange = Math.max(lonRange, latRange);

    let zoom = 6;
    if (maxRange > 100) {
        zoom = 2;
    } else if (maxRange > 50) {
        zoom = 3;
    } else if (maxRange > 20) {
        zoom = 4;
    } else if (maxRange > 10) {
        zoom = 5;
    }

    return [center, zoom];
}

function sumRequestTypes(requestTypes) {
    return Object.values(requestTypes).reduce((acc, count) => acc + count, 0);
}

function processRow(row, columnNames) {
    const { jurisdiction, requestTypes = {} } = row;
    const mapData = getMapData(jurisdiction);
    const { divisionLevel } = mapData;
    let divisionName = jurisdiction;

    if (jurisdiction === 'common.unknown') {
        divisionName = translate(jurisdiction);
    } else if (divisionLevel === 0) {
        divisionName = translate(`countries.${jurisdiction}`);
    } else if (divisionLevel === 1) {
        divisionName = translate(`divisions.${jurisdiction}`);
    }

    return columnNames.reduce((acc, column) => {
        if (column === 'common.jurisdiction') {
            acc.push(divisionName);
        } else if (column === 'common.fields.code.label') {
            acc.push(jurisdiction === 'common.unknown' ? '' : jurisdiction);
        } else if (column === 'common.total') {
            acc.push(sumRequestTypes(requestTypes));
        } else if (requestTypes[column]) {
            acc.push(requestTypes[column]);
        } else {
            acc.push(0);
        }
        return acc;
    }, []);
}

const RequestsByJurisdiction = props => {
    const {
        data: providedData,
        filters: globalFilters,
        loading: providedLoading,
        shouldRetranslate,
        requestTypeData,
    } = props;
    const { data = {}, error, load, loading } = useContext(ReportingContext);
    const reportData = data[REPORT_NAME] || [];
    const noResults = reportData.length === 0;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const isLoading = loading || providedLoading;

    const [geoLookup, requestTypes, minRequestTypes, maxRequestTypes, center, zoom] =
        useMemo(() => {
            if (!reportData.length) {
                return [{}, [], 0, 0, [0, 0], 0];
            }

            const [geoLookup, requestTypesSet, totals] = reportData.reduce(
                ([geoLookup, requestTypesSet, totals], data) => {
                    const { jurisdiction, requestTypes = {} } = data;

                    geoLookup[jurisdiction] = requestTypes;

                    Object.keys(requestTypes).forEach(requestTypeId =>
                        requestTypesSet.add(requestTypeId)
                    );

                    totals.push(sumRequestTypes(requestTypes));

                    return [geoLookup, requestTypesSet, totals];
                },
                [{}, new Set(), []]
            );

            const [center, zoom] = calculateCenterZoom(reportData);

            return [
                geoLookup,
                Array.from(requestTypesSet),
                Math.min(...totals),
                Math.max(...totals),
                center,
                zoom,
            ];
        }, [reportData]);

    const renderTooltip = useBoundCallback(
        (geoLookup, requestTypes, { name }) => {
            const mapData = getMapData(name);
            const { divisionCode, divisionLevel } = mapData;
            let divisionName = name;
            switch (divisionLevel) {
                case 0: {
                    // Countries
                    divisionName = translate(`countries.${divisionCode}`);
                    break;
                }
                case 1: {
                    // States/Provinces/Territories
                    divisionName = translate(`divisions.${divisionCode}`);
                    break;
                }
                default: {
                    divisionName = name;
                    break;
                }
            }

            const geoData = geoLookup[divisionCode];
            if (!requestTypes.length || !geoData) {
                return '';
            }

            const validRequestTypes = requestTypes.filter(requestType => geoData[requestType]);

            const total = validRequestTypes.reduce(
                (acc, requestType) => acc + geoData[requestType],
                0
            );

            // eslint-disable-next-line xss/no-mixed-html
            return `<b>${divisionName} (${total})</b><hr style="margin:0.25em 0"/>${validRequestTypes
                .sort((a, b) => translate(a).localeCompare(translate(b)))
                .map(requestType => {
                    const value = geoLookup[divisionCode][requestType] || 0;
                    const percent = total > 0 ? ((value / total) * 100).toFixed(0) : 0;
                    return `${translate(requestType)}: <b>${percent}%</b> (${value})`;
                })
                .join('<br>')}`;
        },
        [geoLookup, requestTypes]
    );

    const memoOption = useMemo(
        () =>
            merge({}, staticOptions, {
                geo: {
                    tooltip: {
                        formatter: renderTooltip,
                    },
                    center,
                    zoom,
                },
                series: [
                    {
                        type: 'map',
                        map: 'dsar-reporting.50m',
                        geoIndex: 0,
                        data: reportData.map(data => {
                            const { jurisdiction, requestTypes = {} } = data;
                            return {
                                name: jurisdiction,
                                value: sumRequestTypes(requestTypes),
                            };
                        }),
                    },
                ],
                visualMap: {
                    min: minRequestTypes,
                    max: maxRequestTypes,
                    range: [minRequestTypes, maxRequestTypes],
                    text: [translate('common.high'), translate('common.low')],
                },
            }),
        [
            center,
            maxRequestTypes,
            minRequestTypes,
            reportData,
            requestTypes,
            shouldRetranslate,
            zoom,
        ]
    );

    const echartsInstance = useRef(null);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    const makeCSV = useCallback(
        data => {
            const reportData = data[REPORT_NAME] || [];
            const columnNames = [
                'common.jurisdiction',
                'common.fields.code.label',
                'common.total',
                ...requestTypes,
            ];

            const headers = columnNames.map(translate).join(',');

            const rows = reportData
                .sort((a, b) =>
                    a.jurisdiction === 'common.unknown'
                        ? 1
                        : a.jurisdiction.localeCompare(b.jurisdiction)
                )
                .map(row => processRow(row, columnNames).join(','))
                .join('\n');

            const csvFile = `${headers}\n${rows}`;

            return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        },
        [reportData, requestTypes]
    );

    const helpers = useMemo(
        () => ({
            makeCSV,
            name: 'requests-by-jurisdiction',
        }),
        [makeCSV]
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);

    const handleActionsClick = useBoundCallback(
        (open, event) => {
            setAnchorEl(open ? null : event.currentTarget);
        },
        [open]
    );

    const handleActionsClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDownloadCsv = useCallback(() => {
        if (echartsInstance.current) {
            echartsInstance.current.dispatchAction({
                type: 'download',
                format: 'csv',
            });
        }
        handleActionsClose();
    }, [handleActionsClose]);

    const handleDownloadImage = useCallback(() => {
        if (echartsInstance.current) {
            echartsInstance.current.dispatchAction({
                type: 'download',
                format: 'png',
            });
        }
        handleActionsClose();
    }, [handleActionsClose]);

    const enableMutliFilterDrilldown = useSelector(state =>
        isFeatureEnabled(state, ENABLE_DSAR_MULTI_SORTING)
    );

    const handleRequestsByJurisdiction = useCallback(() => {
        globalFilters.requestStatus = Object.keys(DSAR_STATUS)
            // all verified requests i.e. all statuses except email verification, rejected automatically
            // the Redshift query does not use any request statuses
            .filter(
                ds =>
                    ds !== DSAR_STATUS_KEYS.PENDING_EMAIL_VERIFICATION &&
                    ds !== DSAR_STATUS_KEYS.REJECTED_AUTO
            )
            .join(',');
        const makeFilters = enableMutliFilterDrilldown ? makeRequestFiltersV2 : makeRequestFilters;
        window.open(
            `${AuthenticatedPaths.DSAR_REQUESTS}?${makeFilters([globalFilters, providedData, requestTypeData])}`,
            '_blank'
        );
    }, [enableMutliFilterDrilldown]);

    const fetchData = useBoundCallback(
        globalFilters =>
            fetchDSARReportingData({
                ...globalFilters,
                reports: [REPORT_NAME],
            }),
        [globalFilters]
    );

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData, globalFilters]);

    return (
        <Box
            component={Paper}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridTemplateRows={error ? 'auto auto 1fr' : 'auto 1fr'}
            gap={0}
            sx={{ height: '100%' }}
        >
            {error ? (
                <Alert severity="error">
                    <Typography variant="caption">{error}</Typography>
                </Alert>
            ) : null}
            <Box
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                display="flex"
                alignItems="center"
                gridColumn="span 7"
                p={2}
            >
                <Typography variant="h5">
                    <Locale path="dsar.reporting.charts.requestsByJurisdiction.title" />
                    <LocalizedTooltip title="dsar.reporting.charts.requestsByJurisdiction.tooltip">
                        <InfoIcon
                            sx={{
                                color: theme => theme.palette.common.grey[400],
                                cursor: 'help',
                                fontSize: '1em',
                                ml: 0.5,
                                verticalAlign: 'middle',
                            }}
                        />
                    </LocalizedTooltip>
                </Typography>
            </Box>
            <Box
                borderBottom={theme => `1px solid ${theme.palette.divider}`}
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
                gridColumn="span 5"
                p={2}
            >
                <LocalizedTooltip title="common.actions">
                    <span>
                        <IconButton disabled={noResults} onClick={handleActionsClick}>
                            <MoreVertIcon />
                        </IconButton>
                    </span>
                </LocalizedTooltip>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleActionsClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <MenuList>
                        <MenuItem onClick={handleDownloadCsv}>
                            <Locale path="common.table.downloadCsv" />
                        </MenuItem>
                        <MenuItem onClick={handleDownloadImage}>
                            <Locale path="common.table.saveAsImage" />
                        </MenuItem>
                        <MenuItem onClick={handleRequestsByJurisdiction}>
                            <Locale path="dsar.reporting.charts.requests.list" />
                        </MenuItem>
                    </MenuList>
                </Popover>
            </Box>
            <GeoEChart
                gridColumn="span 12"
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
                replaceMerge={['series']}
            />
        </Box>
    );
};

RequestsByJurisdiction.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
    requestTypeData: PropTypes.array,
};

export default withAsyncData(withRetranslate(RequestsByJurisdiction), ReportingContext);
