import { DSAR_DISPLAY_OPTIONS, DSAR_STANDARD_FIELDS_I18N } from '/b2b/dsarForms/constants';
import {
    getEntityById as getOrganizationById,
    getEntitiesForIds as getOrganizationsForIds,
} from '../../organizations/state/db/Organization.selectors';

import { DOMAIN } from './FormDetails.constants';
import castArray from 'lodash/castArray';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { translate } from '../../common/helpers/i18n';

const filteredKeys = ['order'];
const mandatoryKeys = ['id', 'name', 'type', 'required'];

export const getStore = state => get(state, DOMAIN) || {};

export const getId = state => getStore(state).id;
export const getError = state => getStore(state).error;
export const getUpdateError = state => getStore(state).updateError;

export const getChanges = state => getStore(state).changes || {};
export const getName = state => getChanges(state).name || '';
export const getDescription = state => getChanges(state).description || '';
export const getOrganizations = state =>
    getOrganizationsForIds(
        state,
        (getChanges(state).organizations || []).map(({ orgId }) => orgId)
    ).filter(org => org && !org.isRoot);
export const getCustomFields = state => getChanges(state).customFields || [];
export const getFieldById = (state, id) => getChanges(state).fields[id] || {};
export const getFields = state =>
    getCustomFields(state).map(({ id }) => ({ ...getFieldById(state, id) }));
export const getFieldByIndex = (state, index) =>
    getFieldById(state, getCustomFields(state)[index]) || {};

export const getField = (state, field) => {
    const value = get(getChanges(state), field);
    return value === undefined || value === null ? '' : value;
};
export const getErrors = state => getStore(state).errors || {};
export const getFieldErrors = (state, field) => get(getErrors(state), field, '');
export const getFieldOptionsById = (state, id) => get(getFieldById(state, id), 'options') || [];

export const getCustomRequestTypes = state => getChanges(state).customRequestTypes || [];
export const getRequestTypeById = (state, id) => getChanges(state).requestTypes[id] || {};
export const getRequestTypes = state =>
    getCustomRequestTypes(state).map(({ id }) => ({ ...getRequestTypeById(state, id) }));
export const getRequestTypeErrors = (state, requestType) => get(getErrors(state), requestType, '');
export const getUnexcludedRequestTypes = state =>
    getRequestTypes(state).filter(({ jurisdiction } = {}) => jurisdiction !== 'disabled') || [];

export const getConditionalFieldsById = (state, id) =>
    getCustomFields(state).filter(
        ({ conditional: { id: conditionalId } = {} }) => conditionalId === id
    ) || [];
export const getConditionalFieldsByValue = (state, id, value) =>
    getCustomFields(state).filter(
        ({ conditional: { id: conditionalId, value: conditionalValue } = {} } = {}) =>
            conditionalId === id && conditionalValue === value
    ) || [];

export const getCustomFieldErrors = (state, id) => {
    const errors = getErrors(state).fields;
    return id === undefined ? errors : get(errors, id);
};
export const getCustomFieldOptionsErrors = (state, id) => {
    const errors = getCustomFieldErrors(state, id) || [];
    return id === undefined ? errors : get(errors, 'options');
};
export const getCustomRequestTypeErrors = (state, id) => {
    const errors = getErrors(state).requestTypes;
    return id === undefined ? errors : get(errors, id);
};

export const getIdentityVerify = state => getStore(state).identityVerify;

export const isCustomField = (state, id) => {
    if (id === undefined) {
        return true;
    }
    const { name = '' } = getFieldById(state, id);
    return name.indexOf('o-') === 0 && !DSAR_STANDARD_FIELDS_I18N[name];
};
export const isEditableField = (state, id) => {
    if (id === undefined) {
        return true;
    }
    const editable =
        isCustomField(state, id) || get(DSAR_STANDARD_FIELDS_I18N[id], 'editable') || false;
    return editable;
};
export const isRequiredField = (state, id) => {
    if (id === undefined) {
        return false;
    }
    const required = get(DSAR_STANDARD_FIELDS_I18N[id], 'required') || false;
    return required;
};
export const canAddConditionals = (state, id) => {
    const addConditionals = false !== get(DSAR_STANDARD_FIELDS_I18N[id], 'canAddConditionals');
    return addConditionals;
};
export const canShowHide = (state, id) => {
    const canShowHide = false !== get(DSAR_STANDARD_FIELDS_I18N[id], 'canShowHide');
    return canShowHide;
};
export const isExcludableField = (state, id) =>
    (!isCustomField(state, id) && get(DSAR_STANDARD_FIELDS_I18N[id], 'excludable')) || false;
export const isDraggable = (state, id) => {
    return isEditableField(state, id) || false;
};
export const isMandatoryField = (state, id) =>
    !isCustomField(state, id) && !isEditableField(state, id) && isRequiredField(state, id);
export const isLoading = state => getStore(state).loading;
export const isUpdating = state => getStore(state).updating;
export const isDisabledByDefault = (state, id) => {
    if (id === undefined) {
        return true;
    }

    const disabledByDefault = !!(
        DSAR_STANDARD_FIELDS_I18N[id]?.displayOption === DSAR_DISPLAY_OPTIONS.DISABLED
    );

    return disabledByDefault;
};

export const shouldShowConditionals = (state, id) => {
    const { type } = getFieldById(state, id);
    return type === 'checkbox';
};

// eslint-disable-next-line complexity
export const shouldShowOptions = (state, id) => {
    switch (id) {
        case 'requestor-type':
        case 'request-type':
        case 'country':
        case 'territory': {
            return true;
        }
    }
    const { type } = getFieldById(state, id);
    return type === 'select' || type === 'multiselect' || type === 'radio';
};

export const getPayload = state => ({
    allowLocationSelect: getField(state, 'allowLocationSelect'),
    geoFencing: getField(state, 'geoFencing'),
    duplicateReject: getField(state, 'duplicateReject'),
    unverifiedEmailReject: getField(state, 'unverifiedEmailReject'),
    internalDueDays: getField(state, 'internalDueDays'),
    internalDueItems: getField(state, 'internalDueItems'),
    name: getField(state, 'name'),
    fromCompany: getField(state, 'fromCompany'),
    description: getField(state, 'description'),
    fromName: getField(state, 'fromName'),
    orgIds: getField(state, 'organizations')
        .map(({ orgId } = {}) => orgId)
        .filter(orgId => !!orgId && !getOrganizationById(state, orgId).isRoot),
    styling: {
        showHeader: !!getField(state, 'styling.showHeader'),
        instructions: getField(state, 'styling.instructions'),
        stylesheetURL: getField(state, 'styling.stylesheetURL'),
        palette: getField(state, 'styling.palette'),
    },
    connectionIds: (getField(state, 'selectedDataStores') || []).map(field => field.connectionId),
    dataStoreCount: getField(state, 'dataStoreCount'),
    selectedDataStores: getField(state, 'selectedDataStores'),
    completionContent: getField(state, 'completionContent'),
    allowListEmails: getField(state, 'allowListEmails'),
    escalationEmails: getField(state, 'escalationEmails'),
    customFields: getFields(state).map(field => {
        const { id } = field;
        return Object.entries(field).reduce((newField, [key, value]) => {
            if (filteredKeys.includes(key)) {
                return newField;
            }
            if (mandatoryKeys.includes(key)) {
                newField[key] = value;
                return newField;
            }
            if (!isCustomField(state, id)) {
                // Parse the default fields, and only save things we can edit
                switch (key) {
                    case 'options': {
                        if (isEditableField(state, id)) {
                            // If the options are editable, we save them all
                            newField[key] = value;
                        } else {
                            // If the options aren't editable, we check for hidden options only
                            const hiddenOptions = (value || [])
                                .filter(option => !!option?.hidden)
                                .map(({ value }) => value);
                            if (hiddenOptions && hiddenOptions.length) {
                                newField.hidden = hiddenOptions;
                            }
                        }
                        return newField;
                    }

                    case 'excluded': {
                        if (isExcludableField(state, id)) {
                            // If it's excludable, lets save the excluded key
                            newField[key] = !!value;
                            return newField;
                        }
                        break;
                    }

                    case 'accept': {
                        // filter null values from groups
                        newField[key] = castArray(value).filter(val => !!val);
                        return newField;
                    }

                    case 'helperText':
                    case 'label':
                    case 'placeholder': {
                        newField[key] = translate(value);
                        break;
                    }
                    // Adds the conditional property to the field in the payload for standard fields
                    case 'displayOption':
                    case 'displayOptionRequestTypes':
                    case 'conditional': {
                        newField[key] = value;
                        break;
                    }
                }
                return newField;
            }
            if (value !== undefined && value !== '') {
                // filter null values from groups
                if (isArray(value)) {
                    newField[key] = value.filter(val => !!val);
                } else {
                    newField[key] = value;
                }
            }
            return newField;
        }, {});
    }),
    customRequestTypes: getRequestTypes(state),
});

export const isCountryHidden = (state, country) =>
    getFieldById(state, 'country').options.find(({ value, hidden }) => {
        return value === country && hidden;
    }) || false;
