// @ts-check
import * as echarts from 'echarts/core';

// Import bar charts, all suffixed with Chart
import { BarChart, LineChart, MapChart, PieChart } from 'echarts/charts';
import {
    DatasetComponent,
    GeoComponent,
    GraphicComponent,
    GridComponent,
    LegendComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    TransformComponent,
    VisualMapComponent,
} from 'echarts/components';
// Features like Universal Transition and Label Layout
import { LabelLayout, UniversalTransition } from 'echarts/features';

// Import the Canvas renderer
// Note that including the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';
import noop from 'lodash/noop';
// @ts-ignore - Handled by webpack
import osanoTheme from './EChart.theme.json';

// Register the required components
echarts.use([
    BarChart,
    CanvasRenderer,
    DatasetComponent,
    GeoComponent,
    GridComponent,
    LabelLayout,
    LegendComponent,
    LineChart,
    MapChart,
    PieChart,
    GeoComponent,
    GraphicComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    TransformComponent,
    UniversalTransition,
    VisualMapComponent,
]);

export const { theme, themeName } = osanoTheme;
echarts.registerTheme(themeName, theme);

// We need a custom action we can dispatch for downloading
echarts.registerAction({ type: 'download', event: 'download', update: 'prepareAndUpdate' }, noop);
