import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// Chart configuration
import { makeOptions, data as staticData } from './ConsentGraph.chart';

import { Line } from 'react-chartjs-2';
import Locale from '/b2b/common/components/Locale';
import Portlet from '/b2b/common/components/Portlet';
import PortletContent from '/b2b/common/components/PortletContent';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import styles from './ConsentGraph.styles';
import { translate } from '/b2b/common/helpers/i18n';

const useStyles = makeStyles(styles);

const ConsentGraph = props => {
    const { className, daysAgo, data = staticData, isLoading, localeCode } = props;
    const classes = useStyles(props);
    const { datasets = [] } = data;
    const rootClassName = classNames(classes.root, className);
    const dateFormat = 'MMM D';
    const [formattedData, setFormattedData] = useState({ ...staticData, ...data });

    useEffect(() => {
        const dateArray = (data.labels || []).map(label => {
            const date = new Date(parseInt(label, 10) * 1000).toUTCString();
            return moment.utc(date).locale(localeCode).format(dateFormat);
        });

        setFormattedData({
            ...data,
            datasets: (data?.datasets || []).map(dataset => ({
                ...dataset,
                tension: 0.2,
                label: translate(dataset.label || ''),
            })),
            labels: dateArray,
        });
    }, [localeCode, data]);

    return (
        <Portlet className={rootClassName} elevation={0} BoxProps={{ sx: { height: '100%' } }}>
            <PortletContent noPadding className={classes.portletContent}>
                <div className={classes.details}>
                    <div className={classes.summary}>
                        <Typography variant="h3">
                            <Locale path={'layouts.Dashboard.components.ConsentGraph.label'} />
                        </Typography>
                        <Typography variant="body1">
                            <Locale
                                path={'layouts.Dashboard.components.ConsentGraph.lastDays.label'}
                                options={{
                                    days: daysAgo,
                                }}
                            />
                        </Typography>
                    </div>
                    <div className={classes.legends}>
                        {datasets.map(({ label, borderColor }) => (
                            <div key={label} className={classes.legend}>
                                <span
                                    className={classes.legendColor}
                                    style={{ backgroundColor: borderColor }}
                                />
                                <Typography varint="body1">
                                    <Locale path={label} />
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.chart}>
                    <Line data={formattedData} options={makeOptions(formattedData)} />
                </div>
                {isLoading && (
                    <div className={classes.progressWrapper}>
                        <CircularProgress />
                    </div>
                )}
            </PortletContent>
        </Portlet>
    );
};

ConsentGraph.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    daysAgo: PropTypes.number,
    isLoading: PropTypes.bool,
    localeCode: PropTypes.string,
};

export default ConsentGraph;
