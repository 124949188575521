import {
    CRUDDetailsReducers,
    errorReducer,
    genericReducer,
    loadingReducer,
} from '../../common/helpers/ReduxHelpers';
import {
    DSAR_DETAILS_FIELD_IDS,
    DSAR_DISPLAY_OPTIONS,
    DSAR_STANDARD_FIELDS_I18N,
    IDENTITY_VERIFY_ID,
} from '/b2b/dsarForms/constants';

import { types as ActionTypes } from './FormDetails.actions';
import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import AuthenticatedPaths from '../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { types as NavActionTypes } from '../../common/state/nav/Nav.actions';
import { combineReducers } from 'redux';
import { formatIdVerificationField } from '/b2b/dsarForms/format';
import isNil from 'lodash/isNil';
import { matchPath } from 'react-router-dom';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import reduceReducers from 'reduce-reducers';
import schema from './db/Form.schema';
import set from 'lodash/set';
import { v4 as uuid } from 'uuid';
import { validateEmail } from '../../common/helpers/Email';

const { IDENTIFICATION_FILE } = DSAR_DETAILS_FIELD_IDS;

const omitEmpty = obj => omitBy(obj, val => isNil(val) || val === '');

export const initialState = {
    id: '',
    identityVerify: false,
    loading: false,
    updating: false,
    submitted: false,
    error: null,
    updateError: null,
    changes: {
        allowLocationSelect: true,
        geoFencing: false,
        duplicateReject: true,
        unverifiedEmailReject: false,
        internalDueDays: null,
        internalDueItems: null,
        name: null,
        description: null,
        completionContent: null,
        organizations: [],
        customFields: [],
        fields: {},
        customRequestTypes: [],
        requestTypes: {},
        displayOption: null,
        displayOptionRequestTypes: [],
        generatedInbox: '',
        allowListEmails: [],
        escalationEmails: [],
    },
    errors: {},
};

const clearFieldError =
    prefix =>
    (state, { id, field = '', value, oldValue } = { value: true }) => {
        if (value !== oldValue) {
            return omit({ ...state }, `${prefix}${id ? `${id}.` : ''}${field}`);
        }
        return state;
    };

export default reduceReducers(
    initialState,
    combineReducers({
        ...CRUDDetailsReducers(
            initialState,
            [
                ActionTypes.fetchDetailsBegin,
                ActionTypes.fetchDetailsSuccess,
                ActionTypes.fetchDetailsFailure,
            ],
            schema
        ),
        updating: loadingReducer(
            initialState.updating,
            [
                ActionTypes.createBegin,
                ActionTypes.createSuccess,
                ActionTypes.createFailure,
                ActionTypes.updateBegin,
                ActionTypes.updateSuccess,
                ActionTypes.updateFailure,
                ActionTypes.deleteBegin,
                ActionTypes.deleteSuccess,
                ActionTypes.deleteFailure,
            ],
            schema
        ),
        updateError: errorReducer(
            initialState.updateError,
            [
                ActionTypes.fetchDetailsBegin,
                ActionTypes.createBegin,
                ActionTypes.createSuccess,
                ActionTypes.createFailure,
                ActionTypes.updateBegin,
                ActionTypes.updateSuccess,
                ActionTypes.updateFailure,
                ActionTypes.deleteBegin,
                ActionTypes.deleteSuccess,
                ActionTypes.deleteFailure,
            ],
            schema
        ),
        submitted: genericReducer(initialState.submitted, {
            [ActionTypes.createBegin]: () => true,
            [ActionTypes.fetchDetailsBegin]: () => true,
            [ActionTypes.updateBegin]: () => true,
            [ActionTypes.createSuccess]: () => false,
            [ActionTypes.fetchDetailsSuccess]: () => false,
            [ActionTypes.updateSuccess]: () => false,
            [ActionTypes.fieldErrors]: () => true,
        }),
        identityVerify: genericReducer(initialState.identityVerify, {
            [ActionTypes.fetchDetailsSuccess]: (state, { response: { identityVerify } = {} }) => {
                return !isNil(identityVerify) ? identityVerify : state;
            },
            [ActionTypes.updateField]: (state, { id, field, value }) => {
                if (id === IDENTITY_VERIFY_ID) {
                    // TODO: Remove excluded field after ENABLE_DSAR_CONDITIONAL_FIELDS is removed
                    if (field === 'excluded') {
                        return !value;
                    }
                    if (field === 'displayOption') {
                        return value !== DSAR_DISPLAY_OPTIONS.DISABLED;
                    }
                }
                return state;
            },
        }),
        changes: genericReducer(initialState.changes, {
            [ActionTypes.addConditionalField]: (
                state,
                { id: dependentId, value: dependentValue, data }
            ) => {
                const { fields, customFields } = state;
                let newFields = [...customFields];
                const id = uuid('customFormField');
                const field = omitEmpty({
                    type: 'text',
                    required: false,
                    id,
                    ...data,
                    name: data.name.startsWith('o-') ? data.name : `o-${data.name}` || '',
                    conditional: {
                        id: dependentId,
                        value: dependentValue,
                    },
                });

                const parentIndex = newFields.findLastIndex(
                    ({ id: fieldId }) => fieldId === dependentId
                );
                const conditionIndex = newFields.findLastIndex(
                    ({ conditional: { id: conditionalId } = {} }) => conditionalId === dependentId
                );
                const optionIndex = newFields.findLastIndex(
                    ({ conditional: { id: conditionalId, value: conditionalValue } = {} }) =>
                        conditionalId === dependentId && conditionalValue === dependentValue
                );

                // insert priority optionIndex > conditionIndex > parentIndex
                const insertIndex =
                    optionIndex > -1
                        ? optionIndex + 1
                        : conditionIndex > -1
                          ? conditionIndex + 1
                          : parentIndex + 1;

                return {
                    ...state,
                    customFields: [
                        ...newFields.slice(0, insertIndex),
                        field,
                        ...newFields.slice(insertIndex),
                    ],
                    fields: {
                        ...fields,
                        [id]: field,
                    },
                };
            },
            [ActionTypes.updateConditionalField]: (state, { id, data }) => {
                const { fields, customFields } = state;

                let newField;
                const newCustomFields = customFields.reduce((acc, field) => {
                    if (field.id === id) {
                        newField = {
                            ...field,
                            ...omitEmpty({
                                ...data,
                                name: data.name.startsWith('o-')
                                    ? data.name
                                    : `o-${data.name}` || '',
                            }),
                        };
                        return [...acc, newField];
                    }
                    return [...acc, field];
                }, []);

                return {
                    ...state,
                    customFields: newCustomFields,
                    fields: {
                        ...fields,
                        [id]: newField,
                    },
                };
            },
            [ActionTypes.dragConditionalField]: (state, { id, dragIndex, hoverIndex }) => {
                const { customFields } = state;

                const newCustomFields = [...customFields];

                const trueDragIndex = newCustomFields.findIndex(
                    ({ id: fieldId }) => fieldId === id
                );

                const insertIndex =
                    dragIndex > hoverIndex
                        ? trueDragIndex - Math.abs(dragIndex - hoverIndex)
                        : trueDragIndex + Math.abs(dragIndex - hoverIndex);

                const field = newCustomFields.splice(trueDragIndex, 1);

                return {
                    ...state,
                    customFields: [
                        ...newCustomFields.slice(0, insertIndex),
                        ...field,
                        ...newCustomFields.slice(insertIndex),
                    ],
                };
            },
            [ActionTypes.addField]: (state, { data, index }) => {
                const { fields, customFields } = state;
                let newCustomFields = [...customFields];
                const id = uuid('customFormField');
                const field = {
                    label: '',
                    type: 'text',
                    required: false,
                    helperText: '',
                    id,
                    ...data,
                    name: `o-${data.name ? data.name : ''}`,
                };

                if (typeof index === 'number' && index > 0) {
                    const insertIndex = Math.max(0, Math.min(newCustomFields.length, index));
                    newCustomFields = [
                        ...newCustomFields.slice(0, insertIndex),
                        field,
                        ...newCustomFields.slice(insertIndex),
                    ];
                } else {
                    newCustomFields.unshift(field);
                }
                return {
                    ...state,
                    customFields: newCustomFields,
                    fields: {
                        ...fields,
                        [id]: field,
                    },
                };
            },
            [ActionTypes.deleteField]: (state, { id }) => {
                const { fields, customFields } = state;

                const newFields = { ...fields };

                const fieldToDelete = newFields[id];

                let newCustomFields = [...customFields];

                // if we're deleting a conditional field, just delete that field
                if (fieldToDelete?.conditional?.id) {
                    delete newFields[id];
                    newCustomFields = customFields.filter(({ id: fieldId }) => fieldId !== id);
                } else {
                    // otherwise, delete field and all fields that depend on this one
                    customFields
                        .filter(
                            ({ id: fieldId, conditional: { id: conditionalId } = {} }) =>
                                fieldId === id || conditionalId === id
                        )
                        .forEach(({ id: fieldId }) => {
                            delete newFields[fieldId];
                        });

                    newCustomFields = customFields.filter(
                        ({ id: fieldId, conditional: { id: conditionalId } = {} }) =>
                            fieldId !== id && conditionalId !== id
                    );
                }

                return {
                    ...state,
                    customFields: newCustomFields,
                    fields: newFields,
                };
            },
            [ActionTypes.dragFieldOption]: (state, { id, dragIndex, hoverIndex }) => {
                const { fields, customFields } = state;
                const fieldOptions = fields[id]?.options || [];
                const newOrder = [...fieldOptions];
                const dragged = newOrder.splice(dragIndex, 1);

                let newCustomFields = [...customFields];

                const dragFields = newCustomFields.filter(
                    ({ conditional: { id: conditionalId, value: conditionalValue } = {} }) =>
                        conditionalId === id && conditionalValue === fieldOptions[dragIndex].value
                );

                const hoverFields = newCustomFields.filter(
                    ({ conditional: { id: conditionalId, value: conditionalValue } = {} }) =>
                        conditionalId === id && conditionalValue === fieldOptions[hoverIndex].value
                );

                if (dragFields.length || hoverFields.length) {
                    const conditionDragIndex = newCustomFields.findIndex(
                        ({ id: fieldId }) => fieldId === dragFields[0].id
                    );

                    newCustomFields.splice(conditionDragIndex, dragFields.length);

                    const conditionHoverIndex = newCustomFields.findIndex(
                        ({ id: fieldId }) =>
                            fieldId ===
                            hoverFields[dragIndex > hoverIndex ? 0 : hoverFields.length - 1].id
                    );
                    const insertIndex =
                        dragIndex > hoverIndex ? conditionHoverIndex : conditionHoverIndex + 1;

                    newCustomFields = [
                        ...newCustomFields.slice(0, insertIndex),
                        ...dragFields,
                        ...newCustomFields.slice(insertIndex),
                    ];
                }

                return {
                    ...state,
                    customFields: newCustomFields,
                    fields: {
                        ...fields,
                        [id]: {
                            ...fields[id],
                            options: [
                                ...newOrder.slice(0, hoverIndex),
                                ...dragged,
                                ...newOrder.slice(hoverIndex),
                            ],
                        },
                    },
                };
            },
            [ActionTypes.dragField]: (state, { dragIndex, hoverIndex }) => {
                if (dragIndex === hoverIndex) {
                    return state;
                }

                const { customFields } = state;
                const dragId = customFields[dragIndex].id;
                const dragFields = customFields.filter(
                    ({ id, conditional: { id: conditionalId } = {} }) =>
                        [id, conditionalId].includes(dragId)
                );

                const newCustomFields = [...customFields];
                newCustomFields.splice(dragIndex, dragFields.length);

                const hoverId = customFields[hoverIndex].id;
                const insertIndex =
                    dragIndex > hoverIndex
                        ? hoverIndex
                        : newCustomFields.findLastIndex(
                              ({ id, conditional: { id: conditionalId } = {} }) =>
                                  [id, conditionalId].includes(hoverId)
                          ) + 1;

                return {
                    ...state,
                    customFields: [
                        ...newCustomFields.slice(0, insertIndex),
                        ...dragFields,
                        ...newCustomFields.slice(insertIndex),
                    ],
                };
            },
            [ActionTypes.fetchDetailsSuccess]: (state, { response }) => {
                return {
                    ...state,
                    ...response,
                };
            },
            [ActionTypes.updateField]: (state, { id, field, value }) => {
                const { fields, customFields } = state;
                const newFields = { ...fields };
                let newCustomFields = [...customFields];
                switch (field) {
                    case 'name':
                        // We can only edit the names of custom fields
                        value = `o-${value}`;
                        break;
                    case 'type':
                        // If changing type, delete any dependent fields
                        customFields
                            .filter(
                                ({ conditional: { id: conditionalId } = {} }) =>
                                    conditionalId === id
                            )
                            .forEach(({ id: fieldId }) => {
                                delete newFields[fieldId];
                            });

                        newCustomFields = customFields.filter(
                            ({ conditional: { id: conditionalId } = {} }) => conditionalId !== id
                        );
                        break;
                    case 'displayOption':
                        // If changing display option, reset properties set by the previous display option
                        delete newFields[id].conditional;
                        delete newFields[id].displayOptionRequestTypes;
                        delete newFields[id].excluded;
                        if (value === DSAR_DISPLAY_OPTIONS.DISABLED) {
                            newFields[id].excluded = true;
                        }
                        break;
                    case 'displayOptionRequestTypes':
                        newFields[id].conditional = {
                            id: 'request-type',
                            value: value?.map(({ value }) => value),
                        };
                        break;
                }
                return {
                    ...state,
                    customFields: newCustomFields,
                    fields: {
                        ...newFields,
                        [id]: {
                            ...newFields[id],
                            [field]: value,
                        },
                    },
                };
            },
            [ActionTypes.useDefaultOptions]: (state, { id }) => {
                const { fields } = state;
                const options =
                    DSAR_STANDARD_FIELDS_I18N[`${id}`]?.options || fields[id]?.options || [];

                return {
                    ...state,
                    fields: {
                        ...fields,
                        [id]: {
                            ...fields[id],
                            options,
                        },
                    },
                };
            },
            [ActionTypes.addFieldOption]: (state, { id }) => {
                const { fields } = state;
                const options = fields[id]?.options || [];

                return {
                    ...state,
                    fields: {
                        ...fields,
                        [id]: {
                            ...fields[id],
                            options: [
                                ...options,
                                {
                                    label: '',
                                    value: '',
                                },
                            ],
                        },
                    },
                };
            },
            [ActionTypes.deleteFieldOption]: (state, { id, optionIndex }) => {
                const { fields, customFields } = state;
                const options = fields[id]?.options || [];

                // remove any dependent fields
                const newCustomFields = customFields.filter(
                    ({ conditional: { id: conditionalId, value: conditionalValue } = {} }) =>
                        !(conditionalId === id && conditionalValue === options[optionIndex]?.value)
                );

                return {
                    ...state,
                    customFields: newCustomFields,
                    fields: {
                        ...fields,
                        [id]: {
                            ...fields[id],
                            options: [
                                ...options.slice(0, optionIndex),
                                ...options.slice(optionIndex + 1),
                            ],
                        },
                    },
                };
            },
            [ActionTypes.updateFieldOption]: (state, { id, optionIndex, field, value }) => {
                const { fields, customFields } = state;
                const options = fields[id]?.options || [];

                const newFields = { ...fields };

                // keep conditional values in sync with option value
                const newCustomFields =
                    field === 'value'
                        ? customFields.map(field => {
                              if (
                                  field?.conditional?.id === id &&
                                  field?.conditional?.value === options[optionIndex]?.value
                              ) {
                                  newFields[field.id] = {
                                      ...field,
                                      conditional: {
                                          ...field.conditional,
                                          value,
                                      },
                                  };
                                  return {
                                      ...field,
                                      conditional: {
                                          ...field.conditional,
                                          value,
                                      },
                                  };
                              }
                              return field;
                          })
                        : customFields;

                return {
                    ...state,
                    customFields: newCustomFields,
                    fields: {
                        ...newFields,
                        [id]: {
                            ...newFields[id],
                            options: [
                                ...options.slice(0, optionIndex),
                                {
                                    ...options[optionIndex],
                                    [field]: value,
                                },
                                ...options.slice(optionIndex + 1),
                            ],
                        },
                    },
                };
            },
            [ActionTypes.hideFieldOption]: (state, { id, optionIndex }) => {
                const { fields } = state;
                const options = fields[id]?.options || [];
                const { hidden } = options[optionIndex];
                return {
                    ...state,
                    fields: {
                        ...fields,
                        [id]: {
                            ...fields[id],
                            options: [
                                ...options.slice(0, optionIndex),
                                {
                                    ...options[optionIndex],
                                    hidden: !hidden,
                                },
                                ...options.slice(optionIndex + 1),
                            ],
                        },
                    },
                };
            },
            [ActionTypes.dragRequestType]: (state, { dragIndex, hoverIndex }) => {
                if (dragIndex === hoverIndex) {
                    return state;
                }

                const { customRequestTypes, requestTypes } = state;
                const dragId = customRequestTypes[dragIndex].id;
                const dragRequestTypes = customRequestTypes.filter(({ id }) => id === dragId);

                const newCustomRequestTypes = [...customRequestTypes];
                newCustomRequestTypes.splice(dragIndex, 1);

                const hoverId = customRequestTypes[hoverIndex].id;
                const insertIndex =
                    dragIndex > hoverIndex
                        ? hoverIndex
                        : newCustomRequestTypes.findIndex(({ id }) => id === hoverId) + 1;

                const orderedCustomRequestTypes = [
                    ...newCustomRequestTypes.slice(0, insertIndex),
                    ...dragRequestTypes,
                    ...newCustomRequestTypes.slice(insertIndex),
                ].map((requestType, index) => ({
                    ...requestType,
                    ordinal: index * 100,
                }));

                // We're replacing requestTypes on each move so the data gets lost
                // We need to use the requestType and just update the ordinal based on orderedCustomRequestTypes
                const formattedRequestTypes = orderedCustomRequestTypes.reduce(
                    (acc, requestType, index) => {
                        const { id } = requestType;
                        const requestTypeObj = requestTypes[id];
                        if (!requestTypeObj) {
                            return acc;
                        }

                        return {
                            ...acc,
                            [id]: {
                                ...requestTypeObj,
                                id,
                                ordinal: index * 100,
                            },
                        };
                    },
                    {}
                );

                return {
                    ...state,
                    customRequestTypes: orderedCustomRequestTypes,
                    requestTypes: formattedRequestTypes,
                };
            },
            [ActionTypes.updateRequestType]: (state, { id, field, value }) => {
                const { customFields, customRequestTypes, fields, requestTypes } = state;
                const newCustomRequestTypes = customRequestTypes.map(requestType =>
                    requestType.id === id
                        ? {
                              ...requestType,
                              [field]: value,
                          }
                        : requestType
                );
                const newRequestTypes = {
                    ...requestTypes,
                    [id]: {
                        ...requestTypes[id],
                        [field]: value,
                    },
                };

                const idVerificationField = formatIdVerificationField(
                    { ...fields[IDENTIFICATION_FILE] },
                    newCustomRequestTypes
                );

                return {
                    ...state,
                    customRequestTypes: newCustomRequestTypes,
                    requestTypes: newRequestTypes,
                    ...(field === 'verificationLevel' && {
                        customFields: customFields.map(field => {
                            if (field.id === IDENTIFICATION_FILE) {
                                return idVerificationField;
                            }
                            return field;
                        }),
                        fields: {
                            ...fields,
                            [IDENTIFICATION_FILE]: idVerificationField,
                        },
                    }),
                };
            },
            [ActionTypes.updateForm]: (state, { field, value, oldValue }) => {
                if (value === oldValue) {
                    return state;
                }
                if (field === 'organizations') {
                    const mapOrgs = ({ value: orgId = '', label: name = '' } = {}) => ({
                        orgId,
                        name,
                    });
                    return set(
                        {
                            ...state,
                        },
                        field,
                        (value || []).map(mapOrgs)
                    );
                }
                if (field === 'allowListEmails') {
                    if (value.length > 10) {
                        return state;
                    }
                    const newState = set(
                        {
                            ...state,
                        },
                        field,
                        [...new Set(value.filter(validateEmail))]
                    );
                    // remove any allowlisted emails from escalaton emails
                    return set(
                        {
                            ...newState,
                        },
                        'escalationEmails',
                        [
                            ...new Set(
                                newState.escalationEmails.filter(
                                    email =>
                                        validateEmail(email) &&
                                        !newState.allowListEmails.includes(email)
                                )
                            ),
                        ]
                    );
                }
                if (field === 'escalationEmails') {
                    if (value.length > 10) {
                        return state;
                    }
                    return set(
                        {
                            ...state,
                        },
                        field,
                        [
                            ...new Set(
                                value.filter(
                                    email =>
                                        validateEmail(email) &&
                                        !state.allowListEmails.includes(email)
                                )
                            ),
                        ]
                    );
                }
                if (field === 'defaultSettings' && value === 'allDataStores') {
                    return set(
                        {
                            ...state,
                            selectedDataStores: [],
                        },
                        field,
                        value
                    );
                }
                if (field === 'internalDueItems' && value === 'internalDueDefault') {
                    return set(
                        {
                            ...state,
                            internalDueDays: null,
                        },
                        field,
                        value
                    );
                }
                return set(
                    {
                        ...state,
                    },
                    field,
                    value
                );
            },
        }),
        errors: genericReducer(initialState.errors, {
            [ActionTypes.updateForm]: clearFieldError(''),
            [ActionTypes.updateField]: clearFieldError('fields.'),
            [ActionTypes.deleteField]: clearFieldError('fields.'),
            [ActionTypes.addFieldOption]: (state, { id }) => {
                const { fields = {} } = state;
                const options = fields[id]?.options;
                if (typeof options === 'string') {
                    return omit({ ...state }, `fields.${id}.options`);
                }
                return state;
            },
            [ActionTypes.useDefaultOptions]: (state, { id }) =>
                clearFieldError('fields.')(state, { id, field: `options`, value: true }),
            [ActionTypes.deleteFieldOption]: (state, { id, optionIndex, value }) =>
                clearFieldError('fields.')(state, { id, field: `options.${optionIndex}`, value }),
            [ActionTypes.updateFieldOption]: (state, { id, optionIndex, field, value }) =>
                clearFieldError(`fields.`)(state, {
                    id,
                    field: `options.${optionIndex}.${field}`,
                    value,
                }),
            [ActionTypes.dragFieldOption]: (state, { id, dragIndex, hoverIndex }) => {
                const { fields = {} } = state;
                const fieldOptions = Object.entries(fields[id]?.options || {}).sort(
                    ([indexA], [indexB]) => parseInt(indexA, 10) - parseInt(indexB, 10)
                );
                return {
                    ...state,
                    fields: {
                        ...fields,
                        ...(fields[id]
                            ? {
                                  [id]: {
                                      ...fields[id],
                                      ...(fieldOptions.length
                                          ? {
                                                options: fieldOptions.reduce(
                                                    (newOptions, [index, value]) => {
                                                        let idx = parseInt(index, 10);
                                                        if (idx === dragIndex) {
                                                            idx = hoverIndex;
                                                        } else if (
                                                            idx > dragIndex &&
                                                            idx < hoverIndex
                                                        ) {
                                                            idx--;
                                                        } else if (idx >= hoverIndex) {
                                                            idx++;
                                                        }
                                                        newOptions[`${idx}`] = value;
                                                        return newOptions;
                                                    },
                                                    {}
                                                ),
                                            }
                                          : {}),
                                  },
                              }
                            : {}),
                    },
                };
            },
            [ActionTypes.fieldErrors]: (state, errors) => ({ ...errors }),

            [ActionTypes.fetchDetailsSuccess]: () => {
                return {};
            },
        }),
    }),
    genericReducer(initialState, {
        [AuthActionTypes.logout]: () => initialState,
        [NavActionTypes.routeChanged]: (state, payload) => {
            const { location, action } = payload;
            const match = matchPath(
                location.pathname,
                `${AuthenticatedPaths.DSAR_FORMS}/:formId?/:tab?`
            );
            const { params: { formId } = {} } = match || {};
            if (action === 'LOAD' && match) {
                return {
                    ...state,
                    id: formId,
                };
            }
            if (!match || !formId || (formId === 'new' && state.id !== formId)) {
                return { ...initialState, id: match ? formId : '' };
            }
            return state;
        },
    })
);
