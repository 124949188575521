// @ts-check
const { GeoEChart } = require('/b2b/common/components/Charts');

import {
    Alert,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo } from 'react';

import DashboardContext from '../Dashboard.context';
import ErrorBoundary from '/b2b/common/components/ErrorBoundary';
import InfoIcon from '@mui/icons-material/Info';
import Locale from '/b2b/common/components/Locale';
import LocalizedTooltip from '/b2b/common/components/LocalizedTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import Select from '/b2b/common/components/FormControls/Select';
import { fetchUcReportsDashboard } from '/b2b/uc/services';
import { getMapData } from '/b2b/common/components/Charts/EChart.geo';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { theme } from '/b2b/common/components/Charts';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';

export const REPORT_NAME = 'consentsByJurisdiction';

const { color } = theme;
const colorForSeries = i => color[i % color.length];

const allFilters = [
    { id: 'totalConsents', name: 'common.table.filter.all' },
    { id: 'totalOriginalConsents', name: 'uc.section.dashboards.label.originalConsent' },
    { id: 'totalSwitched', name: 'uc.section.dashboards.label.changedConsent' },
    { id: 'totalSwitchedApproved', name: 'uc.section.dashboards.label.switchedToOptIn' },
    { id: 'totalSwitchedDenied', name: 'uc.section.dashboards.label.switchedToOptOut' },
];

const toDivisionCode = (divisionOrCountryCode, regionCode) =>
    `${divisionOrCountryCode.split('_').join('-')}${regionCode ? `-${regionCode}` : ''}`.toLowerCase();
const createSeriesForJurisdiction = (renderTooltip, zoom, filter, data) => {
    const { countryCode, regionCode, consents = [] } = data;
    const divisionCode = toDivisionCode(countryCode, regionCode);
    const { area, center } = getMapData(divisionCode);
    const [x1, y1, x2, y2] = area;
    const wh = Math.min(x2 - x1, y2 - y1);
    const size = wh;
    return consents.length
        ? {
              type: 'pie',
              id: divisionCode,
              animationDurationUpdate: 400,
              coordinateSystem: 'geo',
              seriesLayoutBy: 'row',
              label: {
                  show: false,
              },
              labelLine: {
                  show: false,
              },
              animationDuration: 0,
              // radius: `${size}%`,
              width: size * zoom,
              height: size * zoom,
              center: center || divisionCode,
              emphasis: {
                  disabled: true,
              },
              scaleLimit: {
                  min: 1,
              },
              data: consents.map(({ privacyProtocolId, consents }) => ({
                  name: privacyProtocolId,
                  value: consents[filter],
              })),
              dimensions: [
                  {
                      name: 'privacyProtocolId',
                      type: 'int',
                  },
                  {
                      name: filter,
                      type: 'int',
                  },
              ],
              tooltip: {
                  formatter: renderTooltip,
              },
              //   silent: true, // Do not trigger events on mouseover or click on the pie
              encode: {
                  itemName: 'divisionCode',
                  value: divisionCode,
              },
          }
        : null;
};

/** @type {import('echarts').EChartsOption} */
const staticOptions = {
    legend: {
        show: false,
    },
    tooltip: {},
    // @ts-ignore
    geo: {
        left: '20%',
        right: 0,
    },
    dataset: {
        sourceHeader: true,
    },
};

/**\
 * @param {import('@mui/material').CheckboxProps & { index: number }} props
 * @returns {JSX.Element}
 */
const SeriesCheckbox = ({ index, ...remain }) => {
    const color = `echartsSeries${(index % 8) + 1}`;
    return (
        <Checkbox
            {...remain}
            // @ts-ignore
            checkedIcon={<CheckBox color={color} />}
            // @ts-ignore
            icon={<CheckBoxOutlineBlank color={color} />}
        />
    );
};

SeriesCheckbox.propTypes = {
    index: PropTypes.number.isRequired,
};

function resetProtocolsSelected(protocolIds) {
    return protocolIds.reduce((acc, privacyProtocolId) => {
        acc[privacyProtocolId] = true;
        return acc;
    }, {});
}

const csvColumnMap = {
    divisionName: 'common.jurisdiction',
    divisionCode: 'common.fields.code.label',
    name: 'common.fields.name.label',
    privacyProtocolId: 'uc.label.protocol',
    totalConsents: 'common.total',
    totalOriginalConsents: 'uc.section.dashboards.label.originalConsent',
    totalSwitched: 'uc.section.dashboards.label.changedConsent',
    totalSwitchedApproved: 'uc.section.dashboards.label.switchedToOptIn',
    totalSwitchedDenied: 'uc.section.dashboards.label.switchedToOptOut',
};

const processRow = (row, columnNames, privacyProtocolNames) => {
    const { countryCode, regionCode, consents } = row;
    const divisionCode = toDivisionCode(countryCode, regionCode);
    const mapData = getMapData(divisionCode);
    const { divisionLevel } = mapData;
    let divisionName = divisionCode;
    switch (divisionLevel) {
        case 0: {
            // Countries
            divisionName = translate(`countries.${divisionCode}`);
            break;
        }
        case 1: {
            // States/Provinces/Territories
            divisionName = translate(`division.${divisionCode}`);
            break;
        }
        case 2: {
            // Counties/Regions/Districts
            divisionName = translate(`regions.${divisionCode}`);
            break;
        }
        default: {
            break;
        }
    }
    return consents.reduce((acc, consent) => {
        acc.push(
            columnNames
                .reduce((protocolRow, column) => {
                    switch (column) {
                        case 'divisionName':
                            protocolRow.push(divisionName);
                            break;
                        case 'divisionCode':
                            protocolRow.push(divisionCode);
                            break;
                        case 'name':
                            protocolRow.push(privacyProtocolNames[consent.privacyProtocolId]);
                            break;
                        default:
                            protocolRow.push(consent[column]);
                            break;
                    }
                    return protocolRow;
                }, [])
                .join(',')
        );
        return acc;
    }, []);
};

function makeCSV(data) {
    const { consentsByJurisdiction, privacyProtocolNames } = data || {};
    const columnNames = Object.keys(csvColumnMap);
    // String CSV
    const csvFile = Object.values(consentsByJurisdiction)
        .reduce(
            (/** @type {string[]} */ acc, row) => {
                const rowArray = processRow(row, columnNames, privacyProtocolNames);
                return acc.concat(rowArray);
            },
            [columnNames.map(column => translate(csvColumnMap[column])).join(',')]
        )
        .join('\n');
    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

const prepareData = data => {
    // This is where we would prepare the data if we needed to
    return data;
};

const ConsentsByJurisdiction = props => {
    const { data: providedData, filters: globalFilters, loading: providedLoading } = props;
    const [filter, setFilter] = React.useState(allFilters[0].id);
    const { data, error, load, loading } = useContext(DashboardContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [preventAnimation, setPreventAnimation] = React.useState(false);
    const [zoom, setZoom] = React.useState(1);
    const open = Boolean(anchorEl);
    const isLoading = loading || providedLoading;
    const { consentsByJurisdiction = [], privacyProtocolNames } = data || {};
    const [geoLookup, privacyProtocolIds] = useMemo(() => {
        const geoLookup = {};
        const privacyProtocolIds = new Set();
        consentsByJurisdiction.forEach(geoData => {
            const { countryCode, regionCode, consents } = geoData;
            const divisionCode = toDivisionCode(countryCode, regionCode);
            consents.forEach(
                ({
                    privacyProtocolId,
                    consents: {
                        totalConsents,
                        totalOriginalConsents,
                        totalSwitched,
                        totalSwitchedApproved,
                        totalSwitchedDenied,
                    },
                }) => {
                    privacyProtocolIds.add(privacyProtocolId);
                    geoLookup[divisionCode] = geoLookup[divisionCode] || {};
                    geoLookup[divisionCode][privacyProtocolId] = {
                        totalConsents,
                        totalOriginalConsents,
                        totalSwitched,
                        totalSwitchedApproved,
                        totalSwitchedDenied,
                    };
                }
            );
        });
        return [geoLookup, Array.from(privacyProtocolIds)];
    }, [consentsByJurisdiction]);

    const onChange = useCallback(event => {
        setFilter(event.target.value);
    }, []);

    const echartsInstance = React.useRef(null);
    /** @type {import('/b2b/common/theme').OsanoDefaultTheme} */
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const [protocolsSelected, setProtocolsSelected] = React.useState(
        resetProtocolsSelected(privacyProtocolIds)
    );

    const renderTooltip = useBoundCallback(
        (privacyProtocolNames, protocolsSelected, geoLookup, filter, params) => {
            const { componentType, seriesId, name } = params;
            const mapData = getMapData(componentType === 'series' ? seriesId : name);
            const { divisionCode, divisionLevel } = mapData;
            let divisionName = name;
            switch (divisionLevel) {
                case 0: {
                    // Countries
                    divisionName = translate(`countries.${divisionCode}`);
                    break;
                }
                case 1: {
                    // States/Provinces/Territories
                    divisionName = translate(`divisions.${divisionCode}`);
                    break;
                }
                case 2: {
                    // Counties/Regions/Districts
                    divisionName = translate(`regions.${divisionCode}`);
                    break;
                }
                default: {
                    divisionName = name;
                    break;
                }
            }
            const protocolIds = Object.keys(privacyProtocolNames);
            const selectedProtocols = protocolIds.filter(
                privacyProtocolId => protocolsSelected[privacyProtocolId]
            );
            const geoData = geoLookup[divisionCode];
            if (!selectedProtocols.length || !geoData) {
                return '';
            }
            // eslint-disable-next-line xss/no-mixed-html
            return `<b>${divisionName}</b><hr style="margin:0.25em 0"/>${selectedProtocols
                .map((protocolId, i) => {
                    const protocolData = geoLookup[divisionCode]?.[protocolId];
                    if (!protocolData) {
                        return '';
                    }
                    const name = privacyProtocolNames[protocolId];
                    let total = protocolData[`${filter}`] || 0;
                    return `<div style="display:inline-block;background-color:${colorForSeries(i)};border-radius:0.25em;height:0.75em;width:0.75em;vertical-align:baseline"></div> ${name}: ${total}`;
                })
                .join('<br>')}`;
        },
        [privacyProtocolNames, protocolsSelected, geoLookup, filter]
    );

    const createZoomedSeriesForJurisdiction = useBoundCallback(createSeriesForJurisdiction, [
        renderTooltip,
        zoom,
        filter,
    ]);

    const noResults = consentsByJurisdiction.length === 0;

    const animationDurationUpdate = useBoundCallback(
        preventAnimation => {
            const duration = preventAnimation ? 0 : 400;
            if (preventAnimation) {
                setPreventAnimation(false);
            }
            return duration;
        },
        [preventAnimation]
    );

    useEffect(() => {
        setProtocolsSelected(resetProtocolsSelected(privacyProtocolIds));
    }, [privacyProtocolIds]);

    const memoOption = useMemo(
        () =>
            merge({}, staticOptions, {
                geo: {
                    animationDurationUpdate,
                    tooltip: {
                        formatter: renderTooltip,
                    },
                },
                series: consentsByJurisdiction
                    .map(createZoomedSeriesForJurisdiction)
                    .filter(Boolean),
            }),
        [consentsByJurisdiction, privacyProtocolIds, geoLookup, filter, zoom]
    );

    const onLegendChanged = useBoundCallback(
        (privacyProtocolIds, protocolsSelected, event) => {
            const { type } = event;
            switch (type) {
                case 'legendselected':
                case 'legendunselected':
                case 'legendselectall':
                case 'legendselectchanged':
                case 'legendinverseselect': {
                    const { selected } = event;
                    setProtocolsSelected(selected);
                    break;
                }
                case 'click':
                case 'change': {
                    const { target } = event;
                    // The `name` of the checkbox is the privacyProtocolId
                    const { checked, name, value } = target;
                    if (checked === undefined) {
                        // This is the select dropdown
                        setProtocolsSelected(
                            value.reduce((acc, key) => {
                                acc[key] = true;
                                return acc;
                            }, {})
                        );
                        if (echartsInstance.current) {
                            const selectBatch = [];
                            const unselectBatch = [];
                            privacyProtocolIds.forEach(privacyProtocolId => {
                                if (
                                    value.includes(privacyProtocolId) &&
                                    !protocolsSelected[privacyProtocolId]
                                ) {
                                    selectBatch.push(privacyProtocolId);
                                } else if (
                                    !value.includes(privacyProtocolId) &&
                                    protocolsSelected[privacyProtocolId]
                                ) {
                                    unselectBatch.push(privacyProtocolId);
                                }
                            });
                            selectBatch.forEach(name => {
                                echartsInstance.current.dispatchAction({
                                    type: 'legendSelect',
                                    name,
                                });
                            });
                            unselectBatch.forEach(name => {
                                echartsInstance.current.dispatchAction({
                                    type: 'legendUnSelect',
                                    name,
                                });
                            });
                        }
                    } else {
                        setProtocolsSelected(prev => {
                            return {
                                ...prev,
                                [name]: checked,
                            };
                        });
                        if (checked) {
                            echartsInstance.current &&
                                echartsInstance.current.dispatchAction({
                                    type: 'legendSelect',
                                    name,
                                });
                        } else {
                            echartsInstance.current &&
                                echartsInstance.current.dispatchAction({
                                    type: 'legendUnSelect',
                                    name,
                                });
                        }
                    }
                    break;
                }
            }
        },
        [privacyProtocolIds, protocolsSelected]
    );

    const onGeoRoam = useBoundCallback(
        (zoom, params) => {
            const { totalZoom = zoom } = params;
            setZoom(totalZoom);
        },
        [zoom]
    );

    const onSelectChanged = useBoundCallback(params => {
        const { name } = params;
        if (!name) {
            setZoom(1);
        } else {
            const { zoom = 1 } = getMapData(name);
            setZoom(zoom);
        }
    }, []);

    const onEvents = {
        georoam: onGeoRoam,
        geoselectchanged: onSelectChanged,
        geoselected: onSelectChanged,
        legendselected: onLegendChanged,
        legendunselected: onLegendChanged,
        legendselectall: onLegendChanged,
        legendselectchanged: onLegendChanged,
        legendinverseselect: onLegendChanged,
    };

    const onChartReady = React.useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    const onChartResize = useBoundCallback(() => {
        setPreventAnimation(true);
    }, []);

    const renderProtocolNameFromIdArray = useBoundCallback(
        (privacyProtocolNames, ids = []) => {
            return ids
                .map(id => {
                    return privacyProtocolNames[`${id}`] || id;
                })
                .join(', ');
        },
        [privacyProtocolNames]
    );

    /** @type {() => Promise<UcReportsData>} */
    const fetchData = useBoundCallback(
        globalFilters =>
            fetchUcReportsDashboard({
                filters: {
                    ...globalFilters,
                    requestedCharts: [REPORT_NAME],
                },
            }).then(prepareData),
        [globalFilters]
    );

    const helpers = useMemo(
        () => ({
            makeCSV,
            name: 'consents-by-jurisdiction',
        }),
        []
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);

    const handleActionsClick = useBoundCallback(
        (open, event) => {
            setAnchorEl(open ? null : event.currentTarget);
        },
        [open]
    );

    const handleActionsClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDownloadCsv = useCallback(() => {
        if (echartsInstance.current) {
            echartsInstance.current.dispatchAction({
                type: 'download',
                format: 'csv',
            });
        }
        handleActionsClose();
    }, [handleActionsClose]);

    const handleDownloadImage = useCallback(() => {
        if (echartsInstance.current) {
            echartsInstance.current.dispatchAction({
                type: 'download',
                format: 'png',
            });
        }
        handleActionsClose();
    }, [handleActionsClose]);

    const hasProtocols = Object.keys(privacyProtocolIds).length > 0;
    const gridTemplateRows = isLarge
        ? 'auto 1fr'
        : isSmall
          ? hasProtocols
              ? 'auto auto 1fr'
              : 'auto 1fr'
          : hasProtocols
            ? 'auto auto auto 1fr'
            : 'auto auto 1fr';

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(prepareData(providedData))) : load(fetchData());
    }, [providedData, globalFilters]);

    return (
        <Box
            component={Paper}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridTemplateRows={error ? `auto ${gridTemplateRows}` : gridTemplateRows}
            gap={0}
            sx={{ height: '100%' }}
        >
            {error ? (
                <Alert severity="error">
                    <Typography variant="caption">{error}</Typography>
                </Alert>
            ) : null}
            <Box
                borderBottom={theme => (isLarge ? `1px solid ${theme.palette.divider}` : 'none')}
                display="flex"
                alignItems="center"
                gridColumn={isSmall ? 'span 7' : 'span 12'}
                p={2}
            >
                <Typography variant="h5">
                    <Locale path="uc.section.dashboards.title.consentsByJurisdiction" />
                    <LocalizedTooltip title="uc.section.dashboards.tooltip.consentsByJurisdiction">
                        <InfoIcon
                            sx={{
                                color: theme.palette.common.grey[400],
                                cursor: 'help',
                                fontSize: '1em',
                                ml: 0.5,
                                verticalAlign: 'middle',
                            }}
                        />
                    </LocalizedTooltip>
                </Typography>
            </Box>
            <Box
                borderBottom={theme => (isLarge ? `1px solid ${theme.palette.divider}` : 'none')}
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
                gridColumn={isSmall ? 'span 5' : 'span 12'}
                p={2}
            >
                <Select
                    label={<Locale path="uc.section.dashboards.label.consentType" />}
                    fullWidth
                    onChange={onChange}
                    disabled={isLoading || !hasProtocols}
                    value={filter}
                >
                    {allFilters.map(({ name, id }) => (
                        <MenuItem key={id} value={id} dense>
                            <Locale path={name} />
                        </MenuItem>
                    ))}
                </Select>
                <LocalizedTooltip sx={{ ml: 1, mr: -1, mt: 1 }} title="common.actions">
                    <span>
                        <IconButton disabled={noResults} onClick={handleActionsClick}>
                            <MoreVertIcon />
                        </IconButton>
                    </span>
                </LocalizedTooltip>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleActionsClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <MenuList>
                        <MenuItem onClick={handleDownloadCsv}>
                            <Locale path="common.table.downloadCsv" />
                        </MenuItem>
                        <MenuItem onClick={handleDownloadImage}>
                            <Locale path="common.table.saveAsImage" />
                        </MenuItem>
                    </MenuList>
                </Popover>
            </Box>
            <Box
                borderRight={theme => (isLarge ? `1px solid ${theme.palette.divider}` : 'none')}
                component={FormGroup}
                gridColumn={isLarge ? 'span 3' : 'span 12'}
                gridRow={2}
                p={2}
                py={{ xs: 0, lg: 2 }}
                sx={{
                    display: hasProtocols ? undefined : 'none',
                    ...(isLarge && {
                        alignContent: 'flex-start',
                        flexDirection: 'row',
                        height: '100%',
                        overflowY: 'auto',
                    }),
                }}
            >
                {isLarge ? (
                    privacyProtocolIds.map((privacyProtocolId, i) => {
                        const name = renderProtocolNameFromIdArray([privacyProtocolId]);
                        return (
                            <FormControlLabel
                                key={privacyProtocolId}
                                control={
                                    <SeriesCheckbox
                                        name={privacyProtocolId}
                                        index={i}
                                        checked={protocolsSelected[privacyProtocolId]}
                                        onChange={onLegendChanged}
                                    />
                                }
                                label={name}
                                sx={{ width: '100%' }}
                            />
                        );
                    })
                ) : (
                    <Select
                        label={<Locale path="uc.label.protocol" />}
                        multiple
                        value={Object.entries(protocolsSelected)
                            .filter(([, value]) => value)
                            .map(([key]) => key)}
                        onChange={onLegendChanged}
                        fullWidth
                        renderValue={renderProtocolNameFromIdArray}
                    >
                        {privacyProtocolIds.map((privacyProtocolId, i) => (
                            <MenuItem
                                key={privacyProtocolId}
                                value={privacyProtocolId}
                                dense
                                disableGutters
                            >
                                <SeriesCheckbox
                                    index={i}
                                    checked={protocolsSelected[privacyProtocolId]}
                                />
                                <ListItemText
                                    primary={renderProtocolNameFromIdArray([privacyProtocolId])}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </Box>
            <ErrorBoundary fallback={null}>
                <GeoEChart
                    gridColumn={isLarge && hasProtocols ? 'span 9' : 'span 12'}
                    loading={isLoading}
                    makeDownload={makeDownload}
                    noResults={noResults}
                    onChartReady={onChartReady}
                    // @ts-ignore
                    onEvents={onEvents}
                    onResize={onChartResize}
                    option={memoOption}
                    replaceMerge={['series']}
                />
            </ErrorBoundary>
        </Box>
    );
};

ConsentsByJurisdiction.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
};

export default withAsyncData(ConsentsByJurisdiction, DashboardContext);
