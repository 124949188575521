import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    singleParameter,
} from '../../ReduxHelpers';

export const events = {
    decrementPlanData: singleParameter,
    incrementPlanData: singleParameter,
    reload: singleParameter,
    setFilterModel: singleParameter,
    setFilters: singleParameter,
    setPage: singleParameter,
    setQuery: singleParameter,
    setLimit: singleParameter,
    setSort: namedParameters('column', 'direction'),
    submit: singleParameter,
    updateFilters: singleParameter,
};

export const asyncEvents = makeAsyncEvents(
    ['fetchResults', 'copy', 'create', 'delete', 'update'],
    true
);

export const types = makeActionTypes(
    [...Object.keys(events), ...Object.keys(asyncEvents)],
    'Hooks__ORMResults'
);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
