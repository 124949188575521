import Component from './Greeting.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => {
    const userId = fromState.Auth.user.getId(state);
    const details = fromState.DB.user.getEntityById(state, userId);
    const { firstName } = details;

    return { firstName };
};

export default connect(mapStateToProps)(Component);
