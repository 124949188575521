import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
} from '../../common/helpers/ReduxHelpers';

import { DOMAIN } from './Dashboard.constants';
import { singleParameter } from '../../common/helpers/Redux/parameterize';

export const events = {
    updateTodoPreferences: singleParameter,
};

export const asyncEvents = makeAsyncEvents(['fetchDetails']);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
