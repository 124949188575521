import { Grid } from '@mui/material';
import { styled } from '@mui/styles';

export const Grid50 = styled(Grid)(({ theme }) => {
    return {
        height: '100vw',
        [theme.breakpoints.up('sm')]: {
            height: `75vw`,
        },
        [theme.breakpoints.up('md')]: {
            height: `65vw`,
        },
        [theme.breakpoints.up('lg')]: {
            height: `calc(calc(100vw - ${theme.width.drawer}) * 0.65)`,
        },
        [theme.breakpoints.up('xl')]: {
            height: `calc(calc(100vw - ${theme.width.drawer}) * 0.4)`,
        },
    };
});

export const Grid25 = styled(Grid)(({ theme }) => {
    return {
        height: '50vw',
        [theme.breakpoints.up('md')]: {
            height: `40vw`,
        },
        [theme.breakpoints.up('lg')]: {
            height: `calc(calc(100vw - ${theme.width.drawer}) * 0.4)`,
        },
        [theme.breakpoints.up('xl')]: {
            height: `calc(calc(100vw - ${theme.width.drawer}) * 0.25)`,
        },
    };
});

export default {
    Grid25,
    Grid50,
};
