// @ts-check
export { theme, themeName } from './EChart.setup';

import * as echarts from 'echarts/core';

import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { deepKoamaruLight, grey, white } from '../../colors';

import { Box } from '@mui/material';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PropTypes from 'prop-types';
import UIActions from '../../state/ui/UI.actions';
import { getLocaleCode } from '../../state/locale/Locale.selectors';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import merge from 'lodash/merge';
import { primary } from '../../colors';
import { renderToStaticMarkup } from 'react-dom/server';
import { translate } from '../../helpers/i18n';
import typography from '../../theme/typography';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useDispatch } from 'react-redux';
import useResizeObserver from '@react-hook/resize-observer';
import { useSelector } from 'react-redux';

const iconSize = 250;

const svg = renderToStaticMarkup(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill={grey[100]}
    >
        <LeaderboardIcon />
    </svg>
);

const dataUrl = `data:image/svg+xml;base64,${btoa(svg)}`;

export const CsvIcon =
    'path://M50,5C32.6,5,15,9.6,15,20L15,80C15,90.4,32.6,95,50,95C67.4,95,85,90.4,85,80L85,20C85,9.6,67.4,5,50,5ZM77.5,80C77.5,82.5,67.8,87.5,50,87.5S22.5,82.5,22.5,80L22.5,67C28.6,70.6,39.3,72.5,50,72.5S71.4,70.6,77.5,67L77.5,80ZM50,67.5C32.2,67.5,22.5,62.5,22.5,60L22.5,47C28.6,50.6,39.3,52.5,50,52.5S71.4,50.6,77.5,47L77.5,60C77.5,62.5,67.8,67.5,50,67.5ZM50,47.5C32.2,47.5,22.5,42.5,22.5,40L22.5,27C28.6,30.6,39.3,32.5,50,32.5S71.4,30.6,77.5,27L77.5,40C77.5,42.5,67.8,47.5,50,47.5ZM50,27.5C32.2,27.5,22.5,22.5,22.5,20S32.2,12.5,50,12.5S77.5,17.5,77.5,20S67.8,27.5,50,27.5Z';

export const LegendIcon =
    'path://M4 0 H12 a4 4 0 0 1 4 4 V12 a4 4 0 0 1 -4 4 H4 a4 4 0 0 1 -4 -4 V4 a4 4 0 0 1 4 -4z';

/** @type {import('echarts').GraphicComponentOption} */
export const NoResultsRectangle = {
    type: 'rect',
    shape: {
        x: 0,
        y: 0,
    },
    style: {
        fill: white,
        opacity: 1,
    },
    z: Number.MAX_SAFE_INTEGER,
};

/** @type {import('echarts').GraphicComponentOption} */
export const NoResultsIcon = {
    type: 'image',
    left: 'center',
    top: 'center',
    style: {
        image: dataUrl,
        width: iconSize,
        height: iconSize,
    },
    z: Number.MAX_SAFE_INTEGER,
};

/** @type {import('echarts').GraphicComponentOption} */
export const NoResultsText = {
    type: 'text',
    left: 'center',
    top: 'center',
    silent: true,
    z: Number.MAX_SAFE_INTEGER,
    style: {
        fill: deepKoamaruLight,
        font: `italic ${typography.body1.fontSize} ${typography.body1.fontFamily}`.trim(),
    },
};

/** @typedef {'csv'} MakeDownloadType */

/**
 * @typedef MakeDownloadResponse
 * @property {Blob} file The Blob to download
 * @property {string} [name] The name of the file
 */

/**
 * @export
 * @typedef EChartProps
 * @property {boolean} [autoResize]
 * @property {string} [className]
 * @property {import('echarts/core').SetOptionOpts['lazyUpdate']} [lazyUpdate] If true, the chart will not update until the next frame
 * @property {(type: MakeDownloadType, option: import('echarts').EChartsOption) => Promise<MakeDownloadResponse | Blob>} [makeDownload] A function that returns a Blob to download
 * @property {boolean} [loading]
 * @property {boolean} [noResults]
 * @property {import('echarts/core').SetOptionOpts['notMerge']} [notMerge] If true, the option will not be merged
 * @property {function} [onChartReady]
 * @property {Partial<Record<import('echarts/core').ElementEvent['type'], (event: import('echarts/core').ElementEvent['event']) => boolean | void>>} [onEvents]
 * @property {(entry: ResizeObserverEntry) => void} [onResize]
 * @property {import('echarts').EChartsOption} [option]
 * @property {React.MutableRefObject<HTMLElement> | ((instance: HTMLElement) => void)} [ref]
 * @property {import('echarts/core').SetOptionOpts['replaceMerge']} [replaceMerge] The option keys to replace instead of merge
 * @property {boolean} [silent] If true, the chart will not trigger events
 * @property {object} [sx]
 * @property {string} [theme]
 * @property {import('echarts/core').SetOptionOpts['transition']} [transition] If true, the chart will transition
 */

function bindEvents(instance, events) {
    function _bindEvent(eventName, func) {
        if (isString(eventName) && isFunction(func)) {
            instance.on(eventName, func);
        }
    }

    for (const eventName in events) {
        if (Object.prototype.hasOwnProperty.call(events, eventName)) {
            _bindEvent(eventName, events[eventName]);
        }
    }
}

function unbindEvents(instance, events) {
    function _unbindEvent(eventName, func) {
        if (isString(eventName) && isFunction(func)) {
            instance.off(eventName, func);
        }
    }

    for (const eventName in events) {
        if (Object.prototype.hasOwnProperty.call(events, eventName)) {
            _unbindEvent(eventName, events[eventName]);
        }
    }
}

function createInstance(props, el) {
    const { opts, theme } = props;
    function init(tempOpts = {}) {
        echarts.init(el, theme, { ...tempOpts, ...opts });
        return echarts.getInstanceByDom(el);
    }
    return new Promise(resolve => {
        const instance = init();
        instance.on('finished', () => {
            const width = el.clientWidth;
            const height = el.clientHeight;
            echarts.dispose(el);
            resolve(init({ width, height }));
        });
    });
}

/** @type {React.FC<EChartProps>} */
const EChart = props => {
    const {
        autoResize = true,
        lazyUpdate,
        loading,
        makeDownload,
        notMerge,
        noResults,
        onResize,
        onChartReady,
        onEvents,
        option,
        ref,
        replaceMerge,
        silent,
        sx,
        transition,
        ...remain
    } = props;

    /** @type {React.MutableRefObject<HTMLElement>} */
    const el = useRef(null);
    /** @type {React.MutableRefObject<HTMLAnchorElement>} */
    const linkRef = useRef(null);
    /** @type {[import('echarts/core').ECharts, React.Dispatch<import('echarts/core').ECharts>]} */
    const [instance, setInstance] = useState(null);
    const [initialResize, setInitialResize] = useState(true);
    const initEchartsInstance = useBoundCallback(createInstance, [props]);
    const dispatch = useDispatch();

    /** @type {(entry?: ResizeObserverEntry, observer?: ResizeObserver) => void} */
    const resize = useBoundCallback(
        (instance, initialResize, autoResize, onResize, entry) => {
            if (!initialResize && autoResize) {
                try {
                    instance.resize({
                        width: 'auto',
                        height: 'auto',
                    });
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.warn(e);
                }
            }
            onResize && onResize(entry);
            setInitialResize(false);
        },
        [instance, initialResize, autoResize, onResize]
    );

    useResizeObserver(el, resize);

    const setRef = useCallback((/** @type {HTMLElement} */ e) => {
        el.current = e;
        if (isFunction(ref)) {
            ref(e);
        } else if (ref) {
            ref.current = e;
        }
        initEchartsInstance(e).then(instance => {
            setInstance(instance);
            resize();
        });
    }, []);

    /** @type {import('echarts/core').SetOptionOpts} */
    const memoOptionOptions = useMemo(
        () => ({
            lazyUpdate,
            notMerge,
            // always replace graphic as that's our no results overlay
            replaceMerge: ['graphic', ...(Array.isArray(replaceMerge) ? replaceMerge : [])],
            silent,
            transition,
        }),
        [
            lazyUpdate,
            notMerge,
            JSON.stringify(replaceMerge), // This is an array, so we need to stringify it to avoid unnecessary updates due to inline prop creation
            silent,
            JSON.stringify(transition), // This is an object or array of object, so we need to stringify it to avoid unnecessary updates due to inline prop creation
        ]
    );

    const memoLoadingOptions = useMemo(
        () => ({
            text: '',
            color: primary.main,
            maskColor: 'rgba(255, 255, 255, 0.8)',
            zlevel: Number.MAX_SAFE_INTEGER,
            showSpinner: true,
            spinnerRadius: 18,
            lineWidth: 4,
        }),
        []
    );

    const memoSx = useMemo(() => ({ width: '100%', height: '100%', ...sx }), [sx]);

    const onDownload = useBoundCallback(
        async (instance, makeDownload, option, event) => {
            const { type } = event;
            try {
                instance.showLoading('default', memoLoadingOptions);
                const download = await makeDownload(event, option);
                instance.hideLoading();
                const { file = download, name = `chart.${type}` } = download;
                const url = URL.createObjectURL(file);
                try {
                    URL.revokeObjectURL(linkRef.current.href);
                } catch (e) {
                    // Might not be there
                }
                linkRef.current.setAttribute('href', url);
                linkRef.current.setAttribute('download', name);
                linkRef.current.click();
            } catch (error) {
                // @ts-ignore
                dispatch(UIActions.createSnack(translate(error.message), { variant: 'error' }));
                instance.hideLoading();
            }
        },
        [instance, makeDownload, option]
    );

    const shouldRetranslate = useSelector(getLocaleCode);

    useLayoutEffect(() => {
        if (!instance || !option) {
            return;
        }

        const showOverlay = loading || noResults;
        const showNoResults = noResults && !loading;

        loading ? instance.showLoading('default', memoLoadingOptions) : instance.hideLoading();

        instance.setOption(
            {
                ...option,
                ...(showOverlay
                    ? {
                          graphic: [
                              merge({}, NoResultsRectangle, {
                                  shape: {
                                      width: instance.getWidth(),
                                      height: instance.getHeight(),
                                  },
                              }),
                              ...(showNoResults
                                  ? [
                                        NoResultsIcon,
                                        merge({}, NoResultsText, {
                                            style: {
                                                text: translate('common.charts.notEnoughData'),
                                            },
                                        }),
                                    ]
                                  : []),
                          ].filter(Boolean),
                      }
                    : {}),
            },
            memoOptionOptions
        );
    }, [instance, loading, noResults, option, shouldRetranslate]);

    useLayoutEffect(() => {
        if (instance) {
            bindEvents(instance, onEvents || {});
            return () => {
                unbindEvents(instance, onEvents || {});
            };
        }
    }, [instance, onEvents]);

    useLayoutEffect(() => {
        if (instance) {
            const handleDownload = function (event) {
                onDownload(event);
            }.bind(instance);
            instance.on('download', handleDownload);
            isFunction(onChartReady) && onChartReady(instance);
            return () => {
                instance.off('download', handleDownload);
                instance.dispose();
            };
        }
    }, [instance]);

    return (
        <>
            <Box ref={setRef} sx={memoSx} {...remain} />
            <a href="#" ref={linkRef} style={{ display: 'none' }} />
        </>
    );
};

EChart.defaultProps = {
    theme: 'osano',
};

EChart.propTypes = {
    autoResize: PropTypes.bool,
    className: PropTypes.string,
    lazyUpdate: PropTypes.bool,
    loading: PropTypes.bool,
    makeDownload: PropTypes.func,
    noResults: PropTypes.bool,
    notMerge: PropTypes.bool,
    option: PropTypes.shape({
        title: PropTypes.object,
        legend: PropTypes.object,
        xAxis: PropTypes.object,
        yAxis: PropTypes.object,
        series: PropTypes.array,
        dataset: PropTypes.object,
        graphic: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
        toolbox: PropTypes.object,
    }),
    onChartReady: PropTypes.func,
    onEvents: PropTypes.object,
    onResize: PropTypes.func,
    ref: PropTypes.any,
    replaceMerge: PropTypes.array,
    silent: PropTypes.bool,
    sx: PropTypes.object,
    theme: PropTypes.any,
    transition: PropTypes.any,
};

export default EChart;
