import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    Button,
    Link,
    List,
    ListItem,
    ListItemIcon,
    Typography,
} from '@mui/material';
import React, { Fragment, useCallback } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { TODOS } from '/b2b/dashboard/constants';
import UncheckedCircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ProductTodos.styles';

const useStyles = makeStyles(styles);

export const ProductTodos = props => {
    const classes = useStyles(props);

    const { preferences = {}, title, todos = {}, updateTodoPreferences, dataTestId } = props;

    const taskCount = Object.values(todos).reduce((acc, count) => (count > 0 ? acc + 1 : acc), 0);

    const taskWithWarning = Object.entries(todos).some(
        ([todo, count]) => TODOS[todo]?.warning && count > 0
    );

    const status =
        taskCount > 0 ? (
            <Box display="flex" alignItems="center">
                {taskWithWarning && <WarningIcon color="error" />}
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.needsReviewButton}
                    color="info"
                >
                    <Badge badgeContent={taskCount} color="info" />
                    <Locale
                        path="views.Dashboard.todos.status.review"
                        options={{ smart_count: taskCount }}
                    />
                </Button>
            </Box>
        ) : (
            <Locale path="views.Dashboard.todos.status.caughtUp" />
        );

    const todoProduct = Object.keys(todos).reduce((acc, todo) => acc || TODOS[todo]?.product, null);

    const toggleAccordion = useCallback(
        (event, expanded) => {
            todoProduct &&
                updateTodoPreferences({
                    [todoProduct]: {
                        expanded:
                            preferences?.todos[todoProduct] != null
                                ? !preferences?.todos[todoProduct]?.expanded
                                : expanded,
                    },
                });
        },
        [preferences, todoProduct]
    );

    return (
        <Accordion
            className={classes.accordion}
            disableGutters
            elevation={0}
            expanded={
                preferences?.todos[todoProduct]?.expanded != null
                    ? preferences.todos[todoProduct].expanded
                    : taskCount > 0
            }
            onChange={toggleAccordion}
            data-testid={dataTestId}
        >
            <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ChevronRightIcon />}
            >
                <Box className={classes.accordionSummaryContent}>
                    <Typography variant="h5" whiteSpace="pre-line">
                        <Locale path={title} />
                    </Typography>
                    <Typography variant="caption" className={classes.status}>
                        {status}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <List disablePadding>
                    {Object.entries(todos)
                        .sort(([todoA], [todoB]) => {
                            const orderA = TODOS[todoA]?.order || 0;
                            const orderB = TODOS[todoB]?.order || 0;
                            return orderA - orderB;
                        })
                        .map(([todo, todoCount]) => (
                            <ListItem key={todo} className={classes.todoItem}>
                                <ListItemIcon className={classes.todoIconContainer}>
                                    {todoCount > 0 ? (
                                        TODOS[todo]?.warning ? (
                                            <WarningIcon className={classes.todoIconWarning} />
                                        ) : (
                                            <UncheckedCircleIcon
                                                className={classes.todoIconIncomplete}
                                            />
                                        )
                                    ) : (
                                        <CheckCircleIcon className={classes.todoIconComplete} />
                                    )}
                                </ListItemIcon>
                                <Typography className={todoCount === 0 ? classes.completeTodo : ''}>
                                    <Fragment>
                                        {todoCount > 0 ? (
                                            <Link
                                                className={classes.todoLink}
                                                component={RouterLink}
                                                color="inherit"
                                                to={TODOS[todo]?.urlHref}
                                            >
                                                <Locale
                                                    path={TODOS[todo]?.urlPath}
                                                    options={{
                                                        count: todoCount,
                                                        smart_count: todoCount,
                                                    }}
                                                />
                                            </Link>
                                        ) : (
                                            <Locale
                                                path={TODOS[todo]?.urlPath}
                                                options={{
                                                    count: todoCount,
                                                    smart_count: todoCount,
                                                }}
                                            />
                                        )}{' '}
                                        <Locale
                                            path={TODOS[todo]?.path}
                                            options={{
                                                days: TODOS[todo]?.days,
                                                smart_count: todoCount,
                                            }}
                                        />
                                    </Fragment>
                                </Typography>
                            </ListItem>
                        ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

ProductTodos.propTypes = {
    preferences: PropTypes.object,
    title: PropTypes.string.isRequired,
    todos: PropTypes.object.isRequired,
    updateTodoPreferences: PropTypes.func.isRequired,
    dataTestId: PropTypes.string,
};

export default ProductTodos;
