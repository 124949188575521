export const COMPLETE = 'COMPLETE';
export const COMPLETED = 'COMPLETED';
export const CLAIM = 'CLAIM';
export const UNCLAIM = 'UNCLAIM';
export const CLAIMED = 'CLAIMED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const PENDING_DELETION = 'PENDING_DELETION';
export const REJECTED = 'REJECTED';
export const SEARCH_IN_PROGRESS = 'USER_DATA_SEARCH_IN_PROGRESS';
export const SEARCH_COMPLETED = 'USER_DATA_SEARCH_COMPLETED';

export const RESOLVED_ACTION_ITEM_STATUSES = [COMPLETE, COMPLETED, REJECTED];

export const ACTION_ITEM_ACTIONS = {
    COMPLETE,
    CLAIM,
    UNCLAIM,
};

export const ACTION_ITEM_STATUSES = {
    COMPLETED,
    CLAIMED,
    IN_PROGRESS,
};

export const COMPLETION_TYPE_AUTOMATION = 'AUTOMATION';
export const COMPLETION_TYPE_AUTOMATION_NO_RESULTS = 'AUTOMATION_NO_RESULTS';
export const COMPLETION_TYPE_MANUAL = 'MANUAL';
export const COMPLETION_TYPE_API_AUTOMATION = 'API_AUTOMATION';
export const COMPLETION_TYPE_NO_RESULTS = 'NO_RESULTS';

export const AUTOMATION_COMPLETION_TYPES = [
    COMPLETION_TYPE_AUTOMATION,
    COMPLETION_TYPE_AUTOMATION_NO_RESULTS,
];

export const MANUAL_COMPLETION_TYPES = [COMPLETION_TYPE_MANUAL, COMPLETION_TYPE_NO_RESULTS];

export const PROCESSING_TYPE_AUTOMATION = 'AUTOMATION';
export const PROCESSING_TYPE_MANUAL = 'MANUAL';

export const DSAR_SUMMARY_WRITE_STATUSES = {
    NONE: 'NONE',
    WRITE_ERROR: 'WRITE_ERROR',
    WRITTEN: 'WRITTEN',
};

export const DSAR_ACTION_ITEM_STATUS_KEYS = {
    UNASSIGNED: 'UNASSIGNED',
    ASSIGNED: 'ASSIGNED',
    IN_PROGRESS: 'IN_PROGRESS',
    CLAIMED: 'CLAIMED',
    COMPLETED: 'COMPLETED',
    PENDING_DELETION: 'PENDING_DELETION',
    REJECTED: 'REJECTED',
};

export const DSAR_ACTION_ITEM_STATUS = {
    [DSAR_ACTION_ITEM_STATUS_KEYS.ASSIGNED]: 'dsar.status.actionItem.assigned',
    [DSAR_ACTION_ITEM_STATUS_KEYS.CLAIMED]: 'dsar.status.actionItem.claimed',
    [DSAR_ACTION_ITEM_STATUS_KEYS.COMPLETED]: 'dsar.status.actionItem.completed',
    [DSAR_ACTION_ITEM_STATUS_KEYS.IN_PROGRESS]: 'dsar.status.actionItem.inProgress', // 'To Do' in the UI
    [DSAR_ACTION_ITEM_STATUS_KEYS.PENDING_DELETION]: 'dsar.status.actionItem.deletionInProgress',
    [DSAR_ACTION_ITEM_STATUS_KEYS.UNASSIGNED]: 'dsar.status.actionItem.unassigned',
    [DSAR_ACTION_ITEM_STATUS_KEYS.REJECTED]: 'dsar.status.actionItem.rejected',
};

export const DSAR_ACTION_ITEM_COMPLETED_STATUS_KEYS = {
    NO_APPLICABLE_PRIVACY_LAW: 'NO_APPLICABLE_PRIVACY_LAW',
    OVERDUE: 'OVERDUE',
    WITHIN_REGULATORY_DATE: 'WITHIN_REGULATORY_DATE',
    WITHIN_INTERNAL_DATE: 'WITHIN_INTERNAL_DATE',
    UNKNOWN: 'UNKNOWN',
};

// This is only used for formatting the action item status for CSV (formatActionItemStatus)
export const DSAR_ACTION_ITEM_COMPLETED_STATUS = {
    [DSAR_ACTION_ITEM_COMPLETED_STATUS_KEYS.NO_APPLICABLE_PRIVACY_LAW]:
        'dsar.status.noApplicablePrivacyLaw',
    [DSAR_ACTION_ITEM_COMPLETED_STATUS_KEYS.OVERDUE]: 'dsar.status.overdue',
    [DSAR_ACTION_ITEM_COMPLETED_STATUS_KEYS.WITHIN_INTERNAL_DATE]: 'dsar.status.withinInternalDate',
    [DSAR_ACTION_ITEM_COMPLETED_STATUS_KEYS.WITHIN_REGULATORY_DATE]:
        'dsar.status.withinRegulatoryDate',
    [DSAR_ACTION_ITEM_COMPLETED_STATUS_KEYS.UNKNOWN]: 'dsar.status.unknown',
};

export const DSAR_ACTION_TO_TAKE_KEYS = {
    DELETE: 'DELETE',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    PROCESS_MANUALLY: 'PROCESS_MANUALLY',
    SUMMARIZE: 'SUMMARIZE',
    SUMMARIZE_FIELD: 'SUMMARIZE_FIELD',
    SUMMARIZE_FIELD_NAMES: 'SUMMARIZE_FIELD_NAMES',
    UPDATE: 'UPDATE',
};

export const DSAR_ACTION_TO_TAKE_NAMES = {
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD]: 'dsar.requestActions.summarizeFieldNamesValues',
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD_NAMES]: 'dsar.requestActions.summarizeFieldNames',
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE]: 'dsar.requestActions.summarizeMixed',
    [DSAR_ACTION_TO_TAKE_KEYS.UPDATE]: 'dsar.requestActions.update',
    [DSAR_ACTION_TO_TAKE_KEYS.DELETE]: 'dsar.requestActions.delete',
    [DSAR_ACTION_TO_TAKE_KEYS.PROCESS_MANUALLY]: 'dsar.requestActions.processManually',
};

// Override the action to take translations with shorter versions for the column.
export const ACTION_TO_TAKE_COLUMN_MAPPINGS = {
    ...DSAR_ACTION_TO_TAKE_NAMES,
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD]:
        'dsar.requestActions.column.summarizeFieldNamesValues',
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD_NAMES]:
        'dsar.requestActions.column.summarizeFieldNames',
};

export const DSAR_ACTION_PRESENT = {
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD]: 'dsar.action.present.summarize',
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD_NAMES]: 'dsar.action.present.summarize',
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE]: 'dsar.action.present.summarize',
    [DSAR_ACTION_TO_TAKE_KEYS.DELETE]: 'dsar.action.present.delete',
    [DSAR_ACTION_TO_TAKE_KEYS.UPDATE]: 'dsar.action.present.update',
    [DSAR_ACTION_TO_TAKE_KEYS.PROCESS_MANUALLY]: 'dsar.action.present.manual',
};

export const DSAR_ACTION_PAST = {
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD]: 'dsar.action.past.summarize',
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD_NAMES]: 'dsar.action.past.summarize',
    [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE]: 'dsar.action.past.summarize',
    [DSAR_ACTION_TO_TAKE_KEYS.DELETE]: 'dsar.action.past.delete',
    [DSAR_ACTION_TO_TAKE_KEYS.UPDATE]: 'dsar.action.past.update',
    [DSAR_ACTION_TO_TAKE_KEYS.PROCESS_MANUALLY]: 'dsar.action.past.manual',
};

export const DSAR_REQUESTED_ACTION_DESCRIPTION = {
    SEARCH_STEP: {
        SEARCH_UPDATE: 'dsar.actionItemDetails.requestedActionDescription.searchUpdate',
        SEARCH_RECORDS: 'dsar.actionItemDetails.requestedActionDescription.searchRecords',
    },
    DETAILS_STEP: {
        [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD]:
            'dsar.actionItemDetails.requestedActionDescription.summarizeNameAndValue',
        [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD_NAMES]:
            'dsar.actionItemDetails.requestedActionDescription.summarizeNameOnly',
        // TODO: Figure out new "combination" request action for summarize and summarize name only
        // [DSAR_ACTION_TO_TAKE_KEYS.SUMMARIZE_FIELD_NAMES]:
        //     'dsar.actionItemDetails.requestedActionDescription.summarizeMix',
        [DSAR_ACTION_TO_TAKE_KEYS.PROCESS_MANUALLY]:
            'dsar.actionItemDetails.requestedActionDescription.manual',
        [DSAR_ACTION_TO_TAKE_KEYS.UPDATE]:
            'dsar.actionItemDetails.requestedActionDescription.update',
        [DSAR_ACTION_TO_TAKE_KEYS.DELETE]:
            'dsar.actionItemDetails.requestedActionDescription.delete',
    },
    COMPLETE_STEP: {
        COMPLETE: 'dsar.actionItemDetails.requestedActionDescription.markActionComplete',
    },
};

export const EVENTS = {
    ACTION_ITEMS_FILTER: 'ActionItemsFilter',
    ACTION_ITEMS_SEARCH: 'ActionItemsSearch',
};
