import Actions from '../state/Dashboard.actions';
import { fetchDashboard as fetchDashboardService } from '../services';

// TODO: Remove once ENABLE_DASHBOARD_V2 feature flag is removed
export const fetchDashboard =
    (params = {}) =>
    dispatch => {
        dispatch(Actions.fetchDetailsBegin(params));
        return fetchDashboardService(params)
            .then(response => dispatch(Actions.fetchDetailsSuccess(params, response)))
            .catch(error => dispatch(Actions.fetchDetailsFailure(params, error?.message)));
    };

export const updateTodoPreferences = preferences => dispatch =>
    dispatch(Actions.updateTodoPreferences(preferences));
