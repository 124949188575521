import {
    AccountBoxOutlined as AccountBoxOutlinedIcon,
    BusinessOutlined as OrganizationsIcon,
    Payment as PaymentIcon,
    ExitToAppOutlined as SignoutIcon,
    People as UsersIcon,
    Webhook as WebhookIcon,
} from '@mui/icons-material';
import {
    Divider,
    LinearProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@mui/material';

import API from '@aws-amplify/api';
import APIKeyIcon from '@mui/icons-material/VpnKey';
import AdminHelpers from './components/AdminHelpers';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import Locale from '/b2b/common/components/Locale';
import NavLinkListItem from '/b2b/common/components/NavLinkListItem';
import { PERMISSIONS } from '/b2b/authentication/constants';
import Permission from '/b2b/common/components/Permission';
import PropTypes from 'prop-types';
import React from 'react';
import { apiName } from '@osano-b2b';
import classNames from 'classnames';
import { devMode } from '/b2b/authentication/state/Auth.selectors';
import { handleError } from '/b2b/common/services/helpers';
import makeStyles from '@mui/styles/makeStyles';
import styles from './UserDetails.styles';

const useStyles = makeStyles(styles);

export const fetchBillingPortalUrl = async () => {
    return API.get(apiName, '/customer/billing-portal').catch(handleError);
};

const UserDetails = props => {
    const {
        adminHelpersEnabled,
        className,
        createSnack,
        hasPermissionToViewOrganizations,
        isBillingPortalEnabled,
        onSignOut,
        onOrganizationsClick,
        shouldRenderApiKeys,
    } = props;
    const classes = useStyles(props);
    const rootClassName = classNames(classes.root, className);

    const [fetchingBillingPortalUrl, setFetchingBillingPortalUrl] = React.useState('');

    const handleOrganizationsClick = e => {
        e.preventDefault();
        return onOrganizationsClick();
    };

    const handleBillingPortalClick = e => {
        e.preventDefault();

        setFetchingBillingPortalUrl(true);

        fetchBillingPortalUrl()
            .then(({ url = '' } = {}) => window.open(url, '_blank', 'noopener,noreferrer'))
            .catch(error => {
                createSnack(error?.message);
            })
            .finally(() => {
                setFetchingBillingPortalUrl(false);
            });
    };

    return (
        <div className={rootClassName}>
            {fetchingBillingPortalUrl && <LinearProgress />}
            <div className={classes.content}>
                <List
                    component="div"
                    subheader={
                        devMode || adminHelpersEnabled ? (
                            <ListSubheader className={classes.listSubheader}>
                                <AdminHelpers />
                            </ListSubheader>
                        ) : undefined
                    }
                >
                    <ListItemButton
                        activeClassName={classes.activeListItem}
                        className={classes.listItem}
                        component={NavLinkListItem}
                        to={AuthenticatedPaths.ACCOUNT}
                        isActive={(match, location) =>
                            match
                                ? true
                                : location.pathname.indexOf(AuthenticatedPaths.ACCOUNT) === 0
                        }
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <AccountBoxOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={<Locale path={'common.account'} />}
                        />
                    </ListItemButton>
                    <Permission forRole toPerform="view" on={AuthenticatedPaths.USERS}>
                        <ListItemButton
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.USERS}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(`${AuthenticatedPaths.USERS}/`) ===
                                      0
                            }
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <UsersIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path={'common.users'} />}
                            />
                        </ListItemButton>
                    </Permission>
                    <Permission forRole toPerform="view" on={AuthenticatedPaths.ORGANIZATIONS}>
                        <ListItemButton
                            className={
                                hasPermissionToViewOrganizations
                                    ? classes.listItem
                                    : classes.disabled
                            }
                            onClick={e =>
                                hasPermissionToViewOrganizations
                                    ? onOrganizationsClick
                                    : handleOrganizationsClick(e)
                            }
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.ORGANIZATIONS}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <OrganizationsIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path="common.fields.organizations.label" />}
                            />
                        </ListItemButton>
                    </Permission>
                    <Permission
                        toPerform={{
                            [AuthenticatedPaths.API_KEYS]: { view: true },
                            [PERMISSIONS.DATA_MAPPING]: { read: true },
                        }}
                        shouldRender={shouldRenderApiKeys}
                    >
                        <ListItemButton
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.API_KEYS}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(AuthenticatedPaths.API_KEYS) === 0
                            }
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <APIKeyIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path="common.apiKeys" />}
                            />
                        </ListItemButton>
                    </Permission>

                    <Permission
                        toPerform={{
                            [AuthenticatedPaths.WEBHOOKS]: { view: true },
                            [PERMISSIONS.ADMIN]: { read: true },
                        }}
                    >
                        <ListItemButton
                            activeClassName={classes.activeListItem}
                            className={classes.listItem}
                            component={NavLinkListItem}
                            to={AuthenticatedPaths.WEBHOOKS}
                            isActive={(match, location) =>
                                match
                                    ? true
                                    : location.pathname.indexOf(AuthenticatedPaths.WEBHOOKS) === 0
                            }
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <WebhookIcon />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.listItemText }}
                                primary={<Locale path="common.webhooks" />}
                            />
                        </ListItemButton>
                    </Permission>
                    {isBillingPortalEnabled && (
                        <Permission
                            toPerform={{
                                [PERMISSIONS.ADMIN]: { read: true },
                            }}
                        >
                            <ListItemButton
                                className={classes.listItem}
                                component="a"
                                onClick={handleBillingPortalClick}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PaymentIcon />
                                </ListItemIcon>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={<Locale path="common.billing" />}
                                />
                            </ListItemButton>
                        </Permission>
                    )}
                </List>
                <Divider className={classes.listDivider} />
                <List component="div">
                    <ListItemButton className={classes.listItem} onClick={onSignOut}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <SignoutIcon />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={<Locale path="common.signOut" />}
                        />
                    </ListItemButton>
                </List>
            </div>
        </div>
    );
};

UserDetails.propTypes = {
    adminHelpersEnabled: PropTypes.bool,
    className: PropTypes.string,
    createSnack: PropTypes.func,
    hasPermissionToViewOrganizations: PropTypes.bool,
    isBillingPortalEnabled: PropTypes.bool,
    onSignOut: PropTypes.func,
    onOrganizationsClick: PropTypes.func,
    shouldRenderApiKeys: PropTypes.bool,
};

UserDetails.defaultProps = {
    onSignOut: () => {},
};

export default UserDetails;
