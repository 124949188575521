const { EChart } = require('/b2b/common/components/Charts');

import {
    BAR_CHART_TOOLTIP,
    COMMON_OPTIONS,
    VERTICAL_YAXIS_OPTIONS,
} from '/b2b/dsarReporting/constants';
import { DSAR_STATUS, DSAR_STATUS_KEYS } from '/b2b/dsarRequests/constants';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { makeCSV, makeRequestFilters, makeRequestFiltersV2 } from '../helpers';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { ENABLE_DSAR_MULTI_SORTING } from '/b2b/common/components/FeatureFlag';
import PropTypes from 'prop-types';
import ReportingContext from '../Reporting.context';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchDSARReportingData } from '/b2b/dsarReporting/services';
import { isFeatureEnabled } from '/b2b/common/helpers/FeatureFlags';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'requestTimeToCompletion';

const FIELDS = {
    completedBetween0And10Days: 'completedBetween0And10Days',
    completedBetween11And20Days: 'completedBetween11And20Days',
    completedBetween21And30Days: 'completedBetween21And30Days',
    completedBetween31And40Days: 'completedBetween31And40Days',
    completedBetween41And50Days: 'completedBetween41And50Days',
    completedBetween51And60Days: 'completedBetween51And60Days',
    completedBetween61PlusDays: 'completedBetween61PlusDays',
};

const LABELS = {
    [FIELDS.completedBetween0And10Days]: '0-10',
    [FIELDS.completedBetween11And20Days]: '11-20',
    [FIELDS.completedBetween21And30Days]: '21-30',
    [FIELDS.completedBetween31And40Days]: '31-40',
    [FIELDS.completedBetween41And50Days]: '41-50',
    [FIELDS.completedBetween51And60Days]: '51-60',
    [FIELDS.completedBetween61PlusDays]: '60+',
};

const staticOptions = {
    ...COMMON_OPTIONS,
    ...VERTICAL_YAXIS_OPTIONS,
    legend: {
        ...COMMON_OPTIONS.legend,
        show: false,
    },
    xAxis: {
        ...COMMON_OPTIONS.xAxis,
        data: Object.values(LABELS),
        type: 'category',
    },
    yAxis: {
        ...COMMON_OPTIONS.yAxis,
        ...VERTICAL_YAXIS_OPTIONS.yAxis,
    },
    tooltip: BAR_CHART_TOOLTIP,
};

const RequestTimeToCompletion = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data = {}, error, load, loading } = useContext(ReportingContext);
    const reportData = data[REPORT_NAME] || [];
    const noResults = reportData.length === 0;
    const isLoading = loading || providedLoading;

    const theme = useTheme();

    const memoOption = useMemo(() => {
        const summedRequests = reportData.reduce((acc, curr) => {
            Object.keys(curr).forEach(key => {
                if (key !== 'date') {
                    acc[key] = (acc[key] || 0) + curr[key];
                }
            });
            return acc;
        }, {});

        return merge({}, staticOptions, {
            grid: {
                left: parseInt(theme.spacing(6.5), 10),
                right: parseInt(theme.spacing(4.5), 10),
            },
            yAxis: {
                name: translate('dsar.reporting.common.numberOfRequests'),
                nameGap: parseInt(theme.spacing(5.5), 10),
                show: reportData.length > 0,
            },
            series: [
                {
                    data: reportData.length ? Object.values(summedRequests) : [],
                    name: translate('common.requests'),
                    type: 'bar',
                },
            ],
        });
    }, [reportData, shouldRetranslate]);

    const fetchData = useBoundCallback(
        filters => fetchDSARReportingData({ ...filters, reports: [REPORT_NAME] }),
        [filters]
    );

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData, filters]);

    const echartsInstance = useRef(null);
    const helpers = useMemo(
        () => ({
            makeCSV: () => {
                const summedRequests = Object.entries(LABELS).reduce((acc, [key, label]) => {
                    acc[label] = reportData.reduce((sum, curr) => sum + (curr[key] || 0), 0);
                    return acc;
                }, {});

                const formattedReportData = Object.entries(summedRequests).map(
                    ([days, requests]) => ({
                        days,
                        requests,
                    })
                );

                return makeCSV(
                    formattedReportData,
                    {
                        days: 'common.time.day',
                        requests: 'common.requests',
                    },
                    false
                );
            },
            name: 'request-time-to-completion-days',
        }),
        [reportData]
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    const enableMutliFilterDrilldown = useSelector(state =>
        isFeatureEnabled(state, ENABLE_DSAR_MULTI_SORTING)
    );

    const onDrilldownClick = useBoundCallback(
        drilldownFilters => {
            const makeFilters = enableMutliFilterDrilldown
                ? makeRequestFiltersV2
                : makeRequestFilters;
            window.open(
                `${AuthenticatedPaths.DSAR_REQUESTS}?${makeFilters(drilldownFilters)}`,
                '_blank'
            );
        },
        [
            [
                {
                    ...filters,
                    requestStatus: Object.keys(DSAR_STATUS)
                        .filter(
                            ds =>
                                ds === DSAR_STATUS_KEYS.COMPLETED ||
                                ds === DSAR_STATUS_KEYS.REJECTED
                        )
                        .join(','),
                },
                providedData,
            ],
            enableMutliFilterDrilldown,
        ]
    );
    const downloadFormats = [
        'csv',
        'png',
        { label: 'dsar.reporting.charts.requests.list', onClick: onDrilldownClick },
    ];

    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={downloadFormats}
            echartsInstance={echartsInstance}
            error={error}
            title="dsar.reporting.charts.requestTimeToCompletion.title"
            tooltip="dsar.reporting.charts.requestTimeToCompletion.tooltip"
        >
            <EChart
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </WidgetContainer>
    );
};

RequestTimeToCompletion.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

export default withAsyncData(withRetranslate(RequestTimeToCompletion), ReportingContext);
