import { formatDSARReportingData, formatDSARReportingParams } from './format';
import { formatPagingParams, handleError } from '../common/services/helpers';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';

export const fetchDSARReportingData = queryStringParameters => {
    const options = {
        queryStringParameters: {
            ...formatPagingParams(queryStringParameters),
            ...formatDSARReportingParams(queryStringParameters),
        },
    };
    return API.get(apiName, `/dsar/reporting`, options)
        .catch(handleError)
        .then(formatDSARReportingData);
};
