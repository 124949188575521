import {
    buildActionCreators,
    makeActionTypes,
    makeAsyncEvents,
    namedParameters,
    noParameters,
    singleParameter,
} from '/b2b/common/helpers/ReduxHelpers';

import { DOMAIN } from './ActionItemList.constants';

export const events = {
    reset: noParameters,
    setColumnVisibility: singleParameter,
    setColumnOrder: namedParameters('columnOrder', 'orderedColumns'),
};

export const asyncEvents = makeAsyncEvents(['update'], true);

export const types = makeActionTypes([...Object.keys(events), ...Object.keys(asyncEvents)], DOMAIN);

export default buildActionCreators(
    {
        ...events,
        ...asyncEvents,
    },
    types
);
