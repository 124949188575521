import moment from 'moment';
import { parseDate } from '../../helpers/Date';

const dayInMs = 86400000;
const monthInMs = 2629800000;

export const timeLabelFormats = {
    year: '{yyyy}',
    month: '{MMM}, {yyyy}',
    week: '{MMM}, {DD}',
    day: '{MMM}, {DD}',
    hour: '{hh}:{mm}',
    minute: '{hh}:{mm}',
    second: '{hh}:{mm}:{ss}',
    millisecond: '{hh}:{mm}:{ss} {SSS}',
    none: '{yyyy}-{MM}-{DD} {hh}:{mm}:{ss} {SSS}',
};

const momentFormats = Object.fromEntries(
    Object.entries(timeLabelFormats).map(([key, value]) => [
        key,
        value.replace(/{/g, '').replace(/}/g, ''),
    ])
);

export const getTimeFormat = (minDate, maxDate) => {
    const startValue = moment(parseDate(minDate));
    const endValue = moment(parseDate(maxDate));
    const timeSpan = endValue.diff(startValue);

    // If the date range is less than 30 days, group by day
    const groupByDayThreshold = dayInMs * 30;
    // If the date range is less than 90 days, group by week
    const groupByWeekThreshold = dayInMs * 90;
    // If the date range is less than 18 months, group by month
    const groupByMonthThreshold = monthInMs * 18;
    // Anything greater than 18 months, group by year

    let format = 'day';

    if (timeSpan > groupByMonthThreshold) {
        format = 'year';
    } else if (timeSpan > groupByWeekThreshold) {
        format = 'month';
    } else if (timeSpan > groupByDayThreshold) {
        format = 'week';
    }

    return format;
};

export function axisNameForTimeFormat(format) {
    switch (format) {
        case 'year':
            return 'common.year';
        case 'month':
            return 'common.month';
        case 'week':
            return 'common.weekOf';
        default:
            return '';
    }
}

export const maxTimeInterval = (minDate, maxDate) => {
    if (!minDate || !maxDate) {
        return;
    }

    const format = getTimeFormat(minDate, maxDate);

    switch (format) {
        case 'year':
            return dayInMs * 365;
        case 'month':
            return monthInMs;
        case 'week':
            return dayInMs * 7;
        case 'day':
            return dayInMs;
        default:
            return;
    }
};

export const timeLabelFormatter = (minDate, maxDate, validDates) => {
    if (!minDate || !maxDate) {
        return () => '';
    }

    const startValue = moment(parseDate(minDate));
    const endValue = moment(parseDate(maxDate));

    const format = getTimeFormat(minDate, maxDate);

    let datesToCheck = validDates
        ? validDates.map(date => {
              switch (format) {
                  case 'year':
                  case 'month':
                  case 'week':
                  case 'day':
                      return `${new Date(date).toISOString().split('T')[0]}T00:00:00.000Z`;
                  case 'hour':
                      return `${new Date(date).toISOString().split('T')[0]}T${new Date(date).getHours()}:00:00.000Z`;
                  case 'minute':
                      return `${new Date(date).toISOString().split('T')[0]}T${new Date(date).getHours()}:${new Date(date).getMinutes()}:00.000Z`;
                  case 'second':
                      return `${new Date(date).toISOString().split('T')[0]}T${new Date(date).getHours()}:${new Date(date).getMinutes()}:${new Date(date).getSeconds()}.000Z`;
                  default:
                      return date;
              }
          })
        : [];

    return function hideOverflowLabels(value) {
        /**
         * The `value` here is in milliseconds, but it's in the current timezone when
         * the date is represented as a date string without a timezone. It's nice that ECharts
         * is trying to accommodate for our local timezone, but we need to convert it back to UTC.
         */
        const timezoneOffsetMilliseconds = new Date(value).getTimezoneOffset() * 60 * 1000;
        const utcValue = value - timezoneOffsetMilliseconds;
        const date = new Date(value);
        const utcDate = moment.utc(utcValue);
        if (validDates && !datesToCheck.includes(utcDate.toISOString())) {
            return '';
        }

        if (
            value !== Math.round(value) ||
            !startValue.isSameOrBefore(date) ||
            !endValue.isSameOrAfter(date)
        ) {
            return '';
        }

        switch (format) {
            case 'year':
                if (utcDate.month() !== 1) {
                    return '';
                }
                break;
            case 'month':
                if (utcDate.date() !== 1) {
                    return '';
                }
                break;
            case 'week':
                if (utcDate.hour() !== 0) {
                    return '';
                }
                break;
            case 'day':
                if (utcDate.hour() !== 0) {
                    return '';
                }
                break;
            case 'hour':
                if (utcDate.minute() !== 0) {
                    return '';
                }
                break;
            case 'minute':
                if (utcDate.second() !== 0) {
                    return '';
                }
                break;
            case 'second':
                if (utcDate.millisecond() !== 0) {
                    return '';
                }
                break;
            default:
                break;
        }
        return utcDate.format(momentFormats[`${format}`]);
    };
};
