import AllRequests, {
    REPORT_NAME as ALL_REQUESTS,
} from '/b2b/dsarReporting/routes/Reporting/charts/AllRequests';
import AssessmentsRisks, {
    REPORT_NAME as ASSESSMENTS_RISKS,
} from '/b2b/assessments/routes/AssessmentsReporting/charts/AssessmentsRisks';
import AssignmentsByStatus, {
    REPORT_NAME as ASSIGNMENTS_BY_STATUS,
} from '/b2b/assessments/routes/AssessmentsReporting/charts/AssignmentsByStatus';
import Categories, {
    REPORT_NAME as CATEGORIES,
} from '/b2b/consent/routes/ConsentDashboard/charts/Categories';
import ChangedConsents, {
    REPORT_NAME as CHANGED_CONSENTS,
} from '/b2b/uc/routes/Dashboard/charts/ChangedConsents';
import CompletedActionItems, {
    REPORT_NAME as COMPLETED_ACTION_ITEMS,
} from '/b2b/dsarReporting/routes/Reporting/charts/CompletedActionItems';
import ConsentsByPlatform, {
    REPORT_NAME as CONSENTS_BY_PLATFORM,
} from '/b2b/consent/routes/ConsentDashboard/charts/ConsentsByPlatform';
import ConsentsOverTime, {
    REPORT_NAME as CONSENTS_OVER_TIME,
} from '/b2b/consent/routes/ConsentDashboard/charts/ConsentsOverTime';
import CustomerLifetime, {
    REPORT_NAME as CUSTOMER_LIFETIME,
} from '/b2b/uc/routes/Dashboard/charts/CustomerLifetime';
import DnsOptInOut, {
    REPORT_NAME as DNS_OPT_IN_OUT,
} from '/b2b/consent/routes/ConsentDashboard/charts/DnsOptInOut';
import GpcConsents, {
    REPORT_NAME as GPC_CONSENTS,
} from '/b2b/uc/routes/Dashboard/charts/GpcConsents';
import PrivacyScoresVendorsAssessed, {
    REPORT_NAME as PRIVACY_SCORES_VENDORS_ASSESSED,
} from '/b2b/assessments/routes/AssessmentsReporting/charts/PrivacyScoresVendorsAssessed';
import RequestTimeToCompletion, {
    REPORT_NAME as REQUEST_TIME_TO_COMPLETION,
} from '/b2b/dsarReporting/routes/Reporting/charts/RequestTimeToCompletion';
import RequestsByJurisdiction, {
    REPORT_NAME as REQUESTS_BY_JURISDICTION,
} from '/b2b/dsarReporting/routes/Reporting/charts/RequestsByJurisdiction';
import UnifiedConsentsByUser, {
    REPORT_NAME as UNIFIED_CONSENTS_BY_USER,
} from '/b2b/uc/routes/Dashboard/charts/ConsentsByUser';
import VerifiedRequests, {
    REPORT_NAME as VERIFIED_REQUESTS,
} from '/b2b/dsarReporting/routes/Reporting/charts/VerifiedRequests';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import CookieConsentsByJurisdiction from '/b2b/consent/routes/ConsentDashboard/charts/ConsentsByJurisdiction';
import { NAV_LABELS } from '/b2b/common/layouts/Dashboard/components/Sidebar/constants';
import { PERMISSIONS } from '/b2b/authentication/constants';
import UnifiedConsentsByJurisdiction from '/b2b/uc/routes/Dashboard/charts/ConsentsByJurisdiction';
import moment from 'moment';

const COOKIE_CONSENTS_BY_JURISDICTION = 'cookieConsentsByJurisdiction';
const UNIFIED_CONSENTS_BY_JURISDICTION = 'unifiedConsentsByJurisdiction';

const basePath = 'views.Dashboard.todos.labels';

const ASSESSMENT_PRODUCT = 'assessments';
const COOKIE_CONSENT_PRODUCT = 'cookieConsent';
const SUBJECT_RIGHTS_PRODUCT = 'subjectRights';
const DATA_MAPPING_PRODUCT = 'dataMapping';
const UNIFIED_CONSENT_PRODUCT = 'unifiedConsent';
const VENDOR_MANAGEMENT_PRODUCT = 'vendorMonitoring';

export const PRODUCTS = {
    ASSESSMENT_PRODUCT,
    COOKIE_CONSENT_PRODUCT,
    DATA_MAPPING_PRODUCT,
    SUBJECT_RIGHTS_PRODUCT,
    UNIFIED_CONSENT_PRODUCT,
    VENDOR_MANAGEMENT_PRODUCT,
};

export const PRODUCT_LABELS = {
    [ASSESSMENT_PRODUCT]: NAV_LABELS.assessments,
    [COOKIE_CONSENT_PRODUCT]: NAV_LABELS.consentManager,
    [DATA_MAPPING_PRODUCT]: NAV_LABELS.dataMapping,
    [SUBJECT_RIGHTS_PRODUCT]: NAV_LABELS.dsar,
    [UNIFIED_CONSENT_PRODUCT]: NAV_LABELS.unifiedConsent,
    [VENDOR_MANAGEMENT_PRODUCT]: NAV_LABELS.vendor,
};

export const PRODUCT_ORDER = [
    COOKIE_CONSENT_PRODUCT,
    UNIFIED_CONSENT_PRODUCT,
    SUBJECT_RIGHTS_PRODUCT,
    DATA_MAPPING_PRODUCT,
    VENDOR_MANAGEMENT_PRODUCT,
    ASSESSMENT_PRODUCT,
];

export const TODOS = {
    // assessments
    dueSoonAssessments: {
        urlHref: `${AuthenticatedPaths.ASSESSMENTS}?reviewStatus=dueSoon`,
        urlPath: `${basePath}.nAssignments`,
        path: `${basePath}.dueWithinNDays`,
        product: ASSESSMENT_PRODUCT,
        days: 14,
        order: 1,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.ASSESSMENT,
        },
    },
    overdueAssessments: {
        urlHref: `${AuthenticatedPaths.ASSESSMENTS}?reviewStatus=overdue`,
        urlPath: `${basePath}.nAssignments`,
        path: `${basePath}.overdue`,
        product: ASSESSMENT_PRODUCT,
        order: 0,
        warning: true,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.ASSESSMENT,
        },
    },
    unpublishedAssessmentTemplates: {
        urlHref: `${AuthenticatedPaths.ASSESSMENT_TEMPLATES}?publishStatus=unpublished`,
        urlPath: `${basePath}.nTemplates`,
        path: `${basePath}.unpublished`,
        product: ASSESSMENT_PRODUCT,
        order: 2,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENT_TEMPLATES,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.ASSESSMENT,
        },
    },
    // cookieConsent
    consentConfigUnclassified: {
        urlHref: `${AuthenticatedPaths.CONSENT_MANAGER}?tattling=true`,
        urlPath: `${basePath}.nConfigurations`,
        path: `${basePath}.unclassified`,
        product: COOKIE_CONSENT_PRODUCT,
        order: 0,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_MANAGER,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.CONSENTS,
        },
    },
    unpublishedConsentConfigs: {
        urlHref: `${AuthenticatedPaths.CONSENT_MANAGER}?publishStatus=unpublished`,
        urlPath: `${basePath}.nConfigurations`,
        path: `${basePath}.unpublished`,
        product: COOKIE_CONSENT_PRODUCT,
        order: 1,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_MANAGER,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.CONSENTS,
        },
    },
    // dataMapping
    discoErrors: {
        urlHref: `${AuthenticatedPaths.DATA_STORES}?status=ERROR`,
        urlPath: `${basePath}.nDataStores`,
        path: `${basePath}.errors`,
        product: DATA_MAPPING_PRODUCT,
        order: 0,
        warning: true,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DATA_STORES,
        },
        rolePermission: {
            toPerform: 'write',
            onAny: [PERMISSIONS.DATA_MAPPING, PERMISSIONS.DSAR_REQUEST],
        },
    },
    sourceErrors: {
        urlHref: `${AuthenticatedPaths.DATA_MAPPING_SOURCES}?status=ERROR`,
        urlPath: `${basePath}.nSources`,
        path: `${basePath}.errors`,
        product: DATA_MAPPING_PRODUCT,
        order: 1,
        warning: true,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DATA_MAPPING_SOURCES,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DATA_MAPPING,
        },
    },
    unassignedApps: {
        urlHref: `${AuthenticatedPaths.DATA_MAPPING_APPLICATIONS}?status=NEW`,
        urlPath: `${basePath}.nDiscoveries`,
        path: `${basePath}.unassigned`,
        product: DATA_MAPPING_PRODUCT,
        order: 2,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DATA_MAPPING_APPLICATIONS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DATA_MAPPING,
        },
    },
    // subjectRights
    actionItemsOverdueRegulatory: {
        urlHref: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        urlPath: `${basePath}.nActionItems`,
        path: `${basePath}.overdue`,
        product: SUBJECT_RIGHTS_PRODUCT,
        order: 4,
        warning: true,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_DATASOURCE,
        },
    },
    actionItemsOverdueSoon: {
        urlHref: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        urlPath: `${basePath}.nActionItems`,
        path: `${basePath}.dueWithinNDays`,
        product: SUBJECT_RIGHTS_PRODUCT,
        days: 7,
        order: 5,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_ACTION_ITEMS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_DATASOURCE,
        },
    },
    pendingIdVerification: {
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?requestStatus=is%3APENDING_IDENTITY_VERIFICATION`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.awaitingIdVerification`,
        product: SUBJECT_RIGHTS_PRODUCT,
        order: 3,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    requestOverdueSoon: {
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?spotlightMetric=is%3AdueWithin7DaysRegulatory`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.dueWithinNDays`,
        product: SUBJECT_RIGHTS_PRODUCT,
        days: 7,
        order: 1,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    requestsOverdueInternal: {
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?spotlightMetric=is%3AcurrentlyOverdueInternal`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.overdueInternal`,
        product: SUBJECT_RIGHTS_PRODUCT,
        order: 2,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    requestsOverdueRegulatory: {
        urlHref: `${AuthenticatedPaths.DSAR_REQUESTS}?spotlightMetric=is%3AcurrentlyOverdueRegulatory`,
        urlPath: `${basePath}.nRequests`,
        path: `${basePath}.overdueRegulatory`,
        product: SUBJECT_RIGHTS_PRODUCT,
        order: 0,
        warning: true,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REQUESTS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    // unifiedConsent
    unpublishedConfigCount: {
        urlHref: `${AuthenticatedPaths.UC_CONFIGS}?publishStatus=unpublished`,
        urlPath: `${basePath}.nConfigurations`,
        path: `${basePath}.unpublished`,
        product: UNIFIED_CONSENT_PRODUCT,
        order: 0,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.UC_CONFIGS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.UNIFIED_CONSENT,
        },
    },
    // vendorMonitoring
    vendorReviewsDueSoon: {
        urlHref: `${AuthenticatedPaths.VENDORS}?reviewStatus=reviewDueSoon`,
        urlPath: `${basePath}.nVendorReviews`,
        path: `${basePath}.dueWithinNDays`,
        product: VENDOR_MANAGEMENT_PRODUCT,
        days: 14,
        order: 1,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.VENDORS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.MONITORING,
        },
    },
    vendorReviewsOverdue: {
        urlHref: `${AuthenticatedPaths.VENDORS}?reviewStatus=reviewOverdue`,
        urlPath: `${basePath}.nVendorReviews`,
        path: `${basePath}.overdue`,
        product: VENDOR_MANAGEMENT_PRODUCT,
        order: 0,
        warning: true,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.VENDORS,
        },
        rolePermission: {
            toPerform: 'write',
            on: PERMISSIONS.MONITORING,
        },
    },
};

// all dashboard charts should have same start and end date
const startDate = moment().utc().subtract(30, 'days').startOf('day').valueOf();
const endDate = moment().utc().endOf('day').valueOf();

export const REPORTS = {
    // assessments
    [ASSESSMENTS_RISKS]: {
        Component: AssessmentsRisks,
        default: true,
        filters: {
            dateRange: [startDate, endDate],
        },
        product: ASSESSMENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENTS_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.ASSESSMENT,
        },
    },
    [ASSIGNMENTS_BY_STATUS]: {
        Component: AssignmentsByStatus,
        filters: {
            dateRange: [startDate, endDate],
        },
        product: ASSESSMENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENTS_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.ASSESSMENT,
        },
    },
    [PRIVACY_SCORES_VENDORS_ASSESSED]: {
        Component: PrivacyScoresVendorsAssessed,
        filters: {
            dateRange: [startDate, endDate],
        },
        product: ASSESSMENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.ASSESSMENTS_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.ASSESSMENT,
        },
    },
    // cookieConsent
    [CATEGORIES]: {
        Component: Categories,
        filters: {
            startDate,
            endDate,
        },
        product: COOKIE_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.CONSENTS,
        },
    },
    [COOKIE_CONSENTS_BY_JURISDICTION]: {
        Component: CookieConsentsByJurisdiction,
        filters: {
            startDate,
            endDate,
        },
        product: COOKIE_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.CONSENTS,
        },
    },
    [CONSENTS_BY_PLATFORM]: {
        Component: ConsentsByPlatform,
        filters: {
            startDate,
            endDate,
        },
        product: COOKIE_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.CONSENTS,
        },
    },
    [CONSENTS_OVER_TIME]: {
        Component: ConsentsOverTime,
        default: true,
        filters: {
            startDate,
            endDate,
        },
        product: COOKIE_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.CONSENTS,
        },
    },
    [DNS_OPT_IN_OUT]: {
        Component: DnsOptInOut,
        filters: {
            startDate,
            endDate,
        },
        product: COOKIE_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.CONSENT_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.CONSENTS,
        },
    },
    // subjectRights
    [ALL_REQUESTS]: {
        Component: AllRequests,
        filters: {
            startDate,
            endDate,
        },
        product: SUBJECT_RIGHTS_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    [COMPLETED_ACTION_ITEMS]: {
        Component: CompletedActionItems,
        filters: {
            startDate,
            endDate,
        },
        product: SUBJECT_RIGHTS_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    [REQUESTS_BY_JURISDICTION]: {
        Component: RequestsByJurisdiction,
        filters: {
            startDate,
            endDate,
        },
        product: SUBJECT_RIGHTS_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    [REQUEST_TIME_TO_COMPLETION]: {
        Component: RequestTimeToCompletion,
        filters: {
            startDate,
            endDate,
        },
        product: SUBJECT_RIGHTS_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    [VERIFIED_REQUESTS]: {
        Component: VerifiedRequests,
        default: true,
        filters: {
            startDate,
            endDate,
        },
        product: SUBJECT_RIGHTS_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.DSAR_REPORTING,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.DSAR_REQUEST,
        },
    },
    // unifiedConsent
    [CHANGED_CONSENTS]: {
        Component: ChangedConsents,
        filters: {
            startDate,
            endDate,
        },
        product: UNIFIED_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.UC_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.UNIFIED_CONSENT,
        },
    },
    [UNIFIED_CONSENTS_BY_JURISDICTION]: {
        Component: UnifiedConsentsByJurisdiction,
        filters: {
            startDate,
            endDate,
        },
        product: UNIFIED_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.UC_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.UNIFIED_CONSENT,
        },
    },
    [UNIFIED_CONSENTS_BY_USER]: {
        Component: UnifiedConsentsByUser,
        filters: {
            startDate,
            endDate,
        },
        product: UNIFIED_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.UC_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.UNIFIED_CONSENT,
        },
    },
    [CUSTOMER_LIFETIME]: {
        Component: CustomerLifetime,
        filters: {
            startDate,
            endDate,
        },
        product: UNIFIED_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.UC_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.UNIFIED_CONSENT,
        },
    },
    [GPC_CONSENTS]: {
        Component: GpcConsents,
        default: true,
        filters: {
            startDate,
            endDate,
        },
        product: UNIFIED_CONSENT_PRODUCT,
        customerPermission: {
            toPerform: 'view',
            on: AuthenticatedPaths.UC_DASHBOARD,
        },
        rolePermission: {
            toPerform: 'read',
            on: PERMISSIONS.UNIFIED_CONSENT,
        },
    },
};
