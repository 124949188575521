// @ts-check
const { EChart, LegendIcon } = require('/b2b/common/components/Charts');

import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';

import { CMP_CONSENT_CATEGORIES } from '/b2b/consent/constants';
import ConsentDashboardContext from '../ConsentDashboard.context';
import PropTypes from 'prop-types';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import castArray from 'lodash/castArray';
import { fetchConsentReportsDashboard } from '/b2b/consent/services';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'categories';

/** @type {import('echarts').EChartsOption} */
const staticOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'none',
        },
        appendTo: document.body,
    },
    legend: {
        icon: LegendIcon,
        orient: 'horizontal',
        top: 'top',
    },
    grid: {
        containLabel: false,
    },
    xAxis: {
        axisLabel: { show: false },
        axisLine: { onZero: false, show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        type: 'value',
    },
    yAxis: {
        type: 'category',
        axisLabel: { align: 'left', inside: true, overflow: 'break' },
        axisLine: { onZero: false, show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        boundaryGap: false,
    },
    series: [
        {
            name: 'common.optIn',
            type: 'bar',
            stack: 'total',
            label: {
                align: 'left',
                position: 'insideLeft',
                show: true,
            },
            emphasis: {
                focus: 'series',
            },
            dimensions: [
                { name: 'category', type: 'ordinal' },
                { name: `optIn`, type: 'int' },
            ],
        },
        {
            name: 'common.optOut',
            type: 'bar',
            stack: 'total',
            label: {
                align: 'right',
                position: 'insideRight',
                show: true,
                formatter: params => {
                    const { dimensionNames, value } = params;
                    return `${Math.abs(value[dimensionNames[1]])}`;
                },
            },
            emphasis: {
                focus: 'series',
            },
            tooltip: {
                valueFormatter: value => `${Math.abs(Number.parseInt(value.toString(), 10))}`, // Make the value positive
            },
            dimensions: [
                { name: 'category', type: 'ordinal' },
                { name: `optOut`, type: 'int' },
            ],
        },
    ],
};

const getCategoryName = category => {
    return translate(`consent.categories.${CMP_CONSENT_CATEGORIES[`${category}`]}`);
};

const csvColumnMap = {
    category: 'common.fields.classification.label',
    optIn: 'common.optIn',
    optOut: 'common.optOut',
};

const processRow = (row, columnNames) => {
    return columnNames.reduce((acc, column) => {
        switch (column) {
            case 'category':
                acc.push(getCategoryName(row[column]));
                break;
            case 'optIn':
            case 'optOut':
                acc.push(Math.abs(row[column]));
                break;
            default:
                row[column] && acc.push(`${row[column]}`);
                break;
        }
        return acc;
    }, []);
};

/**
 * @param {CmpReportsData} data
 * @returns {Blob}
 */
function makeCSV(data) {
    const { consentsByCategory } = data || {};
    const columnNames = Object.keys(csvColumnMap);
    // String CSV
    const csvFile = consentsByCategory
        .reduce(
            (acc, row) => {
                const rowArray = processRow(row, columnNames);
                acc.push(rowArray.join(','));
                return acc;
            },
            [columnNames.map(column => translate(csvColumnMap[column])).join(',')]
        )
        .join('\n');
    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

export const Categories = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data, error, load, loading } =
        /** @type {import('/b2b/common/hoc/withAsyncData').HocAsyncDataContext<CmpReportsData>} */ (
            useContext(ConsentDashboardContext)
        );
    const { consentsByCategory } = data;
    /** @type {import('/b2b/common/theme').OsanoDefaultTheme} */
    const theme = useTheme();
    const noResults =
        consentsByCategory.length === 0 ||
        !consentsByCategory.some(row => row.optIn > 0 || row.optOut > 0);
    const isLoading = loading || providedLoading;
    const memoOption = useMemo(() => {
        const maxOptInOut = consentsByCategory.reduce(
            (acc, row) => {
                const { optIn, optOut } = row;
                return [Math.max(acc[0], Math.abs(optIn)), Math.max(acc[1], Math.abs(optOut))];
            },
            [0, 0]
        );
        const range = Math.max.call(null, 1, ...maxOptInOut);
        return merge({}, staticOptions, {
            tooltip: {
                formatter(params) {
                    const { axisValueLabel } = params[0];
                    // eslint-disable-next-line xss/no-mixed-html
                    return `<b>${getCategoryName(axisValueLabel)}</b><hr style="margin:0.25em 0"/>${params
                        .map(({ dimensionNames, marker, seriesName, value }) => {
                            const count = value[dimensionNames[1]];
                            return `${marker} ${translate(seriesName)}: ${Math.abs(count)}`;
                        })
                        .join('<br>')}`;
                },
            },
            legend: {
                left: theme.spacing(1.5),
            },
            grid: {
                left: 128 + parseInt(theme.spacing(4), 10),
                right: parseInt(theme.spacing(2), 10),
            },
            xAxis: {
                min: -range,
                max: range,
            },
            yAxis: {
                offset: 128 + parseInt(theme.spacing(4), 10),
                axisLabel: {
                    formatter: getCategoryName,
                    margin: parseInt(theme.spacing(2), 10),
                    width: 128 + parseInt(theme.spacing(2), 10),
                },
            },
            dataset: {
                source: consentsByCategory,
            },
            series: castArray(staticOptions.series).map(series => {
                return {
                    ...series,
                    name: translate(series.name),
                };
            }),
        });
    }, [consentsByCategory, shouldRetranslate]);

    /** @type {() => Promise<CmpReportsResponseData>} */
    const fetchData = useBoundCallback(
        filters =>
            fetchConsentReportsDashboard({
                filters: {
                    ...filters,
                    requestedCharts: ['consentsOverTime'],
                },
            }),
        [filters]
    );

    const echartsInstance = useRef(null);
    const helpers = useMemo(
        () => ({
            makeCSV,
            name: 'consents-by-category',
        }),
        []
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData]);

    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={['csv', 'png']}
            echartsInstance={echartsInstance}
            error={error}
            title="consent.dashboard.charts.title.categories"
            tooltip="consent.dashboard.charts.tooltip.categories"
        >
            <EChart
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </WidgetContainer>
    );
};

Categories.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

/** @type {import('/b2b/common/hoc/withAsyncData').ComponentWithOverridenProps<CmpReportsData, { filters: CmpReportsQueryParams }>} */
const TranslatedComponent = withRetranslate(Categories);

export default withAsyncData(TranslatedComponent, ConsentDashboardContext);
