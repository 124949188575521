export const formatDashboardTodos = (todos = {}) => {
    const formattedTodos = Object.entries(todos).reduce((acc, [product, productTodos]) => {
        const formattedValue = Object.entries(productTodos).reduce((acc, [todo, todoCount]) => {
            let count = 0;
            try {
                count = todoCount ? Number.parseInt(todoCount, 10) : 0;
            } catch {
                // Do nothing
            }

            return {
                ...acc,
                [todo]: count,
            };
        }, {});
        return {
            ...acc,
            [product]: formattedValue,
        };
    }, {});

    return formattedTodos;
};
