/* eslint-disable camelcase */
import { formatDataStore, formatDataStoreAgentResults } from '/b2b/dataStores/format';
import {
    formatFilterParams,
    formatPagingParams,
    handleError,
    makeResultsFormatter,
} from '/b2b/common/services/helpers';
import {
    formatPrivacyProtocol,
    formatPrivacyProtocolCollection,
    formatUcConfig,
    formatUcConfigConsentDetails,
    formatUcConfigConsentResults,
    formatUcConfigIntegrationOperation,
    formatUcConfigIntegrationResults,
} from './format';

import API from '@aws-amplify/api';
import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { apiName } from '@osano-b2b';
import castArray from 'lodash/castArray';
import { createCollectionPayloadFromState } from './utils';
import { requestOAuthToken } from '/b2b/dataStores/services';
import { v4 as uuid } from 'uuid';

export const connectOAuth2 = (details, redirect = AuthenticatedPaths.CONNECT_UC_INTEGRATION) => {
    const {
        agentId,
        dataStoreId,
        authType,
        datasource, // eslint-disable-line no-unused-vars
        name,
        authorization_url,
        authorizationUrl = authorization_url,
        ...params
    } = details;
    let uri;

    const redirectUri = new URL(
        redirect,
        `${document.location.protocol}//${document.location.host}`
    );

    // client_credentials flow
    if (!authorizationUrl) {
        return new Promise((resolve, reject) => {
            const body = {
                ...params,
                redirect_uri: redirectUri.href,
            };
            requestOAuthToken(agentId, body)
                .then(tokenData => resolve(tokenData))
                .catch(({ message } = {}) => reject(message));
        });
    }

    // authorization_code flow
    try {
        const replacedUrl = authorizationUrl.replace(
            /\$\{(.+?)\}/g,
            (match, parameter) => params[`${parameter}`] || match
        );
        uri = new URL(replacedUrl);
    } catch (e) {
        return Promise.reject(
            `The authorization url for OAuth ${
                dataStoreId ? `Data Store ${dataStoreId}` : `Agent ${agentId}`
            } cannot be constructed.`
        );
    }
    const oauthState = uuid();
    Object.entries(params).forEach(([key, value]) => {
        if (uri.search.includes(key)) {
            uri.searchParams.set(key, value);
        }
    });
    if (uri.search.includes('redirect_uri')) {
        uri.searchParams.set('redirect_uri', redirectUri.href);
    }
    uri.searchParams.append('state', oauthState);

    let timer;
    return new Promise((resolve, reject) => {
        const w = 360;
        const h = 480;
        // Fixes dual-screen position
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
              ? document.documentElement.clientWidth
              : screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
              ? document.documentElement.clientHeight
              : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;
        const callback = messageEvent => {
            const { origin, data } = messageEvent;
            if (origin !== window.location.origin) {
                // If the OAuth didn't redirect and post...ignore it
                return;
            }
            let parsedData;
            try {
                parsedData = typeof data === 'string' ? JSON.parse(data) : data || {};
            } catch (e) {
                // We cannot guarantee that this message was intended for OAuth, so don't reject
                return;
            }
            const { state, ...response } = parsedData;
            const { access_token } = response;
            if (!state || state !== oauthState) {
                // This message might be for another OAuth, so don't reject
                return;
            }
            try {
                clearInterval(timer);
                window.removeEventListener('message', callback);
            } catch (e) {
                // Already removed
            }
            const body = {
                ...params,
                ...response,
                redirect_uri: redirectUri.href,
                state: uri.search.includes('state_long')
                    ? uri.searchParams.get('state_long')
                    : oauthState,
            };

            // Only do a token request if the OAuth was not `token`
            if (!access_token) {
                requestOAuthToken(agentId, body)
                    .then(tokenData => {
                        resolve(tokenData);
                    })
                    .catch(({ message } = {}) => reject(message));
            } else {
                resolve({ agentId, authType, ...body, name });
            }
        };
        window.addEventListener('message', callback);
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        const child = window.open(
            uri.href,
            '_blank',
            `scrollbars=yes,toolbar=0,status=0,width=${w / systemZoom},height=${
                h / systemZoom
            },top=${top},left=${left},rel=opener`
        );
        timer = setInterval(() => {
            if (child.closed) {
                try {
                    window.removeEventListener('message', callback);
                } catch (e) {
                    // Already removed
                }
                reject('Window was closed before authentication completed. Please try again.');
                clearInterval(timer);
            }
        }, 500);
    });
};

export const fetchPrivacyProtocols = params => {
    const queryStringParameters = {
        ...formatPagingParams(params),
        ...formatFilterParams(params),
        preconfigured: true,
    };
    const options = {
        queryStringParameters,
    };
    return API.get(apiName, `/uc/privacy-protocols`, options)
        .catch(handleError)
        .then(makeResultsFormatter(formatPrivacyProtocol));
};

export const fetchPrivacyProtocolById = id => {
    const options = {};
    return API.get(apiName, `/uc/privacy-protocol/${encodeURIComponent(id)}`, options)
        .catch(handleError)
        .then(formatPrivacyProtocol);
};

export const createPrivacyProtocol = state => {
    const options = {
        body: state,
    };
    return API.post(apiName, `/uc/privacy-protocol`, options)
        .catch(handleError)
        .then(responseOrId =>
            typeof responseOrId === 'object'
                ? { ...state, ...responseOrId }
                : { privacyProtocolId: responseOrId, ...state }
        )
        .then(formatPrivacyProtocol);
};

export const copyPrivacyProtocolById = id => {
    const options = {
        body: {
            privacyProtocolId: id,
        },
    };
    return API.post(apiName, `/uc/privacy-protocol/duplicate`, options)
        .catch(handleError)
        .then(formatPrivacyProtocol);
};

export const updatePrivacyProtocolById = (id, state) => {
    const options = {
        body: state,
    };

    return API.patch(apiName, `/uc/privacy-protocol/${encodeURIComponent(id)}`, options)
        .catch(handleError)
        .then(formatPrivacyProtocol);
};

export const deletePrivacyProtocol = idOrIds => {
    const idsToDelete = castArray(idOrIds);
    const options = {
        body: idsToDelete,
        responseType: 'text',
    };
    return API.del(apiName, `/uc/privacy-protocols`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return resp || response || idsToDelete;
            }
            return resp || response;
        });
};

export const fetchPrivacyProtocolCollections = params => {
    const queryStringParameters = {
        ...formatPagingParams(params),
        ...formatFilterParams(params),
        preconfigured: true,
    };
    const options = {
        queryStringParameters,
    };
    return API.get(apiName, `/uc/privacy-protocols`, options)
        .catch(handleError)
        .then(makeResultsFormatter(formatPrivacyProtocolCollection));
};

export const fetchUcConfigs = params => {
    const options = {
        queryStringParameters: {
            ...formatPagingParams(params),
            ...formatFilterParams(params),
        },
    };
    return API.get(apiName, `/uc/configs`, options)
        .catch(handleError)
        .then(makeResultsFormatter(formatUcConfig));
};

export const fetchUcConfigById = id => {
    const options = {};
    return API.get(apiName, `/uc/config/${encodeURIComponent(id)}`, options)
        .catch(handleError)
        .then(formatUcConfig);
};

export const createUcConfig = state => {
    const body = createCollectionPayloadFromState(state);
    const options = {
        body,
        headers: {
            'Content-Type': body !== state ? 'multipart/form-data' : 'application/json',
        },
    };
    return API.post(apiName, `/uc/configs`, options)
        .catch(handleError)
        .then(responseOrId =>
            typeof responseOrId === 'object'
                ? { ...state, ...responseOrId }
                : { id: responseOrId, ...state }
        )
        .then(formatUcConfig);
};

export const copyUcConfigById = (id, overrides) => {
    const body = createCollectionPayloadFromState(overrides);
    const options = {
        body,
        headers: {
            'Content-Type': body !== overrides ? 'multipart/form-data' : 'application/json',
        },
    };
    return API.post(apiName, `/uc/config/${encodeURIComponent(id)}/duplicate`, options)
        .catch(handleError)
        .then(formatUcConfig);
};

export const updateUcConfigById = (id, state) => {
    const body = createCollectionPayloadFromState(state);
    const options = {
        body,
        headers: {
            'Content-Type': body !== state ? 'multipart/form-data' : 'application/json',
        },
    };

    return API.patch(apiName, `/uc/config/${encodeURIComponent(id)}`, options)
        .catch(handleError)
        .then(formatUcConfig);
};

export const deleteUcConfig = idOrIds => {
    const idsToDelete = castArray(idOrIds);
    const options = {
        body: idsToDelete,
        responseType: 'text',
    };
    return API.del(apiName, `/uc/configs`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return resp || response || idsToDelete;
            }
            return resp || response;
        });
};

export const publishUcConfigById = id => {
    const options = {};
    return API.post(apiName, `/uc/config/${encodeURIComponent(id)}`, options).catch(handleError);
};

export const fetchUcConfigConsents = (id, params) => {
    const options = {
        queryStringParameters: {
            ...formatPagingParams(params),
            ...formatFilterParams(params),
        },
    };
    return API.get(apiName, `/uc/config/${encodeURIComponent(id)}/consents`, options)
        .catch(handleError)
        .then(formatUcConfigConsentResults);
};

export const fetchUcConfigConsentById = (configId, consentId) => {
    const options = {};

    return API.get(
        apiName,
        `/uc/config/${encodeURIComponent(configId)}/consent/${consentId}`,
        options
    )
        .catch(handleError)
        .then(formatUcConfigConsentDetails);
};

export const fetchUcConfigIntegrations = params => {
    const options = {
        queryStringParameters: formatPagingParams(params),
    };
    return API.get(apiName, `/uc/integrations`, options)
        .catch(handleError)
        .then(formatUcConfigIntegrationResults);
};

export const fetchUcConfigOperations = (id, params) => {
    const options = {
        queryStringParameters: formatPagingParams(params),
    };
    return API.get(apiName, `/uc/integration/${encodeURIComponent(id)}/operations`, options)
        .catch(handleError)
        .then(makeResultsFormatter(formatUcConfigIntegrationOperation));
};

export const fetchUcIntegrationAgents = params => {
    const options = {
        queryStringParameters: formatPagingParams(params),
    };
    return API.get(apiName, `/uc/integration-agents`, options)
        .catch(handleError)
        .then(formatDataStoreAgentResults)
        .then(response => {
            const { results } = response;
            return results.reduce((agents, result) => {
                const { agentId, name, schema, ...data } = result;
                agents[agentId] = {
                    ...data,
                    agentId,
                    name,
                    schema,
                };
                return agents;
            }, {});
        });
};

export const createUcConfigIntegration = params => {
    const options = {
        body: params,
    };
    return API.post(apiName, `/uc/integrations`, options)
        .catch(handleError)
        .then(responseOrId =>
            typeof responseOrId === 'object'
                ? { ...options.body, ...responseOrId }
                : { id: responseOrId, ...options.body }
        )
        .then(formatDataStore);
};

export const updateUcConfigIntegrationById = (id, state) => {
    const options = {
        body: state,
    };
    return API.patch(apiName, `/uc/integration/${id}`, options)
        .catch(handleError)
        .then(responseOrId =>
            typeof responseOrId === 'object'
                ? { ...options.body, ...responseOrId }
                : { id: responseOrId, ...options.body }
        )
        .then(formatDataStore);
};

export const deleteUcConfigIntegration = idOrIds => {
    const idsToDelete = castArray(idOrIds);
    const options = {
        body: idsToDelete,
        responseType: 'text',
    };
    return API.del(apiName, `/uc/integrations`, options)
        .then(response => {
            try {
                // Check if the response is JSON
                return JSON.parse(response);
            } catch (error) {
                return { status: 'success', code: 200, response };
            }
        })
        .catch(handleError)
        .then(response => {
            const { status, response: resp } = response;
            if (status === 'success') {
                return resp || response || idsToDelete;
            }
            return resp || response;
        });
};

export const fetchUcConfigOperationData = (id, operation) => {
    const options = {};
    return API.get(
        apiName,
        `/uc/integration/${encodeURIComponent(id)}/operation/${operation}`,
        options
    ).catch(handleError);
};

/**
 * @param {UcReportsQueryParams} queryStringParameters
 * @returns {Promise<UcReportsData>}
 */
export const fetchUcReportsDashboard = params => {
    const queryStringParameters = {
        ...formatPagingParams(params),
        ...formatFilterParams(params, true),
    };
    const options = {
        queryStringParameters,
    };
    return API.get(apiName, `/uc/reports/dashboard`, options).catch(handleError);
};
