import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import React, { Fragment, useContext, useLayoutEffect } from 'react';

import EditNoteIcon from '@mui/icons-material/BorderColor';
import Locale from '/b2b/common/components/Locale';
import { NAV_LABELS } from '/b2b/common/layouts/Dashboard/components/Sidebar/constants';
import { PRODUCTS } from '/b2b/dashboard/constants';
import ProductTodos from './components/ProductTodos';
import PropTypes from 'prop-types';
import TodoListContext from './TodoList.context';
import { fetchDashboardTodos } from '/b2b/dashboard/services';
import { getFilteredTodos } from '/b2b/dashboard/state/Dashboard.selectors';
import makeStyles from '@mui/styles/makeStyles';
import startCase from 'lodash/startCase';
import styles from './TodoList.styles';
import toLower from 'lodash/toLower';
import { useSelector } from 'react-redux';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

const useStyles = makeStyles(styles);

const TodoList = props => {
    const { allowedTodos = {}, data: providedData, loading: providedLoading } = props;
    const classes = useStyles(props);
    const { data = {}, load, loading } = useContext(TodoListContext);
    const isLoading = loading || providedLoading;

    const todos = useSelector(state => getFilteredTodos(state, data));

    // have at least one allowed todo by permission
    const canViewTodoList = Object.values(allowedTodos).some(Boolean);
    // have at least one todo item
    const hasTodos = Object.values(todos).some(todo => Object.keys(todo).length > 0);

    useLayoutEffect(() => {
        if (!canViewTodoList) {
            return;
        }

        const todoPermissions = Object.values(allowedTodos).reduce(
            (acc, { product } = {}) => {
                switch (product) {
                    case PRODUCTS.ASSESSMENT_PRODUCT:
                        acc.canViewAssessments = true;
                        break;
                    case PRODUCTS.COOKIE_CONSENT_PRODUCT:
                        acc.canViewCookieConsent = true;
                        break;
                    case PRODUCTS.DATA_MAPPING_PRODUCT:
                        acc.canViewDataMapping = true;
                        break;
                    case PRODUCTS.SUBJECT_RIGHTS_PRODUCT:
                        acc.canViewSubjectRights = true;
                        break;
                    case PRODUCTS.UNIFIED_CONSENT_PRODUCT:
                        acc.canViewUnifiedConsent = true;
                        break;
                    case PRODUCTS.VENDOR_MANAGEMENT_PRODUCT:
                        acc.canViewVendorMonitoring = true;
                        break;
                    default:
                        break;
                }

                return acc;
            },
            {
                canViewAssessments: false,
                canViewCookieConsent: false,
                canViewDataMapping: false,
                canViewSubjectRights: false,
                canViewUnifiedConsent: false,
                canViewVendorMonitoring: false,
            }
        );
        providedData
            ? load(Promise.resolve(providedData))
            : load(fetchDashboardTodos(todoPermissions));
    }, [JSON.stringify(allowedTodos), canViewTodoList, providedData]);

    return isLoading || (canViewTodoList && hasTodos) ? (
        <Grid item xs={12}>
            <Paper className={classes.paper} elevation={0}>
                <Box className={classes.header}>
                    <EditNoteIcon className={classes.icon} />
                    <Typography variant="h3">
                        <Locale path="common.todo" formatter={str => startCase(toLower(str))} />
                    </Typography>
                </Box>
                {isLoading ? (
                    <Box className={classes.loading}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Fragment>
                        {Object.keys(todos.cookieConsent || {}).length > 0 && (
                            <ProductTodos
                                title={NAV_LABELS.consentManager}
                                todos={todos.cookieConsent}
                                dataTestId="todo-cookie-consent"
                            />
                        )}
                        {Object.keys(todos.unifiedConsent || {}).length > 0 && (
                            <ProductTodos
                                title={NAV_LABELS.unifiedConsent}
                                todos={todos.unifiedConsent}
                                dataTestId="todo-unified-consent"
                            />
                        )}
                        {Object.keys(todos.subjectRights || {}).length > 0 && (
                            <ProductTodos
                                title={NAV_LABELS.dsar}
                                todos={todos.subjectRights}
                                dataTestId="todo-subject-rights"
                            />
                        )}
                        {Object.keys(todos.dataMapping || {}).length > 0 && (
                            <ProductTodos
                                title={NAV_LABELS.dataMapping}
                                todos={todos.dataMapping}
                                dataTestId="todo-data-mapping"
                            />
                        )}
                        {Object.keys(todos.vendorMonitoring || {}).length > 0 && (
                            <ProductTodos
                                title={NAV_LABELS.vendor}
                                todos={todos.vendorMonitoring}
                                dataTestId="todo-vendor-privacy-risk"
                            />
                        )}
                        {Object.keys(todos.assessments || {}).length > 0 && (
                            <ProductTodos
                                title={NAV_LABELS.assessments}
                                todos={todos.assessments}
                                dataTestId="todo-assessments"
                            />
                        )}
                    </Fragment>
                )}
            </Paper>
        </Grid>
    ) : null;
};

TodoList.propTypes = {
    allowedTodos: PropTypes.object,
    data: PropTypes.object,
    loading: PropTypes.bool,
};

export default withAsyncData(withRetranslate(TodoList), TodoListContext);
