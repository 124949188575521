import React, { useContext, useLayoutEffect, useMemo } from 'react';

import { CMP_CONSENT_CATEGORIES } from '/b2b/consent/constants';
import ConsentDashboardContext from '../ConsentDashboard.context';
import ErrorBoundary from '/b2b/common/components/ErrorBoundary';
// @ts-check
import { GeoEChart } from '/b2b/common/components/Charts';
import PropTypes from 'prop-types';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchConsentReportsDashboard } from '/b2b/consent/services';
import { getMapData } from '/b2b/common/components/Charts/EChart.geo';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'consentsByJurisdiction';

/**
 * @typedef {Record<string,Partial<Record<'totalConsents'|CmpConsentCategories,number>>>} GeoLookup
 */

const csvColumnMap = {
    name: 'common.fields.name.label',
    jurisdiction: 'common.jurisdiction',
    totalConsents: 'common.total',
    .../** @type {{[K in CmpConsentCategoriesValues as `${K}TotalConsents`]: string;}} */ (
        Object.values(CMP_CONSENT_CATEGORIES).reduce((acc, category) => {
            switch (category) {
                case CMP_CONSENT_CATEGORIES.STORAGE:
                    return acc;
                default: {
                    acc[`${category}TotalConsents`] = [
                        'common.total',
                        `consent.categories.${category}`,
                    ];
                    break;
                }
            }
            return acc;
        }, {})
    ),
};

/**
 * @param {Unpacked<CmpReportsData['consentsByJurisdiction']>} row
 * @param {Array<keyof typeof csvColumnMap>} columnNames
 * @return
 */
const processRow = (row, columnNames) => {
    const { jurisdiction } = row;
    const mapData = getMapData(jurisdiction);
    const { divisionLevel } = mapData;
    let divisionName = jurisdiction;
    switch (divisionLevel) {
        case 0: {
            // Countries
            divisionName = translate(`countries.${jurisdiction}`);
            break;
        }
        case 1: {
            // States/Provinces/Territories
            divisionName = translate(`division.${jurisdiction}`);
            break;
        }
        case 2: {
            // Counties/Regions/Districts
            divisionName = translate(`regions.${jurisdiction}`);
            break;
        }
        default: {
            break;
        }
    }
    return columnNames
        .reduce((acc, column) => {
            switch (column) {
                case 'name':
                    acc.push(divisionName);
                    break;
                default:
                    acc.push(row[column]);
                    break;
            }
            return acc;
        }, [])
        .join(',');
};

/**
 * @param {CmpReportsData} data
 * @returns {Blob}
 */
function makeCSV(data) {
    const { consentsByJurisdiction } = data || {};
    const columnNames = /** @type {Array<keyof typeof csvColumnMap>} */ (Object.keys(csvColumnMap));
    // String CSV
    const csvFile = Object.values(consentsByJurisdiction)
        .reduce(
            (/** @type {string[]} */ acc, row) => {
                const rowArray = processRow(row, columnNames);
                return acc.concat(rowArray);
            },
            [
                columnNames
                    .map(column => translate(csvColumnMap[column], { delimiter: ' - ' }))
                    .join(','),
            ]
        )
        .join('\n');
    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

/** @type {import('echarts').EChartsOption} */
const staticOptions = {
    color: ['#fff'],
    legend: {
        show: false,
    },
    tooltip: {},
    // @ts-ignore
    geo: {
        animation: true,
        left: 0,
        right: 0,
        emphasis: {
            disabled: true,
        },
        select: {
            itemStyle: {
                borderWidth: 0,
            },
        },
    },
    dataset: {
        sourceHeader: true,
    },
    visualMap: {
        type: 'continuous',
        inRange: {
            color: ['#7A3FF1'],
            colorAlpha: [0.5, 1],
            // colorHue: [260],
            // colorSaturation: [0.86],
            // colorLightness: [0.5, 0.8],
        },
        show: false,
    },
};

const ConsentsByJurisdiction = props => {
    const { filters, data: providedData, loading: providedLoading, shouldRetranslate } = props;
    const { data, error, load, loading } = useContext(ConsentDashboardContext);
    const { consentsByJurisdiction = [] } = data;
    const noResults = consentsByJurisdiction.length === 0;
    const isLoading = loading || providedLoading;

    const echartsInstance = React.useRef(null);
    const onChartReady = React.useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    const helpers = useMemo(
        () => ({
            makeCSV,
            name: 'consents-by-jurisdiction',
        }),
        [makeCSV]
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);

    const [geoLookup, categories, center, zoom] = useMemo(() => {
        if (!consentsByJurisdiction.length) {
            return [{}, [], [0, 0], 1];
        }

        let [center, zoom, total] = [[0, 0], 1, 0];
        const [geoLookup, categoriesSet] = consentsByJurisdiction.reduce(
            ([geoLookup, categoriesSet], row) => {
                const { jurisdiction, consents, totalConsents } = row;
                const categories = /** @type {CmpConsentCategories[]} */ (Object.keys(consents));
                geoLookup[jurisdiction] = {
                    ...Object.entries(consents).reduce(
                        (acc, /** @type {[CmpConsentCategories,number]} */ [category, optIn]) => {
                            acc[category] = optIn;
                            return acc;
                        },
                        {}
                    ),
                    totalConsents,
                };

                if (totalConsents > total) {
                    total = totalConsents;
                    const mapData = getMapData(jurisdiction);
                    center = mapData.center;
                    zoom = mapData.zoom;
                }

                categories.forEach(category => categoriesSet.add(category));

                return [geoLookup, categoriesSet];
            },
            [
                // eslint-disable-next-line max-len
                /** @type {GeoLookup} */ ({}),
                /** @type {Set<CmpConsentCategories>} */ (new Set()),
            ]
        );

        return [geoLookup, Array.from(categoriesSet), center, zoom];
    }, [consentsByJurisdiction]);

    const renderTooltip = useBoundCallback(
        /** @type {(geoLookup: GeoLookup, categories: CmpConsentCategories[], tooltipParams: import('echarts').TooltipComponentOption) => string} */
        (geoLookup, categories, { name }) => {
            const mapData = getMapData(name);
            const { divisionCode, divisionLevel } = mapData;
            let divisionName = name;
            switch (divisionLevel) {
                case 0: {
                    // Countries
                    divisionName = translate(`countries.${divisionCode}`);
                    break;
                }
                case 1: {
                    // States/Provinces/Territories
                    divisionName = translate(`divisions.${divisionCode}`);
                    break;
                }
                default: {
                    divisionName = name;
                    break;
                }
            }

            const geoData = geoLookup[divisionCode];
            if (!categories.length || !geoData) {
                return '';
            }

            const validCategories = categories.filter(
                category => !['STORAGE'].includes(category) && geoData[category]
            );
            const total = geoData.totalConsents || 0;

            // eslint-disable-next-line xss/no-mixed-html
            return `<b>${divisionName}</b><hr style="margin:0.25em 0"/>${validCategories
                .sort((a, b) => translate(a).localeCompare(translate(b)))
                .map(category => {
                    const value = geoLookup[divisionCode][category] || 0;
                    const percent = total > 0 ? ((value / total) * 100).toFixed(0) : 0;
                    return `${translate(`consent.categories.${CMP_CONSENT_CATEGORIES[category]}`)}: <b>${percent}%</b> (${value})`;
                })
                .join('<br>')}`;
        },
        [geoLookup, categories]
    );
    const memoOption = useMemo(() => {
        const { consentRange } = data;
        return merge({}, staticOptions, {
            geo: {
                tooltip: {
                    formatter: renderTooltip,
                },
                center,
                zoom,
            },
            visualMap: {
                min: consentRange[0],
                max: consentRange[1],
            },
            series: [
                {
                    type: 'map',
                    geoIndex: 0,
                    emphasis: {
                        disabled: true,
                    },
                    data: consentsByJurisdiction.map(row => {
                        const { jurisdiction, totalConsents } = row;
                        return {
                            name: jurisdiction,
                            value: totalConsents,
                        };
                    }),
                },
            ],
        });
    }, [center, consentsByJurisdiction, categories, shouldRetranslate, zoom]);

    /** @type {() => Promise<CmpReportsResponseData>} */
    const fetchData = useBoundCallback(
        filters =>
            fetchConsentReportsDashboard({
                filters: {
                    ...filters,
                    requestedCharts: [REPORT_NAME],
                },
            }),
        [filters]
    );
    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData]);

    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={['csv', 'png']}
            echartsInstance={echartsInstance}
            error={error}
            title="consent.dashboard.charts.title.consentsByJurisdiction"
            tooltip="consent.dashboard.charts.tooltip.consentsByJurisdiction"
        >
            <ErrorBoundary>
                <GeoEChart
                    loading={isLoading}
                    makeDownload={makeDownload}
                    noResults={noResults}
                    onChartReady={onChartReady}
                    option={memoOption}
                    replaceMerge={['series']}
                />
            </ErrorBoundary>
        </WidgetContainer>
    );
};

ConsentsByJurisdiction.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

/** @type {import('/b2b/common/hoc/withAsyncData').ComponentWithOverridenProps<CmpReportsData, { filters: CmpReportsQueryParams }>} */
const TranslatedComponent = withRetranslate(ConsentsByJurisdiction);

export default withAsyncData(TranslatedComponent, ConsentDashboardContext);
