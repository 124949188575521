import { ENABLE_DASHBOARD_V2, isFeatureEnabled } from '/b2b/common/helpers/FeatureFlags';

import Component from './Dashboard.component';
import { connect } from 'react-redux';
import { fetchDashboard } from './Dashboard.commands';
import fromState from '/b2b/common/state/selectors';

const mapStateToProps = state => ({
    allowedReports: fromState.Dashboard.getAllowedReports(state),
    isDashboardV2Enabled: isFeatureEnabled(state, ENABLE_DASHBOARD_V2),
});

const mapDispatchToProps = {
    fetchDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
