// @ts-check
/**
 * @typedef CSVHelpers
 * @property {(data: import("echarts").EChartsOption) => Promise<Blob>} makeCSV
 */

/**
 * @typedef {Object} PNGHelpers

/**
 * @export
 * @param {React.MutableRefObject<import("echarts/core").EChartsType>} instance
 * @param {import("echarts").EChartsOption} data
 * @param {CSVHelpers & PNGHelpers & { name?: string }} helpers
 * @param {import("echarts/core").ECElementEvent & { type: import("echarts/core").ECElementEvent['type'] & 'download'}} event
 * @return {Promise<{ file: Blob, name: string }>}
 * @throws {Error}
 */
export async function makeDownloadBlob(instance, data, helpers, event) {
    const { type, format } = event;
    if (type !== 'download' || !format) {
        // Does not need translations because this is a developer error
        throw new Error('Download format not specified');
    }
    const { name } = helpers;
    switch (format) {
        case 'csv': {
            const { makeCSV } = helpers;
            const blob = await makeCSV(data);
            return { file: blob, name: `${name}.csv` };
        }
        case 'png': {
            instance.current.hideLoading();
            const dataUrl = await instance.current.getConnectedDataURL({
                type: format,
                pixelRatio: 2,
                excludeComponents: ['toolbox'],
            });
            const res = await fetch(dataUrl);
            const blob = await res.blob();
            return { file: blob, name: `${name}.${format}` };
        }
        default: {
            // Does not need translations because this is a developer error
            throw new Error(`Unknown download format: ${format}`);
        }
    }
}
