import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { translate } from '/b2b/common/helpers/i18n';

function processRow(row, columnNames) {
    return columnNames.map(column =>
        column === 'date' ? `"${moment.utc(row[column]).format('MMM, YYYY')}"` : row[column]
    );
}

export function makeCSV(reportData, labels, truncateByDate = true) {
    const columnNames = [truncateByDate && 'date', ...Object.keys(labels)].filter(Boolean);

    const headers = columnNames
        .map(column =>
            column === 'date'
                ? capitalize(translate('common.date'))
                : // pluralize the column name when it's a smart translation
                  capitalize(translate(labels[column], { smart_count: 2 }))
        )
        .join(',');

    const rows = reportData.map(row => processRow(row, columnNames).join(',')).join('\n');

    const csvFile = `${headers}\n${rows}`;

    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

// TODO: Remove when ENABLE_DSAR_MULTI_SORTING is enabled and switch to makeRequestFiltersV2
export function makeRequestFilters(data = []) {
    const filters = data[0];
    const providedData = data[1];
    const requestTypeData = data[2];

    const getJurisdictions = filters => {
        if (filters?.jurisdictions?.length) {
            // @ts-ignore
            return [...new Set(filters.jurisdictions.map(c => c?.value))].join(',');
        }
        return '';
    };

    const getRequestTypes = (filters, providedData, requestTypeData) => {
        if (filters?.requestTypes?.length) {
            const ids = filters.requestTypes.map(c => c?.value);
            // @ts-ignore
            return [...new Set(ids)].join(',');
        } else if (providedData?.requestsByJurisdiction?.length) {
            const requestData = providedData.requestsByJurisdiction.flatMap(c =>
                Object.keys(c?.requestTypes)
            );

            const ids = requestData.map(c => requestTypeData?.find(d => d.name === c)?.id);

            return [
                // @ts-ignore
                ...new Set(ids),
            ].join(',');
        }
        return '';
    };

    // providedData has no form data
    const getForms = filters => {
        if (filters?.forms?.length) {
            // @ts-ignore
            return [...new Set(filters.forms.map(c => c?.value))].join(',');
        }
        return '';
    };

    // providedData has no range data
    const getDateRange = filters => {
        if (filters?.dateRange?.length) {
            // @ts-ignore
            return [...new Set(filters.dateRange)].join(',');
        }
        return '';
    };

    // params for webservices getRequests
    const jurisdictions = getJurisdictions(filters);
    const createdDateRange = getDateRange(filters);
    const requestType = getRequestTypes(filters, providedData, requestTypeData);
    const formId = getForms(filters);
    const requestStatus = filters?.requestStatus;

    let params = '';

    if (requestStatus) {
        params += `requestStatus=${requestStatus}&`;
    }

    if (jurisdictions) {
        params += `jurisdictions=${jurisdictions}&`;
    }
    if (createdDateRange) {
        params += `createdDateRange=${createdDateRange}&`;
    }
    if (requestType) {
        params += `requestType=${requestType}&`;
    }
    if (formId) {
        params += `formId=${formId}&`;
    }

    params = params.replace(/&$/, '');

    return params;
}

export function makeRequestFiltersV2(data = []) {
    const filters = data[0];
    const providedData = data[1];
    const requestTypeData = data[2];

    const getJurisdictions = filters => {
        if (filters?.jurisdictions?.length) {
            // @ts-ignore
            return [...new Set(filters.jurisdictions.map(c => c?.value))].join(',');
        }
        return '';
    };

    const getRequestTypes = (filters, providedData, requestTypeData) => {
        if (filters?.requestTypes?.length) {
            const ids = filters.requestTypes.map(c => c?.value);
            // @ts-ignore
            return [...new Set(ids)].join(',');
        } else if (providedData?.requestsByJurisdiction?.length) {
            const requestData = providedData.requestsByJurisdiction.flatMap(c =>
                Object.keys(c?.requestTypes)
            );

            const ids = requestData.map(c => requestTypeData?.find(d => d.name === c)?.id);

            return [
                // @ts-ignore
                ...new Set(ids),
            ].join(',');
        }
        return '';
    };

    // providedData has no form data
    const getForms = filters => {
        if (filters?.forms?.length) {
            // @ts-ignore
            return [...new Set(filters.forms.map(c => c?.value))].join(',');
        }
        return '';
    };

    // providedData has no range data
    const getDateRange = filters => {
        if (filters?.dateRange?.length) {
            // @ts-ignore
            return [...new Set(filters.dateRange)].join(',');
        }
        return '';
    };

    // params for webservices getRequests
    const jurisdictions = getJurisdictions(filters);
    const createdDateRange = getDateRange(filters);
    const requestType = getRequestTypes(filters, providedData, requestTypeData);
    const formId = getForms(filters);
    const requestStatus = filters?.requestStatus;

    let params = '';

    if (requestStatus) {
        params += `requestStatus=isAnyOf:${requestStatus}&`;
    }
    if (jurisdictions) {
        params += `location=isAnyOf:${jurisdictions}&`;
    }
    if (createdDateRange) {
        params += `created=isBetween:${createdDateRange}&`;
    }
    if (requestType) {
        params += `requestType=isAnyOf:${requestType}&`;
    }
    if (formId) {
        params += `formName=isAnyOf:${formId}&`;
    }
    if (params.length > 0) {
        params += 'linkOperator=and';
    }

    params = params.replace(/&$/, '');

    return params;
}

export function makeActionItemFilters(data = []) {
    const filters = data[0];
    const providedData = data[1];
    const requestTypeData = data[2];

    const getJurisdictions = filters => {
        if (filters?.jurisdictions?.length) {
            // @ts-ignore
            return [...new Set(filters.jurisdictions.map(c => c?.value))].join(',');
        }
        return '';
    };

    const getRequestTypes = (filters, providedData, requestTypeData) => {
        if (filters?.requestTypes?.length) {
            const ids = filters.requestTypes.map(c => c?.value);
            // @ts-ignore
            return [...new Set(ids)].join(',');
        } else if (providedData?.requestsByJurisdiction?.length) {
            const requestData = providedData.requestsByJurisdiction.flatMap(c =>
                Object.keys(c?.requestTypes)
            );

            const ids = requestData.map(c => requestTypeData?.find(d => d.name === c)?.id);

            return [
                // @ts-ignore
                ...new Set(ids),
            ].join(',');
        }
        return '';
    };

    // providedData has no form data
    const getForms = filters => {
        if (filters?.forms?.length) {
            // @ts-ignore
            return [...new Set(filters.forms.map(c => c?.value))].join(',');
        }
        return '';
    };

    // providedData has no range data
    const getDateRange = filters => {
        if (filters?.dateRange?.length) {
            // @ts-ignore
            return [...new Set(filters.dateRange)].join(',');
        }
        return '';
    };

    // params for webservices getActionItems
    const jurisdictions = getJurisdictions(filters);
    const createdDateRange = getDateRange(filters);
    const requestType = getRequestTypes(filters, providedData, requestTypeData);
    const formId = getForms(filters);
    const status = filters?.status;

    let params = '';

    if (status) {
        params += `status=${status}&`;
    }
    if (jurisdictions) {
        params += `jurisdictions=${jurisdictions}&`;
    }
    if (createdDateRange) {
        params += `createdDateRange=${createdDateRange}&`;
    }
    if (requestType) {
        params += `requestType=${requestType}&`;
    }
    if (formId) {
        params += `formId=${formId}&`;
    }

    params = params.replace(/&$/, '');

    return params;
}

// TODO: Remove once ENABLE_DSAR_MULTI_SORTING is enabled and switch to makeSpotlightFiltersV2
export function makeSpotlightFilters(report) {
    return `spotlightMetric=${report}`;
}

export function makeSpotlightFiltersV2(report) {
    return `spotlightMetric=is:${report}`;
}
