const { EChart } = require('/b2b/common/components/Charts');

import {
    ASSESSMENTS_BY_RISK_COLORS,
    BAR_CHART_TOOLTIP,
    COMMON_OPTIONS,
    TIME_XAXIS_OPTIONS,
    VERTICAL_YAXIS_OPTIONS,
} from './constants';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';

import AssessmentsReportingContext from '../AssessmentsReporting.context';
import PropTypes from 'prop-types';
import ReportingWidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchAssessmentsReportingData } from '/b2b/assessments/routes/AssessmentsReporting/services';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'assessmentRisks';

const FIELDS = {
    noRisk: 'nonAnswered',
    lowRisk: 'low',
    mediumRisk: 'medium',
    highRisk: 'high',
};

const LABELS = {
    [FIELDS.noRisk]: 'views.AssessmentsReporting.charts.assessmentRisks.label.noAnswer',
    [FIELDS.lowRisk]: 'views.AssessmentsReporting.charts.assessmentRisks.label.lowRisk',
    [FIELDS.mediumRisk]: 'views.AssessmentsReporting.charts.assessmentRisks.label.mediumRisk',
    [FIELDS.highRisk]: 'views.AssessmentsReporting.charts.assessmentRisks.label.highRisk',
};

const staticOptions = {
    color: [...ASSESSMENTS_BY_RISK_COLORS],
    ...COMMON_OPTIONS,
    ...VERTICAL_YAXIS_OPTIONS,
    xAxis: {
        ...COMMON_OPTIONS.xAxis,
        ...TIME_XAXIS_OPTIONS.xAxis,
    },
    yAxis: {
        ...COMMON_OPTIONS.yAxis,
        ...VERTICAL_YAXIS_OPTIONS.yAxis,
    },
    tooltip: BAR_CHART_TOOLTIP,
};

const AssessmentsRisks = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data = {}, error, load, loading } = useContext(AssessmentsReportingContext);
    const reportData = data[REPORT_NAME] || [];
    const noResults = reportData.length === 0;
    const isLoading = loading || providedLoading;

    const theme = useTheme();

    const memoOption = useMemo(
        () =>
            merge({}, staticOptions, {
                grid: {
                    left: parseInt(theme.spacing(5.5), 10),
                    right: parseInt(theme.spacing(4.5), 10),
                },
                legend: {
                    data: Object.values(LABELS).map(translate),
                    left: theme.spacing(1.5),
                },
                dataset: {
                    source: reportData,
                },
                yAxis: {
                    name: translate(
                        'views.AssessmentsReporting.charts.assessmentRisks.yaxis.label'
                    ),
                    nameGap: parseInt(theme.spacing(5.5), 10),
                    show: reportData.length > 0,
                },
                series: Object.entries(LABELS).map(([field, path]) => ({
                    dimensions: [
                        { name: 'date', type: 'time' },
                        { name: field, type: 'int' },
                    ],
                    name: translate(path),
                    stack: 'x',
                    type: 'bar',
                })),
            }),
        [reportData, shouldRetranslate]
    );

    const fetchData = useBoundCallback(
        filters => fetchAssessmentsReportingData({ ...filters, reports: [REPORT_NAME] }),
        [filters]
    );

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData, filters]);

    const echartsInstance = useRef(null);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    return (
        <ReportingWidgetContainer
            disabled={noResults}
            echartsInstance={echartsInstance}
            error={error}
            title="views.AssessmentsReporting.charts.assessmentRisks.title"
            tooltip="views.AssessmentsReporting.charts.assessmentRisks.tooltip"
        >
            <EChart
                loading={isLoading}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </ReportingWidgetContainer>
    );
};

AssessmentsRisks.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

export default withAsyncData(withRetranslate(AssessmentsRisks), AssessmentsReportingContext);
