import { Alert, Divider, Typography } from '@mui/material';
import React, { Fragment } from 'react';

import Lightbulb from '/b2b/common/icons/Lightbulb';
import Locale from '../Locale';
import PropTypes from 'prop-types';
import ValidationField from './components/ValidationField';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import classNames from 'classnames';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import makeStyles from '@mui/styles/makeStyles';
import styles from './ValidationForm.styles';
import theme from '/b2b/common/theme';

const useStyles = makeStyles(styles);

const ValidationFormFields = props => {
    const {
        defaultValues = {},
        errors,
        fields,
        disabled,
        isLoading,
        helperText,
        schema,
        onFieldBlur,
        onFieldChange,
        usePopper,
        onFieldRevert,
        touched,
        values,
    } = props;
    const classes = useStyles(props);
    return (
        <Fragment>
            {fields
                ? fields.map(field => {
                      const { scheme = {}, value } = field;
                      const generatedScheme = // Functional schema must be evaluated
                          typeof scheme === 'function'
                              ? scheme(value, values, field)
                              : scheme || {};
                      const {
                          component: Component,
                          label,
                          type,
                          depends,
                          props: { canRevert, ...fieldProps } = {},
                      } = generatedScheme;
                      const { body, first = false, disabled: fieldDisabled } = fieldProps;
                      let shouldRender = type !== 'hidden';

                      if (depends) {
                          shouldRender = Object.entries(depends).every(([fieldName, expected]) => {
                              const value = get(values, fieldName, get(defaultValues, fieldName));
                              if (isArray(expected)) {
                                  return expected.includes(value);
                              }
                              if (!!expected === expected) {
                                  // Depends is a boolean, so we check for existence
                                  if (isArray(value)) {
                                      return !!value.length === expected;
                                  }
                                  if (typeof value !== 'boolean') {
                                      return !!value;
                                  }
                              }
                              return value === expected;
                          });
                      }

                      if (!shouldRender) {
                          return null;
                      }

                      switch (type) {
                          case 'alert': {
                              const colors = {
                                  error: theme.palette.text.urgent,
                                  info: theme.palette.info.main,
                                  warning: theme.palette.warning.dark,
                              };

                              const icons = {
                                  info: <Lightbulb sx={{ fontSize: 'inherit' }} />,
                                  warning: <WarningAmberIcon fontSize="inherit" />,
                              };

                              return (
                                  <Alert
                                      key={field.field}
                                      role="alert"
                                      severity={fieldProps.severity || 'warning'}
                                      variant={fieldProps.variant || 'outlined'}
                                      iconMapping={{
                                          error: icons.warning,
                                          info: icons.info,
                                          warning: icons.warning,
                                      }}
                                      sx={{ mb: 1, mt: 1, ...fieldProps.sx }}
                                  >
                                      <Typography
                                          color={colors[fieldProps.severity || 'warning']}
                                          fontSize={14}
                                      >
                                          <Locale
                                              color={colors[fieldProps.severity || 'warning']}
                                              fontSize={14}
                                              path={label}
                                          />
                                      </Typography>
                                  </Alert>
                              );
                          }
                          case 'component': {
                              return <Component key={field.field} {...fieldProps} />;
                          }
                          case 'headline': {
                              return (
                                  <div className={classes.row} key={field.field}>
                                      {!first && <Divider />}
                                      {label && (
                                          <Typography
                                              className={classNames(
                                                  classes.title,
                                                  fieldProps.className
                                              )}
                                              variant={fieldProps?.variant || 'h3'}
                                          >
                                              <Locale path={label} />
                                          </Typography>
                                      )}
                                      {body && (
                                          <Typography className={classes.subtitle} variant="body1">
                                              <Locale path={body} />
                                          </Typography>
                                      )}
                                  </div>
                              );
                          }
                      }
                      return (
                          <ValidationField
                              className={classes.field}
                              key={field.field}
                              onChange={onFieldChange}
                              onRevert={
                                  canRevert && touched && touched[`${field}`]
                                      ? onFieldRevert
                                      : undefined
                              }
                              disabled={fieldDisabled}
                              usePopper={usePopper}
                              {...field}
                          />
                      );
                  })
                : Object.entries(schema).map(([field, scheme]) => {
                      const value = get(values, field, get(defaultValues, field));
                      const generatedScheme = // Functional schema must be evaluated
                          typeof scheme === 'function'
                              ? scheme(value, values, field)
                              : scheme || {};
                      const {
                          component: Component,
                          label,
                          type,
                          depends,
                          props: { canRevert, ...schemeProps } = {},
                          presence,
                      } = generatedScheme;

                      const { body, first = false, disabled: fieldDisabled } = schemeProps;
                      let shouldRender = type !== 'hidden';

                      if (depends) {
                          shouldRender = Object.entries(depends).every(([fieldName, expected]) => {
                              const dependsOnValue = get(
                                  values,
                                  fieldName,
                                  get(defaultValues, fieldName)
                              );
                              if (isArray(expected)) {
                                  return expected.includes(value);
                              }
                              if (!!expected === expected) {
                                  // Depends is a boolean, so we check for existence
                                  if (isArray(dependsOnValue)) {
                                      return !!dependsOnValue.length === expected;
                                  }
                                  if (typeof dependsOnValue !== 'boolean') {
                                      return !!dependsOnValue;
                                  }
                              }
                              return dependsOnValue === expected;
                          });
                      }

                      if (!shouldRender) {
                          return null;
                      }

                      const isRequired = presence && presence?.allowEmpty === false ? true : false;
                      const error = get(errors, field, '');

                      const fieldProps = {
                          ...schemeProps,
                          field,
                          helperText: get(helperText, field),
                          onBlur: onFieldBlur,
                          onChange: onFieldChange,
                          onRevert:
                              canRevert && touched && touched[`${field}`]
                                  ? onFieldRevert
                                  : undefined,
                          disabled: disabled || fieldDisabled || isLoading,
                          scheme: generatedScheme,
                          value,
                          error: isArray(error) ? error.join('\n') : error,
                          required: isRequired,
                      };

                      switch (type) {
                          case 'alert': {
                              const colors = {
                                  error: theme.palette.text.urgent,
                                  info: theme.palette.info.main,
                                  warning: theme.palette.warning.dark,
                              };

                              const icons = {
                                  info: <Lightbulb sx={{ fontSize: 'inherit' }} />,
                                  warning: <WarningAmberIcon fontSize="inherit" />,
                              };

                              return (
                                  <Alert
                                      key={field}
                                      role="alert"
                                      severity={fieldProps.severity || 'warning'}
                                      variant={fieldProps.variant || 'outlined'}
                                      iconMapping={{
                                          error: icons.warning,
                                          info: icons.info,
                                          warning: icons.warning,
                                      }}
                                      sx={{ mb: 1, mt: 1, ...fieldProps.sx }}
                                  >
                                      <Typography
                                          color={colors[fieldProps.severity || 'warning']}
                                          fontSize={14}
                                      >
                                          <Locale
                                              color={colors[fieldProps.severity || 'warning']}
                                              fontSize={14}
                                              path={label}
                                          />
                                      </Typography>
                                  </Alert>
                              );
                          }
                          case 'component': {
                              return <Component key={field} {...fieldProps} />;
                          }
                          case 'headline': {
                              return (
                                  <div className={classes.row} key={field}>
                                      {!first && <Divider />}
                                      {label && (
                                          <Typography
                                              className={classNames(
                                                  classes.title,
                                                  fieldProps.className
                                              )}
                                              variant={fieldProps?.variant || 'h3'}
                                          >
                                              <Locale path={label} />
                                          </Typography>
                                      )}
                                      {body && (
                                          <Typography className={classes.subtitle} variant="body1">
                                              <Locale path={body} />
                                          </Typography>
                                      )}
                                  </div>
                              );
                          }
                      }

                      return (
                          <ValidationField
                              className={classes.field}
                              key={field}
                              usePopper={usePopper}
                              {...fieldProps}
                          />
                      );
                  })}
        </Fragment>
    );
};

ValidationFormFields.propTypes = {
    defaultValues: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    fields: PropTypes.array,
    isLoading: PropTypes.bool,
    onFieldBlur: PropTypes.func,
    onFieldChange: PropTypes.func,
    onFieldRevert: PropTypes.func,
    helperText: PropTypes.object,
    schema: PropTypes.object,
    usePopper: PropTypes.bool,
    touched: PropTypes.object,
    values: PropTypes.object,
};

export default ValidationFormFields;
