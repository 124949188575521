import Component from './ProductTodos.component';
import { connect } from 'react-redux';
import fromState from '/b2b/common/state/selectors';
import { updateTodoPreferences } from '/b2b/dashboard/views/Dashboard.commands';

const mapStateToProps = state => ({
    preferences: fromState.Dashboard.getPreferences(state),
});

const mapDispatchToProps = {
    updateTodoPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
