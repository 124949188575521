import { formatDataStoreAgent } from '/b2b/dataStores/format';
import { formatUser } from '/b2b/users/format';
import { htmlDecode } from '/b2b/common/helpers/String';
/* eslint-disable camelcase */
import { makeResultsFormatter } from '/b2b/common/services/helpers';

/** @type {(data: Partial<PrivacyProtocolConfig>) => PrivacyProtocolConfig} */
export const formatPrivacyProtocol = ({ description, name, ...data } = {}) => {
    // Make sure to sort variants by jurisdiction
    const { variants = [], ...remain } = data;
    variants.sort((a, b) => a?.jurisdiction?.localeCompare(b?.jurisdiction));
    return {
        ...remain,
        // React automatically encodes HTML under the hood, so we need to provide a decoded string
        name: name ? htmlDecode(name) : '',
        description: description ? htmlDecode(description) : '',
        variants,
    };
};

export const formatPrivacyProtocolCollection = data => {
    return data;
};

export const formatUcConfig = ({ description, name, ...data } = {}) => {
    return {
        platform: 'hosted',
        ...data,
        // React automatically encodes HTML under the hood, so we need to provide a decoded string
        name: name ? htmlDecode(name) : '',
        description: description ? htmlDecode(description) : '',
    };
};

export const formatUcConfigConsent = data => {
    const { hashedSubjectId, ...remain } = data;
    return {
        ...remain,
        hashedSubjectId,
        identifier: hashedSubjectId,
    };
};
export const formatUcConfigConsentResults = makeResultsFormatter(formatUcConfigConsent);

export const formatUcConfigConsentRecord = record => {
    const { actions, conflicts, lastUpdateDate, region, version } = record;

    return {
        ...record,
        actions,
        conflicts,
        jurisdiction: region,
        updated: lastUpdateDate,
        version,
    };
};

export const formatUcConfigConsentDetails = data => {
    const { activeConsent = {}, protocols: protocolLookup = {}, records = [], ...remain } = data;
    const protocols = Object.fromEntries(
        Object.entries(protocolLookup).map(([id, protocol]) => [
            id,
            formatPrivacyProtocol(protocol),
        ])
    );

    return {
        ...remain,
        activeConsent,
        protocols,
        records: records.map(formatUcConfigConsentRecord),
    };
};

export const formatUcConfigIntegrationOperation = data => {
    const { name, description, operationGroupId } = data;
    return {
        name,
        description,
        operationGroupId,
    };
};

export const formatUcConfigIntegration = data => {
    const {
        agent_id,
        agent_name,
        agent_description,
        agent_version,
        agent_schema,
        agent_on_prem,
        agent_created,
        agent_updated,
        agent_type,
        agent: providedAgent,

        provider_id,
        provider_name,
        provider_description,
        provider_type,
        provider: providedProvider,

        customer_id,
        customerId = customer_id,

        connection_id,
        connectionId = connection_id,
        connection_description,
        description = connection_description,
        connection_name,
        name = connection_name,
        connection_status,
        status = connection_status,
        connection_status_message,
        statusMessage = connection_status_message,
        connection_credentials,
        credentials = connection_credentials,
        connection_created,
        created = connection_created,
        connection_updated,
        updated = connection_updated,
        connection_owners = [],
        operations: providedOperations = [],
        owners: providedOwners = connection_owners,
    } = data;

    const provider = {
        providerId: provider_id,
        name: provider_name,
        description: provider_description,
        type: provider_type,
        ...providedProvider,
    };

    const agent = {
        agentId: agent_id,
        name: agent_name,
        description: agent_description,
        type: agent_type,
        version: agent_version,
        schema: agent_schema,
        onPrem: agent_on_prem,
        created: agent_created,
        updated: agent_updated,
        provider,
        ...providedAgent,
    };

    const operations = providedOperations.map(formatUcConfigIntegrationOperation);
    const owners = providedOwners.map(formatUser);
    const primaryOwner = owners.find(({ is_primary }) => is_primary) || owners[0];

    return {
        agent: formatDataStoreAgent(agent),
        customer: customerId,
        connectionId,
        created,
        credentials,
        description,
        name,
        owners,
        primaryOwner: formatUser(primaryOwner || {}),
        status,
        statusMessage,
        operations,
        updated,
    };
};

export const formatUcConfigIntegrationResults = makeResultsFormatter(formatUcConfigIntegration);
