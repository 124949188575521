import {
    STATUS_ERROR,
    STATUS_IN_PROGRESS,
    STATUS_QUEUED,
    STATUS_SYNCED,
} from '/b2b/dataStores/constants';

import { APPLICATION_DISCOVERY_TEMPLATE } from '/b2b/assessments/constants';
import DataStoreIcon from '@mui/icons-material/Storage';
import VendorIcon from '@mui/icons-material/Storefront';
import palette from '/b2b/common/theme/palette';

export const SOURCE_TYPE_SSO = 'SSO_CONNECTION';
export const SOURCE_TYPE_ASSESSMENT = 'ASSESSMENT';

export const SOURCE_TYPE_CDP = 'CDP_CONNECTION';

export const SOURCE_TYPE_CLOUD_DB = 'CLOUD_DB_CONNECTION';

export const SOURCE_TYPES = {
    [SOURCE_TYPE_SSO]: 'dataSources.sources.types.ssoConnection.name',
    [SOURCE_TYPE_ASSESSMENT]: 'dataSources.sources.types.assessment.name',
    [SOURCE_TYPE_CDP]: 'dataSources.sources.types.cdpConnection.name',
    [SOURCE_TYPE_CLOUD_DB]: 'dataSources.sources.types.cloudDbConnection.name',
};

export const ASSESSMENT_SOURCES = {
    [APPLICATION_DISCOVERY_TEMPLATE]: 'views.Assessment.templates.applicationDiscovery.label',
};

export const SOURCE_CONNECTION_STATUS = {
    [STATUS_ERROR]: 'dataSources.status.error',
    [STATUS_IN_PROGRESS]: 'dataSources.status.inProgress',
    [STATUS_SYNCED]: 'dataSources.status.synced',
    [STATUS_QUEUED]: 'dataSources.status.queued',
};

export const MANAGEMENT_STATUS_NEEDS_REVIEW = 'NEW';
export const MANAGEMENT_STATUS_LINKED = 'LINKED';
export const MANAGEMENT_STATUS_IGNORED = 'IGNORED';

export const MANAGEMENT_STATUS = {
    [MANAGEMENT_STATUS_NEEDS_REVIEW]: 'dataSources.managementStatus.needsReview',
    [MANAGEMENT_STATUS_LINKED]: 'dataSources.managementStatus.linked',
    [MANAGEMENT_STATUS_IGNORED]: 'dataSources.managementStatus.ignored',
};

export const APP_STATUS_ACTIVE = 'ACTIVE';
export const APP_STATUS_INACTIVE = 'INACTIVE';
export const APP_STATUS_MIXED = 'MIXED';

export const APP_STATUS = {
    [APP_STATUS_ACTIVE]: 'common.active',
    [APP_STATUS_INACTIVE]: 'common.inactive',
    [APP_STATUS_MIXED]: 'common.mixed',
};

export const OKTA_LOGO_URL = './assets/images/logos/sso/okta.svg';
export const ONELOGIN_LOGO_URL = './assets/images/logos/sso/onelogin.svg';
export const MICROSOFT_LOGO_URL = './assets/images/logos/sso/microsoft.svg';

export const SSO_PROVIDER_OKTA = 'Okta';
export const SSO_PROVIDER_OKTA_DEMO_AGENT = 'Okta Demo Agent';
export const SSO_PROVIDER_ONELOGIN = 'OneLogin';
export const SSO_PROVIDER_ONELOGIN_DEMO_AGENT = 'OneLogin Demo Agent';
export const SSO_PROVIDER_MICROSOFT = 'Microsoft';

export const SSO_CONNECTION_LOGOS = {
    [SSO_PROVIDER_OKTA]: OKTA_LOGO_URL,
    [SSO_PROVIDER_OKTA_DEMO_AGENT]: OKTA_LOGO_URL,
    [SSO_PROVIDER_ONELOGIN]: ONELOGIN_LOGO_URL,
    [SSO_PROVIDER_ONELOGIN_DEMO_AGENT]: ONELOGIN_LOGO_URL,
    [SSO_PROVIDER_MICROSOFT]: MICROSOFT_LOGO_URL,
};

export const RUDDERSTACK_LOGO_URL = './assets/images/logos/cdp/rudderstack.svg';

export const SEGMENT_LOGO_URL = './assets/images/logos/cdp/segment.svg';

export const CDP_PROVIDER_RUDDERSTACK = 'RudderStack';
export const CDP_PROVIDER_SEGMENT = 'Segment';
export const CDP_PROVIDER_SEGMENT_DEMO = 'SegmentDemo';

export const CLOUD_DB_PROVIDER_AURORA_POSTGRES = 'Aurora PostgreSQL';
export const CLOUD_DB_PROVIDER_AURORA_MYSQL = 'Aurora MySQL';

export const CDP = 'CDP';
export const SOURCE_DISCOVERY = 'SOURCE_DISCOVERY';
export const CLOUD_DB_SCAN = 'CLOUD_DB_SCAN';
export const CLOUD_DB = 'cloud_db';
export const SSO_SCAN = 'SSO_SCAN';
export const DATA_MAPPING = 'DATA_MAPPING';

export const CDP_SCAN = 'CDP_SCAN';

export const CREDENTIALS_CHECK = 'CREDENTIALS_CHECK';

export const CDP_CONNECTION_LOGOS = {
    [CDP_PROVIDER_RUDDERSTACK]: RUDDERSTACK_LOGO_URL,
    [CDP_PROVIDER_SEGMENT]: SEGMENT_LOGO_URL,
    [CDP_PROVIDER_SEGMENT_DEMO]: SEGMENT_LOGO_URL,
};

export const SOURCE_ID = 'discoveredSourceId';
export const APP_ID = 'appId';
export const PRODUCT_ID = 'productId';

export const EVENTS = {
    DATA_MAPPING_SOURCES_TAB: 'DataMappingSourcesTab',
    DATA_MAPPING_APPLICATIONS_TAB: 'DataMappingApplicationsTab',
    DATA_MAPPING_APPLICATIONS_SEARCH: 'DataMappingApplicationsSearch',
    DATA_MAPPING_APPLICATIONS_FILTER: 'DataMappingApplicationsFilter',
    DATA_MAPPING_APPLICATIONS_SORT: 'DataMappingApplicationsSort',
    DATA_MAPPING_MANAGE_APP_OPEN: 'DataMappingManageAppOpen',
    DATA_MAPPING_MANAGE_APP_CLOSE: 'DataMappingManageAppClose',
    DATA_MAPPING_MANAGE_APP_CONFIRM: 'DataMappingManageAppConfirm',
    DATA_MAPPING_MANAGE_APP_CREATE_NEW_DATA_STORE: 'DataMappingManageAppCreateNewDataStore',
    DATA_MAPPING_MANAGE_APP_LINK_DATA_STORE: 'DataMappingManageAppLinkDataStore',
    DATA_MAPPING_MANAGE_APP_IGNORE_APP: 'DataMappingManageAppIgnoreApp',
    DATA_MAPPING_SNACK_TAKE_ME_TO_DATA_STORE: 'DataMappingSnackTakeMeToDataStore',
};

export const DEFAULT_MAP_ID = 'default';

export const DATA_MAP_PREFIX = 'data-map-';
export const SMART_COMPONENT_TYPE = 'smart';

export const DATA_MAP_CONTAINER_ID = `${DATA_MAP_PREFIX}container`;
export const DATA_MAP_SEARCH_ID = `${DATA_MAP_PREFIX}search`;

export const DATA_MAP_EDGE_CLASS = `react-flow__edge`;
export const DATA_MAP_NODE_CLASS = `react-flow__node`;

export const SIDEBAR_ADD_NODE_BUTTON_CLASS = `${DATA_MAP_PREFIX}add-node-button`;

export const DATA_MAP_ARIA_ID = `data-map-desc-1`;
export const NODE_ARIA_ID = `react-flow__node-desc-1`;
export const EDGE_ARIA_ID = `react-flow__edge-desc-1`;
export const SOURCE_HANDLE_ARIA_ID = `react-flow__node-source-handle-desc-1`;
export const TARGET_HANDLE_ARIA_ID = `react-flow__node-target-handle-desc-1`;

export const EDGE_TYPES = {
    DEFAULT: 'DEFAULT',
    DISABLED: 'DISABLED',
    SELECTED: 'SELECTED',
};

export const EDGE_MARKER_IDS = {
    [EDGE_TYPES.DEFAULT]: `${DATA_MAP_PREFIX}edge-marker-default`,
    [EDGE_TYPES.DISABLED]: `${DATA_MAP_PREFIX}edge-marker-disabled`,
    [EDGE_TYPES.SELECTED]: `${DATA_MAP_PREFIX}edge-marker-selected`,
};

export const EDGE_COLORS = {
    [EDGE_TYPES.DEFAULT]: palette.common.grey[500],
    [EDGE_TYPES.DISABLED]: palette.common.grey[300],
    [EDGE_TYPES.SELECTED]: palette.primary.main,
};

export const EDGE_MARKERS = {
    [EDGE_TYPES.DEFAULT]: {
        color: EDGE_COLORS[EDGE_TYPES.DEFAULT],
        id: EDGE_MARKER_IDS[EDGE_TYPES.DEFAULT],
        type: EDGE_TYPES.DEFAULT,
    },
    [EDGE_TYPES.DISABLED]: {
        color: EDGE_COLORS[EDGE_TYPES.DISABLED],
        disabled: true,
        id: EDGE_MARKER_IDS[EDGE_TYPES.DISABLED],
        type: EDGE_TYPES.DISABLED,
    },
    [EDGE_TYPES.SELECTED]: {
        color: EDGE_COLORS[EDGE_TYPES.SELECTED],
        id: EDGE_MARKER_IDS[EDGE_TYPES.SELECTED],
        type: EDGE_TYPES.SELECTED,
    },
};

export const REMOVE_EDGE_BUTTON_ID = `${DATA_MAP_PREFIX}remove-edge-button`;

export const NODE_TYPES = {
    DATA_STORE: 'DATA_STORE',
    VENDOR: 'VENDOR',
};

export const NODE_DISABLED_COLOR = palette.common.grey[300];

export const NODE_IDS = {
    [NODE_TYPES.DATA_STORE]: 'connectionId',
    [NODE_TYPES.VENDOR]: 'vendorId',
};

export const NODE_TYPE_LABELS = {
    [NODE_TYPES.DATA_STORE]: 'dataMap.nodeTypes.dataStores',
    [NODE_TYPES.VENDOR]: 'dataMap.nodeTypes.vendors',
};

export const NODE_COLORS = {
    [NODE_TYPES.DATA_STORE]: palette.primary.main,
    [NODE_TYPES.VENDOR]: palette.info.main,
};

export const NODE_ICONS = {
    [NODE_TYPES.DATA_STORE]: DataStoreIcon,
    [NODE_TYPES.VENDOR]: VendorIcon,
};

export const NODE_HORIZONTAL_SPACING = 80;
export const NODE_VERTICAL_SPACING = 80;

export const NODE_WIDTH = 325;

export const HANDLE_SIZE = 16;

export const MAP_DIRECTION = {
    RIGHT: 'RIGHT',
    DOWN: 'DOWN',
};

// keys must exist in /data-map/:mapId response node_data or resource_data objects
export const MAP_FILTERS = {
    sources: {
        key: 'sourceId',
        value: 'name',
        path: 'dataMap.filters.sources',
        sortOrder: 1,
    },
    organizations: {
        key: 'orgId',
        value: 'name',
        path: 'dataMap.filters.organizations',
        sortOrder: 2,
    },
    connectionOwner: {
        key: 'email',
        value: 'name',
        path: 'dataMap.filters.owners',
        sortOrder: 3,
    },
    location: {
        key: 'countryIso',
        value: 'countryName',
        path: 'dataMap.filters.countries',
        sortOrder: 4,
    },
    fieldClassifications: {
        key: 'classification',
        value: 'name',
        path: 'dataMap.filters.classifications',
        sortOrder: 5,
    },
    labels: {
        key: 'labelId',
        value: 'name',
        path: 'dataMap.filters.labels',
        sortOrder: 6,
    },
    assessments: {
        key: 'assessmentId',
        value: 'name',
        path: 'dataMap.filters.assessments',
        sortOrder: 7,
    },
};

export const EMDASH = '—';

export const ANIMATION_DURATION = '300ms';
export const DISABLED_OPACITY = 0.5;
export const ENABLED_OPACITY = 0.9;
export const FIT_VIEW_DURATION = 500;
export const FONT_SIZE = '0.625rem';
export const MIN_ZOOM = 0.1;
