import { DOMAIN } from './RequestList.constants';
import get from 'lodash/get';

export const getStore = state => get(state, DOMAIN) || {};

export const getError = state => getStore(state).error;
export const getColumnOrder = state => getStore(state).columnOrder || [];
export const getColumnVisibility = state => getStore(state).columnVisibility || {};
export const getColumnWidths = state => getStore(state).columnWidths || {};
export const getPinnedColumns = state => getStore(state).pinnedColumns || {};
export const isLoading = state => getStore(state).loading;
