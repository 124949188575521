export const EVENTS = {
    BULK_COMPLETE_REQUEST: 'BulkCompleteRequest',
    BULK_REJECT_REQUEST: 'BulkRejectRequest',
    COMPLETE_REQUEST: 'CompleteRequest',
    REJECT_REQUEST: 'RejectRequest',
    SEND_MESSAGE: 'SendMessage',
    VERIFY_IDENTITY: 'VerifyIdentity',
    REQUESTS_FILTER: 'RequestsFilter',
    OPEN_ACTIVITY_LOG: 'OpenActivityLog',
    OPEN_INFO: 'OpenInfo',
    OPEN_STATUS: 'OpenStatus',
    IMPORT_CSV: 'ImportDSARCSV',
};

export const DSAR_STATUS_KEYS = {
    PENDING_EMAIL_VERIFICATION: 'PENDING_EMAIL_VERIFICATION',
    PENDING_IDENTITY_VERIFICATION: 'PENDING_IDENTITY_VERIFICATION',
    IN_PROGRESS: 'IN_PROGRESS',
    IN_REVIEW: 'IN_REVIEW',
    COMPLETED: 'COMPLETED',
    REJECTED: 'REJECTED',
    REJECTED_AUTO: 'REJECTED_AUTO',
};

export const DSAR_STATUS = {
    [DSAR_STATUS_KEYS.PENDING_EMAIL_VERIFICATION]: 'dsar.status.pendingEmailVerification',
    [DSAR_STATUS_KEYS.PENDING_IDENTITY_VERIFICATION]: 'dsar.status.pendingIdentityVerification',
    [DSAR_STATUS_KEYS.IN_PROGRESS]: 'dsar.status.inProgress',
    [DSAR_STATUS_KEYS.IN_REVIEW]: 'dsar.status.inReview',
    [DSAR_STATUS_KEYS.COMPLETED]: 'dsar.status.completed',
    [DSAR_STATUS_KEYS.REJECTED]: 'dsar.status.rejected',
    [DSAR_STATUS_KEYS.REJECTED_AUTO]: 'dsar.status.rejectedAuto',
};

export const DSAR_REJECTED_STATUSES = [DSAR_STATUS_KEYS.REJECTED, DSAR_STATUS_KEYS.REJECTED_AUTO];

export const DSAR_FINISHED_STATUSES = [DSAR_STATUS_KEYS.COMPLETED, ...DSAR_REJECTED_STATUSES];

export const DSAR_SUBSTATUS_KEYS = {
    ALL_ACTION_ITEMS_COMPLETE: 'ALL_ACTION_ITEMS_COMPLETE',
    NO_RESULTS_FOUND: 'NO_RESULTS_FOUND',
    NO_ACTION_ITEMS_GENERATED: 'NO_ACTION_ITEMS_GENERATED',
    ACTION_ITEMS_IN_PROGRESS: 'ACTION_ITEMS_IN_PROGRESS',
    EMAIL_VERIFIED: 'EMAIL_VERIFIED',
    EMAIL_VERIFICATION_PENDING: 'EMAIL_VERIFICATION_PENDING',
};

export const DSAR_SUBSTATUS = {
    [DSAR_SUBSTATUS_KEYS.ALL_ACTION_ITEMS_COMPLETE]: 'dsar.requests.status.allActionItemsComplete',
    [DSAR_SUBSTATUS_KEYS.NO_RESULTS_FOUND]: 'common.noResultsFound',
    [DSAR_SUBSTATUS_KEYS.NO_ACTION_ITEMS_GENERATED]: 'dsar.requests.status.noActionItemsGenerated',
    [DSAR_SUBSTATUS_KEYS.ACTION_ITEMS_IN_PROGRESS]: 'dsar.requests.status.actionItemsRemaining',
    [DSAR_SUBSTATUS_KEYS.EMAIL_VERIFIED]: 'dsar.requests.status.pendingIdentityVerification',
    [DSAR_SUBSTATUS_KEYS.EMAIL_VERIFICATION_PENDING]:
        'dsar.requests.status.pendingEmailVerification',
};

export const DSAR_SUBSTATUS_FILTERS = {
    ...DSAR_SUBSTATUS,
    [DSAR_STATUS_KEYS.REJECTED]: DSAR_STATUS[DSAR_STATUS_KEYS.REJECTED],
    [DSAR_STATUS_KEYS.COMPLETED]: DSAR_STATUS[DSAR_STATUS_KEYS.COMPLETED],
    [DSAR_SUBSTATUS_KEYS.ACTION_ITEMS_IN_PROGRESS]:
        'dsar.requests.status.actionItemsRemainingWithoutCount',
};

export const DSAR_NEXT_STEP_LABELS = {
    [DSAR_STATUS_KEYS.PENDING_EMAIL_VERIFICATION]: 'dsar.nextStepLabel.pendingEmailVerification',
    [DSAR_STATUS_KEYS.PENDING_IDENTITY_VERIFICATION]:
        'dsar.nextStepLabel.pendingIdentityVerification',
    [DSAR_STATUS_KEYS.IN_PROGRESS]: 'dsar.nextStepLabel.readyForReview',
    [DSAR_STATUS_KEYS.IN_REVIEW]: 'dsar.nextStepLabel.readyForCompletion',
};

export const DSAR_DEFAULT_COMPLETE_MESSAGE = `We've completed your request. If there were files associated with this request, they can be found in the secure messaging portal.`;
export const DSAR_DEFAULT_REJECTION_MESSAGE = `We're sorry, but we're unable to process your request for the following reason:`;
export const DSAR_OTHER_REJECTION_MESSAGE = `We're sorry, but we're unable to process your request.`;
export const DSAR_REJECTION_REASONS = {
    OTHER: 'dsar.rejectionReason.other',
};
