import { fetchConsentStats, fetchConsentTrafficStats } from '../consent/services';

import API from '@aws-amplify/api';
import { allSettled } from '../common/services/helpers';
import { apiName } from '@osano-b2b';
import { fetchDocumentChangesCount } from '../documents/services';
import { fetchFollowingLitigationCount } from '../litigation/services';
import { fetchFollowingVendorsCount } from '../vendors/services';
import { formatDashboardTodos } from './format';
import { handleError } from '../common/services/helpers';

const friendlyMessage = original => {
    switch (original) {
        case 'call not allowed by plan':
            return { upgrade: true, fakeValue: Math.floor(Math.random() * 100 - 1) };
        default:
            return '';
    }
};

const makeStat = (settlement, title) => {
    const { state, value, reason } = settlement;
    const { message = reason } = reason || {};
    return {
        title,
        value: state === 'fulfilled' ? value : friendlyMessage(message) || 0,
    };
};

// TODO: Remove once ENABLE_DASHBOARD_V2 feature flag is removed
export const fetchDashboard = () => {
    return allSettled([
        fetchFollowingVendorsCount(),
        fetchFollowingLitigationCount(),
        fetchDocumentChangesCount(),
        fetchConsentStats(),
        fetchConsentTrafficStats(),
    ]).then(resultSet => {
        const [vendors, litigaton, documentChanges, consent, traffic] = resultSet;
        const stats = [
            makeStat(vendors, 'layouts.Dashboard.stats.VendorsMonitored.label'),
            makeStat(litigaton, 'layouts.Dashboard.stats.LawsuitsFiled.label'),
            makeStat(documentChanges, 'layouts.Dashboard.stats.DocChanges.label'),
        ];
        return {
            traffic: traffic.value || {},
            consent: consent.value || {},
            stats,
        };
    });
};

export const fetchProductTodos = product =>
    API.get(apiName, `/${product}/to-do`).catch(handleError);

export const fetchDashboardTodos = ({
    canViewAssessments = false,
    canViewCookieConsent = false,
    canViewDataMapping = false,
    canViewSubjectRights = false,
    canViewUnifiedConsent = false,
    canViewVendorMonitoring = false,
}) =>
    allSettled([
        canViewAssessments && fetchProductTodos('assessment-management'),
        canViewCookieConsent && fetchProductTodos('consentmanager'),
        canViewDataMapping && fetchProductTodos('data-mapping'),
        canViewSubjectRights && fetchProductTodos('dsar'),
        canViewUnifiedConsent && fetchProductTodos('uc'),
        canViewVendorMonitoring && fetchProductTodos('vendor-management'),
    ])
        .then(
            ([
                assessments,
                cookieConsent,
                dataMapping,
                subjectRights,
                unifiedConsent,
                vendorMonitoring,
            ]) => ({
                assessments: assessments?.value || {},
                cookieConsent: cookieConsent?.value || {},
                dataMapping: dataMapping?.value || {},
                subjectRights: subjectRights?.value || {},
                unifiedConsent: unifiedConsent?.value || {},
                vendorMonitoring: vendorMonitoring?.value || {},
            })
        )
        .then(formatDashboardTodos);
