import { types as ActionTypes, asyncEvents as AsyncEvents } from './RequestList.actions';
import {
    errorReducer,
    genericReducer,
    loadingReducer,
    passthroughReducer,
} from '/b2b/common/helpers/ReduxHelpers';

import { types as AuthActionTypes } from '/b2b/authentication/state/Auth.actions';
import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';

export const initialState = {
    loading: false,
    error: null,
    columnOrder: [],
    columnVisibility: {},
    columnWidths: {},
    pinnedColumns: {},
};

export default reduceReducers(
    initialState,
    combineReducers({
        columnOrder: passthroughReducer(initialState.columnOrder),
        columnVisibility: passthroughReducer(initialState.columnVisibility),
        columnWidths: passthroughReducer(initialState.columnWidths),
        loading: loadingReducer(
            initialState.loading,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
        pinnedColumns: passthroughReducer(initialState.pinnedColumns),
        error: errorReducer(
            initialState.error,
            Object.keys(AsyncEvents).map(key => ActionTypes[key])
        ),
    }),
    genericReducer(initialState, {
        [ActionTypes.reset]: () => initialState,
        [ActionTypes.setColumnOrder]: (state, { columnOrder = {}, orderedColumns = [] }) => {
            const { field, targetIndex } = columnOrder;
            const defaultColumnOrdering = orderedColumns.map(column => column.field);

            const mergedColumnOrder = [
                ...new Set([...state.columnOrder, ...defaultColumnOrdering]),
            ];

            //Remove the column we're moving from the current index and insert it at the target index
            const currentIndex = mergedColumnOrder.indexOf(field);
            if (currentIndex !== -1) {
                mergedColumnOrder.splice(currentIndex, 1);
            }
            mergedColumnOrder.splice(targetIndex - 1, 0, field);
            return {
                ...state,
                columnOrder: mergedColumnOrder,
            };
        },
        [ActionTypes.setColumnVisibility]: (state, columnVisibility) => ({
            ...state,
            columnVisibility,
        }),
        [ActionTypes.setColumnWidths]: (state, params) => {
            const { colDef: { field } = {}, width } = params || {};
            return {
                ...state,
                columnWidths: {
                    ...state.columnWidths,
                    [field]: width,
                },
            };
        },
        [AuthActionTypes.logout]: () => initialState,
        [ActionTypes.setPinnedColumns]: (state, updatedPinnedColumns) => {
            const { left = [] } = updatedPinnedColumns || {};

            // Unpin the check column if it's the only column pinned
            if (left.length === 1 && left.includes('__check__')) {
                left.shift();
            }

            // If we're pinning a column to the left then add the check column to the beginning
            if (left.length === 1) {
                left.unshift('__check__');
            }

            return {
                ...state,
                pinnedColumns: updatedPinnedColumns,
            };
        },
    })
);
