const { EChart } = require('/b2b/common/components/Charts');

import {
    BAR_CHART_TOOLTIP,
    COMMON_OPTIONS,
    TIME_XAXIS_OPTIONS,
    VERTICAL_YAXIS_OPTIONS,
} from '/b2b/dsarReporting/constants';
import { DSAR_STATUS, DSAR_STATUS_KEYS } from '/b2b/dsarRequests/constants';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { makeCSV, makeRequestFilters, makeRequestFiltersV2 } from '../helpers';

import AuthenticatedPaths from '/b2b/routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import { ENABLE_DSAR_MULTI_SORTING } from '/b2b/common/components/FeatureFlag';
import PropTypes from 'prop-types';
import ReportingContext from '../Reporting.context';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchDSARReportingData } from '/b2b/dsarReporting/services';
import { isFeatureEnabled } from '/b2b/common/helpers/FeatureFlags';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'verifiedRequests';

const FIELDS = {
    completedByInternalDueDate: 'completedByInternalDueDate',
    completedByRegulatoryDueDate: 'completedByRegulatoryDueDate',
    completedLateRegulatory: 'completedLateRegulatory',
    open: 'open',
};

const LABELS = {
    [FIELDS.completedByInternalDueDate]:
        'dsar.reporting.charts.verifiedRequests.label.completedByInternalDueDate',

    [FIELDS.completedByRegulatoryDueDate]:
        'dsar.reporting.charts.verifiedRequests.label.completedByRegulatoryDueDate',
    [FIELDS.completedLateRegulatory]:
        'dsar.reporting.charts.verifiedRequests.label.completedLateRegulatory',
    [FIELDS.open]: 'dsar.reporting.charts.verifiedRequests.label.open',
};

const staticOptions = {
    ...COMMON_OPTIONS,
    ...VERTICAL_YAXIS_OPTIONS,
    xAxis: {
        ...COMMON_OPTIONS.xAxis,
        ...TIME_XAXIS_OPTIONS.xAxis,
    },
    yAxis: {
        ...COMMON_OPTIONS.yAxis,
        ...VERTICAL_YAXIS_OPTIONS.yAxis,
    },
    tooltip: BAR_CHART_TOOLTIP,
};

const VerifiedRequests = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data = {}, error, load, loading } = useContext(ReportingContext);
    const reportData = data[REPORT_NAME] || [];
    const noResults = reportData.length === 0;
    const isLoading = loading || providedLoading;

    const theme = useTheme();

    const memoOption = useMemo(
        () =>
            merge({}, staticOptions, {
                grid: {
                    left: parseInt(theme.spacing(5.5), 10),
                    right: parseInt(theme.spacing(4.5), 10),
                },
                legend: {
                    data: Object.values(LABELS).map(translate),
                    left: theme.spacing(1.5),
                },
                dataset: {
                    source: reportData,
                },
                yAxis: {
                    name: translate('dsar.reporting.common.numberOfRequests'),
                    nameGap: parseInt(theme.spacing(5.5), 10),
                    show: reportData.length > 0,
                },
                series: Object.entries(LABELS).map(([field, path]) => ({
                    dimensions: [
                        { name: 'date', type: 'time' },
                        { name: field, type: 'int' },
                    ],
                    name: translate(path),
                    stack: 'x',
                    type: 'bar',
                })),
            }),
        [reportData, shouldRetranslate]
    );

    const fetchData = useBoundCallback(
        filters => fetchDSARReportingData({ ...filters, reports: [REPORT_NAME] }),
        [filters]
    );

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData, filters]);

    const echartsInstance = useRef(null);
    const helpers = useMemo(
        () => ({
            makeCSV: () => makeCSV(reportData, LABELS),
            name: 'verified-requests',
        }),
        [reportData]
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    const enableMutliFilterDrilldown = useSelector(state =>
        isFeatureEnabled(state, ENABLE_DSAR_MULTI_SORTING)
    );

    const onDrilldownClick = useBoundCallback(
        drilldownFilters => {
            const makeFilters = enableMutliFilterDrilldown
                ? makeRequestFiltersV2
                : makeRequestFilters;
            window.open(
                `${AuthenticatedPaths.DSAR_REQUESTS}?${makeFilters(drilldownFilters)}`,
                '_blank'
            );
        },
        [
            [
                {
                    ...filters,
                    requestStatus: Object.keys(DSAR_STATUS)
                        // all verified requests i.e. all statuses except email verification, rejected automatically
                        // the Redshift query is including these statuses: 'PENDING_IDENTITY_VERIFICATION', 'IN_PROGRESS', 'IN_REVIEW', 'COMPLETED', 'REJECTED'
                        .filter(
                            ds =>
                                ds !== DSAR_STATUS_KEYS.PENDING_EMAIL_VERIFICATION &&
                                ds !== DSAR_STATUS_KEYS.REJECTED_AUTO
                        )
                        .join(','),
                },
                providedData,
            ],
            enableMutliFilterDrilldown,
        ]
    );
    const downloadFormats = [
        'csv',
        'png',
        { label: 'dsar.reporting.charts.requests.list', onClick: onDrilldownClick },
    ];

    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={downloadFormats}
            echartsInstance={echartsInstance}
            error={error}
            title="dsar.reporting.charts.verifiedRequests.title"
            tooltip="dsar.reporting.charts.verifiedRequests.tooltip"
        >
            <EChart
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </WidgetContainer>
    );
};

VerifiedRequests.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

export default withAsyncData(withRetranslate(VerifiedRequests), ReportingContext);
