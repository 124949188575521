import React from 'react';

export const ReportingContext = React.createContext({
    loading: false,
    error: null,
    initialLoad: true,
    load: () => Promise.reject(new Error('Not implemented')),
    data: {},
});

export default ReportingContext;
