const { EChart, LegendIcon } = require('/b2b/common/components/Charts');

import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';

import DashboardContext from '../Dashboard.context';
import PropTypes from 'prop-types';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchUcReportsDashboard } from '/b2b/uc/services';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { parseDate } from '/b2b/common/helpers/Date';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'consentsByUser';

const staticOptions = {
    legend: {
        icon: LegendIcon,
        orient: 'horizontal',
        top: 'top',
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'none',
        },
        formatter(/** @type {import('echarts/types/dist/shared').CallbackDataParams[]} */ params) {
            const { axisValueLabel } = params[0];
            const date = new Date(parseDate(axisValueLabel));
            const formatOptions = {
                timeZone: 'UTC',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            };
            // eslint-disable-next-line xss/no-mixed-html
            return `<b>${date.toLocaleString('default', formatOptions)}</b><hr style="margin:0.25em 0"/>${params
                .map(({ dimensionNames, marker, seriesName, value }) => {
                    const count = value[dimensionNames[1]];
                    return `${marker} ${translate(seriesName)}: ${count}`;
                })
                .join('<br>')}`;
        },
    },
    grid: {
        containLabel: true,
    },
    xAxis: {
        axisLine: { onZero: false, show: false },
        axisTick: { show: false },
        minorTick: { show: false },
        splitLine: { show: false },
        type: 'time',
        axisLabel: {
            interval: 0,
            formatter: {
                hour: '',
                day: '{MMM}, {dd}',
                month: '{MMM}, {yyyy}',
                year: '{yyyy}',
            },
        },
    },
    yAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { align: 'right', margin: 0, showMinLabel: false },
        nameRotate: 90,
        nameLocation: 'center',
        splitNumber: 2,
        type: 'value',
        nameTextStyle: {
            verticalAlign: 'middle',
        },
        min(value) {
            return Math.max(0, value.min - 10);
        },
    },
    series: [
        {
            name: 'uc.label.known',
            type: 'bar',
            barGap: 0,
            barMinHeight: 1,
            dimensions: [
                { name: 'date', type: 'time' },
                { name: 'totalKnown', type: 'int' },
            ],
        },
        {
            name: 'uc.label.unknown',
            type: 'bar',
            barGap: 0,
            barMinHeight: 1,
            dimensions: [
                { name: 'date', type: 'time' },
                { name: 'totalUnknown', type: 'int' },
            ],
        },
    ],
};

const csvColumnMap = {
    date: 'common.date',
    totalKnown: 'uc.label.known',
    totalUnknown: 'uc.label.unknown',
};

const processRow = (row, columnNames) => {
    return columnNames.reduce((acc, column) => {
        acc.push(row[column]);
        return acc;
    }, []);
};

function makeCSV(data) {
    const { consentsByUser } = data || {};
    const columnNames = Object.keys(csvColumnMap);
    // String CSV
    const csvFile = Object.values(consentsByUser)
        .reduce(
            (acc, row) => {
                const rowArray = processRow(row, columnNames);
                acc.push(rowArray.join(','));
                return acc;
            },
            [columnNames.map(column => translate(csvColumnMap[column])).join(',')]
        )
        .join('\n');
    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

const prepareData = data => {
    // This is where we would prepare the data if we needed to
    return data;
};

const ConsentsByUser = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data, error, load, loading } = useContext(DashboardContext);
    const { consentsByUser = [] } = data || {};
    const noResults = consentsByUser.length === 0;
    const isLoading = loading || providedLoading;
    const theme = useTheme();
    /** @type {import('echarts').EChartsOption} */
    const memoOption = useMemo(
        () =>
            merge({}, staticOptions, {
                legend: {
                    left: theme.spacing(1.5),
                },
                grid: {
                    left: parseInt(theme.spacing(4.5), 10),
                    right: parseInt(theme.spacing(2), 10),
                },
                yAxis: {
                    show: consentsByUser.length > 0,
                    name: translate('uc.section.dashboards.label.consents'),
                    nameGap: parseInt(theme.spacing(3.5), 10),
                },
                dataset: {
                    source: consentsByUser,
                },
                series: staticOptions.series.map(series => {
                    return {
                        ...series,
                        name: translate(series.name),
                    };
                }),
            }),
        [consentsByUser, shouldRetranslate]
    );

    /** @type {() => Promise<UcReportsData>} */
    const fetchData = useBoundCallback(
        filters =>
            fetchUcReportsDashboard({
                filters: { ...filters, requestedCharts: [REPORT_NAME] },
            }).then(prepareData),
        [filters]
    );

    const echartsInstance = useRef(null);
    const helpers = useMemo(
        () => ({
            makeCSV,
            name: 'consents-by-user',
        }),
        []
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(prepareData(providedData))) : load(fetchData());
    }, [providedData, filters]);

    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={['csv', 'png']}
            echartsInstance={echartsInstance}
            error={error}
            title="uc.section.dashboards.title.consentsByUser"
            tooltip="uc.section.dashboards.tooltip.consentsByUser"
        >
            <EChart
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </WidgetContainer>
    );
};
ConsentsByUser.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

export default withAsyncData(withRetranslate(ConsentsByUser), DashboardContext);
