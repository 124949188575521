export default theme => ({
    paper: {
        border: '1px solid',
        borderColor: theme.palette.grey[300],
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 'inherit',
        justifyContent: 'space-between',
        minWidth: 0,
        padding: theme.spacing(3),
    },
    header: {
        alignItems: 'baseline',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(3),
        minWidth: 100,
    },
    icon: {
        color: theme.palette.info.light,
        marginRight: theme.spacing(2),
    },
    loading: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(3),
    },
});
