// @ts-check
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getMapData, loadMap } from './EChart.geo';
import { primary, tertiary } from '../../colors';

import EChart from './EChart';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { translate } from '../../helpers/i18n';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';

/**
 * @typedef GeoEChartProps
 * @property {function} [onChartReady]
 * @property {function} [onMapReady]
 * @property {import('echarts').EChartsOption} [option]
 */

const staticOptions = {
    geo: {
        map: 'adm0.110m',
        roam: true,
        label: {
            color: primary.contrastText,
        },
        layoutCenter: ['50%', '50%'],
        nameProperty: 'divisionCode',
        tooltip: {
            formatter: params => {
                // Name should be the `divisionCode` property
                const { name } = params;
                const mapData = getMapData(name);
                const { divisionCode, divisionLevel } = mapData;
                switch (divisionLevel) {
                    case 0:
                        // Countries
                        return translate(`countries.${divisionCode}`);
                    case 1:
                        // States/Provinces/Territories
                        return translate(`division.${divisionCode}`);
                    case 2:
                        // Counties/Regions/Districts
                        return translate(`regions.${divisionCode}`);
                    default:
                        return name;
                }
            },
        },
        emphasis: {
            label: {
                show: false,
                color: primary.contrastText,
                textBorderColor: 'rgba(0, 0, 0, 0.5)',
                textBorderWidth: 1,
                textShadowColor: 'rgba(0, 0, 0, 1)',
                textShadowBlur: 1,
            },
            itemStyle: {
                areaColor: 'inherit',
                borderColor: tertiary.light,
                color: 'inherit',
                shadowBlur: 5,
                shadowColor: tertiary.light,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
            },
        },
        select: {
            label: {
                show: false,
                color: primary.contrastText,
                textBorderColor: 'rgba(0, 0, 0, 0.5)',
                textBorderWidth: 1,
                textShadowColor: 'rgba(0, 0, 0, 1)',
                textShadowBlur: 1,
            },
            itemStyle: {
                areaColor: null,
                borderColor: tertiary.light,
                borderWidth: 2,
                color: null,
            },
        },
    },
};

/** @type {React.FC<import('@mui/material').BoxProps & GeoEChartProps & import('./EChart').EChartProps>} */
const GeoEChart = props => {
    // GeoEChart is a wrapper around EChart for Geo charts

    const { onChartReady, onMapReady, onResize, option, ...remain } = props;
    /** @type {React.MutableRefObject<import('echarts/core').ECharts>} */
    const instance = useRef();
    /** @type {[boolean|string, React.Dispatch<React.SetStateAction<boolean|string>>]} */
    const [mapsLoaded, setMapsLoaded] = useState(false);
    const sizeRef = useRef(0);
    const [size, setSize] = useState({ width: 0, height: 0 });
    // @ts-ignore
    const { geo: { animationDurationUpdate } = {} } = option || {};
    const [preventAnimation, setPreventAnimation] = useState(false);
    const _animationDurationUpdate = useBoundCallback(
        (animationDurationUpdate, preventAnimation) => {
            const duration = preventAnimation
                ? 0
                : animationDurationUpdate
                  ? animationDurationUpdate()
                  : 400;
            if (preventAnimation || duration <= 0) {
                setPreventAnimation(false);
            }
            return duration;
        },
        [animationDurationUpdate, preventAnimation]
    );
    const rafSetSize = useBoundCallback(newSize => {
        setPreventAnimation(true);
        if (sizeRef.current) {
            cancelAnimationFrame(sizeRef.current);
        }
        sizeRef.current = requestAnimationFrame(() => {
            setSize(newSize);
            sizeRef.current = 0;
        });
    }, []);

    const memoOption = useMemo(() => {
        const newOption = merge(
            {
                geo: {
                    layoutSize: Math.min(size.width, size.height),
                    scaleLimit: {
                        min: Math.max(size.width, size.height) / Math.min(size.width, size.height),
                    },
                },
            },
            staticOptions,
            option,
            {
                geo: {
                    animationDurationUpdate: _animationDurationUpdate,
                },
            }
        );
        const {
            geo: { map },
        } = newOption;
        // @ts-ignore - Typing is fuzzy here, but we know that `map` is a string
        if (mapsLoaded !== map) {
            setMapsLoaded(false);
        }

        return newOption;
    }, [option, size]);

    const onMapLoad = useBoundCallback(
        map => {
            function ready() {
                setPreventAnimation(true);
                setMapsLoaded(map);
                // Trigger a size change to ensure the map is centered
                setSize(size => ({ ...size }));
            }
            const [mapName, administrationLevel] = map.split('.');
            return loadMap(mapName, administrationLevel)
                .then(ready)
                .catch(() => {
                    // eslint-disable-next-line no-console
                    console.error(`Failed to load map: ${map}`);
                });
        },
        [memoOption?.geo?.map]
    );

    const _onResize = useBoundCallback(
        (onResize, /** @type {ResizeObserverEntry} */ entry) => {
            const newSize = {
                width: entry.contentRect.width,
                height: entry.contentRect.height,
            };
            rafSetSize(newSize);
            onResize && onResize(entry);
        },
        [onResize]
    );

    const onChartClick = useBoundCallback(
        (size, params) => {
            const { target, name } = params;
            const { center = [0, 0], zoom = 1 } = getMapData(name);
            const aspect = size.width / size.height;
            const newZoom = Math.max(1, aspect, zoom * aspect * 0.5625);
            instance.current.setOption({ geo: { center, zoom: newZoom } });
            if (!name && !target) {
                // We deselected the map by clicking outside of it...
                instance.current.dispatchAction({
                    type: 'geoToggleSelect',
                });
            }
        },
        [size]
    );

    const _onChartReady = useBoundCallback(
        (onChartReady, /** @type {import('echarts/core').ECharts} */ chart) => {
            if (instance.current) {
                instance.current.off('click', onChartClick);
                instance.current.getZr().off('click', onChartClick);
            }
            function ready() {
                instance.current = chart;
                instance.current.setOption({ geo: { center: [0, 0], zoom: 1 } });
                chart.on('click', onChartClick);
                chart.getZr().on('click', onChartClick);
                if (onChartReady) {
                    onChartReady(chart);
                }
                setPreventAnimation(true);
                // Trigger a size change to ensure the map is centered
                setSize(size => ({ ...size }));
            }

            onMapLoad().then(ready);
        },
        [onChartReady]
    );

    useLayoutEffect(() => {
        if (instance.current) {
            const option = instance.current.getOption();
            // `getOption` returns option items in their array form: https://echarts.apache.org/en/api.html#echartsInstance.getOption
            const geo = option.geo[0];
            const aspect = Math.max(size.width / size.height, size.height / size.width);
            if (!Number.isNaN(aspect) && aspect !== Infinity) {
                const zoom = Math.max(geo.zoom || 1, aspect);
                const newZoom = Math.max(1, Math.min(aspect, zoom), aspect * 0.5625);
                instance.current.setOption({ geo: { zoom: newZoom } });
            }
        }
    }, [size]);

    useLayoutEffect(() => {
        if (instance.current && !mapsLoaded) {
            onMapLoad();
        }
    }, [memoOption?.geo?.map]);

    useLayoutEffect(() => {
        if (mapsLoaded) {
            onMapReady && onMapReady(mapsLoaded);
        }
    }, [onMapReady, mapsLoaded]);

    return (
        <EChart
            {...remain}
            onChartReady={_onChartReady}
            onResize={_onResize}
            option={mapsLoaded ? memoOption : undefined}
        />
    );
};

GeoEChart.propTypes = {
    onChartReady: PropTypes.func,
    onMapReady: PropTypes.func,
    onResize: PropTypes.func,
    option: PropTypes.any,
};

export default GeoEChart;
