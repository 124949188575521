import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import useBoundCallback from '../helpers/Hooks/useBoundCallback';

/**
 * @template T
 * @typedef {
 *  function(newState: T): PromiseLike<T> |
 *  function(asyncFunction: (currentState: T) => T): PromiseLike<T> |
 *  function(asyncFunction: (currentState: T) => PromiseLike<T>): PromiseLike<T> |
 *  function(promise: PromiseLike<T>): PromiseLike<T>
 * } HocAsyncDataContextLoaders
 */

/**
 * @template T
 * @typedef {object} HocAsyncDataState
 * @property {boolean} loading
 * @property {T} data
 * @property {null|Error|string} error
 */

/**
 * @template T
 * @typedef {object} HocAsyncDataLoad
 * @property {HocAsyncDataContextLoaders<T>} load
 */

/**
 * @template T
 * @export
 * @typedef {HocAsyncDataState<T> & HocAsyncDataLoad<T>} HocAsyncDataContext
 */

/**
 * @template P
 * @typedef {React.FC<P>} ComponentWithService
 */

/**
 * @export
 * @template P
 * @template A
 * @typedef {React.ComponentType<Partial<HocAsyncDataState<P>> & A>} ComponentWithOverridenProps
 */

export const withAsyncDataContext = React.createContext({
    initialLoad: true,
    load: () => Promise.resolve(null),
    loading: false,
    data: null,
    error: null,
});

/**
 * @template T
 * @typedef {(import('react').SetStateAction<HocAsyncDataState<T>>) => void} HocAsyncDataSetStateAction
 */

/**
 * @template T
 * @overload
 * @param {T} currentData
 * @param {HocAsyncDataSetStateAction<T>} setState
 * @param {PromiseLike<T>} promise
 * @return {PromiseLike<T>}
 */

/**
 * @overload
 * @param {T} currentData
 * @param {HocAsyncDataSetStateAction<T>} setState
 * @param {(currentState: T) => (PromiseLike<T> | T)} asyncFunction
 * @return {PromiseLike<T>}
 */

/**
 * @overload
 * @param {T} currentData
 * @param {HocAsyncDataSetStateAction<T>} setState
 * @param {T} newState
 * @return {PromiseLike<T>}
 */
function loader(currentData, setState, newState) {
    const nextState = typeof newState === 'function' ? newState(currentData) : newState;
    if (nextState && nextState.then && typeof nextState.then === 'function') {
        setState(({ data }) => ({ data, initialLoad: false, loading: true, error: null }));
        // Thenable
        return nextState
            .then(data => {
                setState({ data, initialLoad: false, loading: false, error: null });
                return data;
            })
            .catch(error => {
                const { errors, message: errorMessage } = error;
                // Could be a custom error message with roadblocks
                const { message = errorMessage, roadblocks: _roadblocks } = errorMessage || {};
                setState(({ data }) => ({
                    data,
                    initialLoad: false,
                    loading: false,
                    error: `${message}\n${Object.values(errors ?? {}).join('\n')}`,
                }));
                throw error;
            });
    } else {
        setState(({ initialLoad, loading, error }) => ({
            initialLoad,
            loading,
            error,
            data: nextState,
        }));
        return Promise.resolve(nextState);
    }
}

const LoadingCoordinatorContext = React.createContext({
    registerLoadingState: () => {},
});

/**
 * @param {import('react').ReactNode} children
 * @returns {import('react').ReactNode}
 */
const LoadingCoordinator = ({ children }) => {
    const [childLoadingStates, setChildLoadingStates] = useState({});

    const registerLoadingState = useCallback((componentId, isLoading) => {
        setChildLoadingStates(prev => ({
            ...prev,
            [componentId]: isLoading,
        }));
    }, []);

    const isAnyChildLoading = Object.values(childLoadingStates).some(Boolean);

    const contextValue = useMemo(
        () => ({
            registerLoadingState,
            isAnyChildLoading,
        }),
        [registerLoadingState, isAnyChildLoading]
    );

    return (
        <LoadingCoordinatorContext.Provider value={contextValue}>
            {children}
        </LoadingCoordinatorContext.Provider>
    );
};

LoadingCoordinator.propTypes = {
    children: PropTypes.node.isRequired,
};

/**
 * @template P
 * @template T
 * @param {import('react').ComponentType<P>} Component
 * @param {import('react').Context<HocAsyncDataContext<T>>} [providedContext = withAsyncDataContext]
 * @param {T} [initialData]
 * @returns {ComponentWithService<P>}
 */
export const withAsyncData = (Component, providedContext = withAsyncDataContext, initialData) => {
    // If the component is memoized, the context update will not force a re-render
    const MemoizedComponent = React.memo(Component);

    function ComponentWithService(props) {
        const defaultState = useContext(providedContext);
        const { componentId } = props;
        const loadingCoordinator = useContext(LoadingCoordinatorContext);

        const [{ data, initialLoad, error, loading }, setState] = useState({
            ...defaultState,
            data: initialData ?? defaultState.data,
        });

        const load = useBoundCallback(loader, [data, setState]);

        // Register this component's loading state with coordinator
        useEffect(() => {
            if (componentId && loadingCoordinator?.registerLoadingState) {
                loadingCoordinator.registerLoadingState(componentId, loading);
            }
        }, [componentId, loading]);

        // Use coordinator's global loading state
        const effectiveLoading = loading || loadingCoordinator?.isAnyChildLoading;

        const context = useMemo(
            () => ({
                data,
                initialLoad,
                error,
                load,
                loading: effectiveLoading,
            }),
            [data, error, effectiveLoading]
        );

        return (
            <providedContext.Provider value={context}>
                <MemoizedComponent {...props} />
            </providedContext.Provider>
        );
    }

    ComponentWithService.propTypes = {
        componentId: PropTypes.string,
    };

    return ComponentWithService;
};

// Export the coordinator for top-level usage
export { LoadingCoordinator };
