import moment from 'moment';

export const parseDate = date => {
    let timestamp = date;

    if (!isNaN(Date.parse(date))) {
        // Convert date strings into numbers
        timestamp = Date.parse(date);
    }

    if (typeof date === 'string' && !isNaN(Number(date))) {
        // Convert string timestamps to numbers
        timestamp = Number(date);
    }

    // Return UTC date string or original
    return typeof timestamp === 'number' ? new Date(timestamp).toUTCString() : date;
};

export const validateDate = (date, { format = 'MM/DD/YYYY' } = {}) =>
    moment(date, format).isValid();

export const validateFutureDate = (date, { format = 'MM/DD/YYYY', currentTime = null } = {}) => {
    const normalizedDate = moment(date, format);
    const normalizedCurrentTime = validateDate(currentTime)
        ? moment(currentTime, format)
        : moment();

    return validateDate(normalizedDate) && normalizedCurrentTime.isBefore(normalizedDate);
};

export const validatePastDate = (date, { format = 'MM/DD/YYYY', currentTime = null } = {}) => {
    const normalizedDate = moment(date, format);
    const normalizedCurrentTime = validateDate(currentTime)
        ? moment(currentTime, format)
        : moment();

    return validateDate(normalizedDate) && normalizedCurrentTime.isAfter(normalizedDate);
};

/**
 * @param {number} [length=5] Number of years to return
 * @param {Intl.DateTimeFormatOptions} [format={ year: 'numeric' }]
 * @param {boolean} [inclusive=true] Include current year
 * @return {string[]} Array of formatted months
 */
export const priorYears = (length = 5, format = { year: 'numeric' }, inclusive = true) => {
    const currentDate = new Date();
    const months = Array.from({ length }, (v, i) => {
        return new Date(
            currentDate.setFullYear(currentDate.getFullYear() - (!inclusive || i ? 1 : 0))
        );
    }).reverse();
    return months.map((/** @type {Date} */ date) => date.toLocaleString('default', format));
};

/**
 * @param {number} [length=12] Number of months to return
 * @param {Intl.DateTimeFormatOptions} [format={ month: 'short' }]
 * * @param {boolean} [inclusive=true] Include current month
 * @return {string[]} Array of formatted months
 */
export const priorMonths = (length = 12, format = { month: 'short' }, inclusive = true) => {
    const currentDate = new Date();
    const months = Array.from({ length }, (v, i) => {
        return new Date(currentDate.setMonth(currentDate.getMonth() - (!inclusive || i ? 1 : 0)));
    }).reverse();
    return months.map((/** @type {Date} */ date) => date.toLocaleString('default', format));
};

/**
 * @param {number} [length=7] Number of days to return
 * @param {Intl.DateTimeFormatOptions} [format={ weekday: 'short' }] Date format options
 * @param {boolean} [inclusive=true] Include current day
 * @return {string[]} Array of formatted days
 */
export const priorDays = (length = 7, format = { weekday: 'short' }, inclusive = true) => {
    const currentDate = new Date();
    const days = Array.from({ length }, (v, i) => {
        return new Date(currentDate.setDate(currentDate.getDate() - (!inclusive || i ? 1 : 0)));
    }).reverse();
    return days.map((/** @type {Date} */ date) => date.toLocaleString('default', format));
};

/**
 * @param {number} [length=12] Number of hours to return
 * @param {Intl.DateTimeFormatOptions} [format={ hour12: true, hour: 'numeric' }]
 * @param {boolean} [inclusive=true] Include current hour
 * @return {string[]} Array of formatted hours
 */
export const priorHours = (
    length = 12,
    format = { hour12: true, hour: 'numeric' },
    inclusive = true
) => {
    const currentDate = new Date();
    const months = Array.from({ length }, (v, i) => {
        return new Date(currentDate.setHours(currentDate.getHours() - (!inclusive || i ? 1 : 0)));
    }).reverse();
    return months.map((/** @type {Date} */ date) => date.toLocaleString('default', format));
};

/**
 * Normalizes a date range to the start of the first day and the end of the last day in UTC.
 *
 * @param {Array<string|number>} dateRange - The date range to normalize, represented as an array with two elements: start and end dates.
 * @returns {number[]} - An array containing the normalized start and end dates as UTC timestamps in milliseconds.
 */
export const normalizeDateRange = dateRange => {
    let [start, end] = dateRange;
    start = typeof start === 'string' ? parseInt(start, 10) : start;
    end = typeof end === 'string' ? parseInt(end, 10) : end;
    const startDate = moment.utc(start).startOf('day');
    const endDate = moment.utc(end).endOf('day');

    return [startDate.valueOf(), endDate.valueOf()];
};

/**
 * Normalizes a date to the start of the first day and the end of the last day in UTC.
 * @param {string|number} date
 * @returns {number} - The normalized date as a UTC timestamp in milliseconds.
 */
export const normalizeDate = date => {
    const parsedDate = typeof date === 'string' ? parseInt(date, 10) : date;
    return moment.utc(parsedDate).startOf('day').valueOf();
};

export const dateRange = (start, end, { format = 'MM/DD/YYYY' } = {}) => {
    const startDate = moment(start, format);
    const endDate = moment(end, format);
    const range = [];

    if (startDate.isValid() && endDate.isValid()) {
        let currentDate = startDate.clone();

        while (currentDate.isSameOrBefore(endDate)) {
            range.push(currentDate.format(format));
            currentDate.add(1, 'day');
        }
    }

    return range;
};
