import { CircularProgress, Grid, Link, Typography } from '@mui/material';

import Locale from '/b2b/common/components/Locale';
import Portlet from '/b2b/common/components/Portlet';
import PortletContent from '/b2b/common/components/PortletContent';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Stats.styles';

const useStyles = makeStyles(styles);

const Stats = props => {
    const { className, data = [] } = props;
    const classes = useStyles(props);
    const displayStat = (title, stat) => {
        if (stat === '--') {
            return <CircularProgress className={classes.circleProgress} />;
        } else {
            if (typeof stat === 'object') {
                return stat.upgrade ? (
                    <div>
                        <Link className={classes.link} component={RouterLink} to={'/premium'}>
                            Upgrade Now
                        </Link>
                        <Typography className={classes.upgrade}>{stat.fakeValue}</Typography>
                        <Typography className={classes.title} variant="body1" component="h4">
                            <Locale path={title} />
                        </Typography>
                    </div>
                ) : (
                    <Typography className={classes.upgrade}>
                        {stat?.fakeValue || stat?.value}
                    </Typography>
                );
            } else {
                return (
                    <div>
                        <Typography className={classes.stat}>{stat}</Typography>
                        <Typography className={classes.title} variant="body1" component="h4">
                            <Locale path={title} />
                        </Typography>
                    </div>
                );
            }
        }
    };

    return (
        <Grid container spacing={2} className={classes.root}>
            {data.map(({ title, value }, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg className={classes.container}>
                    <Portlet className={className} elevation={0}>
                        <PortletContent className={classes.wrapper}>
                            {displayStat(title, value)}
                        </PortletContent>
                    </Portlet>
                </Grid>
            ))}
        </Grid>
    );
};

Stats.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
};

export default Stats;
