import { FormHelperText, InputBase, MenuItem } from '@mui/material';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useField, useFormikContext } from 'formik';

import DatePickerComponent from './DatePicker';
import Locale from '../Locale';
import PropTypes from 'prop-types';
import SelectComponent from './Select';
import SwitchComponent from './Switch';
import TextFieldComponent from './TextField';
import castArray from 'lodash/castArray';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { isValidElementType } from 'react-is';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import useBoundCallback from '../../helpers/Hooks/useBoundCallback';
import { v4 as uuid } from 'uuid';

const emptyArray = [];
const fields = {
    input: {
        date: DatePickerComponent,
        hidden: InputBase,
        select: SelectComponent,
    },
    select: SelectComponent,
    switch: SwitchComponent,
};

function validationErrorReducer(allErrors, error) {
    if (React.isValidElement(error) || typeof error === 'string') {
        return [...allErrors, error];
    }
    if (typeof error === 'object') {
        return Object.values(error).reduce(validationErrorReducer, allErrors);
    }
    if (error instanceof Error) {
        return [...allErrors, error.message];
    }
    return allErrors;
}

/** @typedef {[field: string, value: any]} ClauseTuple */

/**
 * Processes the provided state against the `clauses` tuples
 *
 * @param {Object} [state={}] The state to verify clauses against
 * @param {[string, any][]} [clauses=[]] An `OR` clause array of `AND` [field, clause] tuples needed to verify against state
 * @param {string} domain The starting domain key in the state to prefix field clauses
 * @return {boolean} If the depends clauses are satisfied by the provided state
 */
function verifyDepends(state = {}, clauses = [], domain) {
    return clauses.reduce(
        (pass, andClauses) => {
            if (pass) {
                return pass;
            }
            // Each clause is a 2-tuple ['path', 'expectedValue']
            for (let index = 0; index < andClauses.length; index += 2) {
                /** @type {[string, any]} */
                const [field, value] = andClauses.slice(index, index + 2);
                const currentValue = get(state, `${domain || ''}${domain ? '.' : ''}${field}`);
                if (value != null && !castArray(currentValue).includes(value)) {
                    return false;
                }
            }
            return true;
        },
        clauses.length ? false : true
    );
}

/**
 * Handler called in order to adapt component `onChange` handlers to be Formik compatible
 *
 * @param {*} currentValue
 * @param {string} type
 * @param {import('formik').FieldHelperProps['setValue']} setValue
 * @param {import('formik').FormikHelpers['setTouched']} setTouched
 * @param {import('formik').FormikHandlers['handleChange']} formikChange
 * @param {import('formik').FieldProps['onChange']} onChange
 * @param {Event|*} eventOrValue
 * @param {*} value
 */
const onChangeFix = (
    currentValue,
    type,
    setValue,
    setTouched,
    formikChange,
    onChange,
    eventOrValue,
    value,
    ...args
) => {
    if (typeof eventOrValue?.preventDefault !== 'function') {
        switch (type) {
            case 'date-range': {
                // Check if every array value is undefined
                if (eventOrValue.every(val => val === undefined)) {
                    if (!currentValue) {
                        // This is the initial state, so we don't need to do anything
                        return;
                    }
                    if (!isEqual(currentValue, eventOrValue)) {
                        setTouched(true);
                        setValue(undefined);
                        onChange && onChange(eventOrValue, value, ...args);
                        return;
                    }
                }
            }
        }
        // Dealing with a value set
        if (!isEqual(currentValue, eventOrValue)) {
            // Manually set touched because the formik field is not aware of the change until blur
            setTouched(true);
            setValue(eventOrValue);
            onChange && onChange(eventOrValue, value, ...args);
        }
        return;
    }
    switch (type) {
        case 'autocomplete': {
            /**
             * Autocomplete
             *
             * Autocomplete dispatches a change event with the event.target being the
             * list item (<li>) selected. The second parameter is the new value.
             *
             */
            setValue(value);
            onChange && onChange(eventOrValue, value, ...args);
            return;
        }
        case 'checkbox': {
            // Manually set touched because the formik field is not aware of the change until blur
            setTouched(true);
            /**
             * Switch + Checkbox
             *
             * Due to the nature of forms, checkboxes and switches can repeat with the
             * same name, and make their data accumulate into an array.
             *
             */
            const fieldValue = `${eventOrValue.target.value ?? ''}`;
            if (!fieldValue) {
                setValue(eventOrValue.target.checked ? true : false);
                onChange && onChange(eventOrValue, value, ...args);
                return;
            }
            const arrayValue = castArray(fieldValue.split(',')).filter(Boolean);
            if (arrayValue.length > 1) {
                // A multi-value checkbox, so we turn all the values on or off
                const newValue = value
                    ? [...castArray(currentValue), ...arrayValue]
                    : castArray(currentValue).filter(val => !arrayValue.includes(val));
                setValue(uniq(newValue));
                onChange && onChange(eventOrValue, value, ...args);
                return;
            }
            // Formik handles single value checkboxes as arrays, so by the time we get here we let Formik handle it
            break;
        }
        case 'file': {
            // Manually set touched because the formik field is not aware of the change until blur
            setTouched(true);
            const input = eventOrValue.target;
            if (input.files && input.files[0]) {
                setValue(input.files);
            } else {
                setValue([]);
            }
            onChange && onChange(eventOrValue, value, ...args);
            return;
        }
        case 'select': {
            // Manually set touched because the formik field is not aware of the change until blur
            setTouched(true);
            break;
        }
        default: {
            break;
        }
    }
    formikChange && formikChange(eventOrValue);
    onChange && onChange(eventOrValue, value, ...args);
};

/**
 * Necessary for (MUI DatePicker + Formik)[https://next.material-ui-pickers.dev/guides/forms#formik]
 *
 * @param {string} reason
 * @return {string} The error message to use for validation
 */
const onErrorReason = reason => {
    switch (reason) {
        case 'invalidDate': {
            return 'common.fields.errors.invalidDate';
        }

        case 'disableFuture': {
            return 'common.fields.error.notInFuture';
        }

        case 'disablePast': {
            return 'common.fields.error.notInPast';
        }

        case 'minDate': {
            return 'common.fields.errors.minDate';
        }

        case 'maxDate': {
            return 'common.fields.errors.maxDate';
        }

        case 'shouldDisableDate': {
            // date was disabled due to `shouldDisableDate` logic returning `true` for provided date
            return 'common.fields.errors.dateNotAllowed';
        }
    }

    return reason;
};

/**
 * @typedef {Object} Base
 * @property {(string | React.ComponentType<import('formik').FieldProps>)=} component Either a React component or the name of an HTML element to render.
 * @property {string=} error A manually provided error. This error will replace the Formik generated error.
 * @property {(value: T) => T=} format A function to format the input value before it is set in Formik.
 * @property {string} helperText The helperText to display beneath the field.
 * @property {string=} id The id to be used for the form field. If one is not provided, it will be generated.
 * @property {(value: T) => T=} mask A function to format the input value before it is displayed in the input.
 * @property {string} name The name of the field used in the form submission.
 * @property {Record<string,any>=} translationOptions The `options` object provided to all internal <Locale/> translations for the TextField
 * @property {string} type The type of form field.
 * @property {(formValues: any) => bool | [string, any][]} depends A function or series of tuple clauses used to decide if the form field is visible
 * @property {(fieldValue:any) => (undefined|Promise<String>|String)=} validate A form level validation method. This will be run every time the form validates in addition to Form level validation. Returns undefined if valid, an error message string, or a Promise for async validation.
 * @property {string=} value The string value to use for the form field in the instance of a `checkbox`.
 *
 * @typedef {import('@mui/material').TextFieldProps & import('formik').FieldProps & Base} FormControlProps
 */

/**
 * @type {React.ForwardRefExoticComponent<FormControlProps>}
 */
export const FormikField = React.forwardRef((props, ref) => {
    const {
        children,
        component = 'input',
        depends,
        error: providedError,
        format,
        fullWidth,
        helperText,
        id: providedId,
        label,
        mask,
        multiple,
        name,
        onChange,
        onError,
        options,
        placeholder,
        required,
        slotProps,
        translationOptions,
        type = 'text',
        validate: providedValidate,
        value: providedValue,
        ...remain
    } = props;
    const Component = fields[component]
        ? isValidElementType(fields[component])
            ? fields[component]
            : (isValidElementType(fields[component][type]) && fields[component][type]) ||
              TextFieldComponent
        : isValidElementType(component)
          ? component
          : TextFieldComponent;

    const { isValid, submitCount, values } = useFormikContext();
    const shouldRender =
        typeof depends === 'function' ? depends(values) : verifyDepends(values, depends);
    const currentValue =
        type === 'checkbox' && providedValue
            ? castArray(get(values, name, emptyArray)).filter(Boolean)
            : get(values, name);
    const customError = useRef(null);
    const [dependsValue, storeDependsValue] = useState(currentValue);

    const validate = useBoundCallback(
        (validate, value) => {
            let error = (validate && validate(value)) || customError.current;
            return error;
        },
        [providedValidate]
    );
    const checkboxLike = type === 'checkbox' || type === 'switch' || type === 'radio';
    const [formikInputProps, formikMetaProps, formikHelperProps] = useField({
        // The only time we want to provide a value is if the control has a 'checked' property, otherwise we are overwriting the input value
        value: checkboxLike ? providedValue ?? '' : undefined, // Fix for Formik checkboxes that don't provide a value
        type,
        multiple,
        name,
        validate,
    });

    const {
        initialError = providedError,
        initialTouched,
        initialValue,
        error = initialError,
        touched = initialTouched,
    } = formikMetaProps;
    const { setValue, setTouched } = formikHelperProps;

    const id = useRef(providedId || uuid());
    const helperId = useRef(`o-${id.current}-helper-text`); // MUI uses `${id}-helper-text` internally, so ours needs to be different

    // Switch + Checkbox
    const arrayValue = useMemo(
        () => castArray(`${providedValue ?? ''}`.split(',')).filter(Boolean),
        [type, providedValue]
    );
    let indeterminate;
    if (type === 'checkbox') {
        if (typeof currentValue === 'boolean') {
            // If the value in the form is a boolean, we can just compare it directly
            formikInputProps.checked = currentValue;
        } else {
            const currentValueArray = castArray(currentValue);
            formikInputProps.checked =
                arrayValue.length > 0 && arrayValue.every(val => currentValueArray.includes(val));
            indeterminate =
                formikInputProps.checked === false &&
                arrayValue.some(val => currentValueArray.includes(val));
        }
    } else if (type === 'radio') {
        formikInputProps.checked = `${providedValue}` === `${currentValue}`;
    }

    const maskValue = useBoundCallback(
        (mask, value) => {
            return mask ? mask(value) : value;
        },
        [mask]
    );

    const formatInput = useBoundCallback(
        (format, value) => {
            return format ? format(value) : value;
        },
        [format]
    );

    const _setValue = useBoundCallback(
        (formatInput, setValue, value, shouldValidate) => {
            setValue(formatInput(value), shouldValidate);
        },
        [formatInput, setValue]
    );

    const formikOnChange = useBoundCallback(
        (formatInput, onChange, event, ...args) => {
            const value = event.target.value;
            event.target.value = formatInput(value);
            return onChange && onChange(event, ...args);
        },
        [formatInput, formikInputProps.onChange]
    );

    // This will allow us to handle change events that send events, or new values, and fix checkbox and switch behavior
    const handleChange = useBoundCallback(onChangeFix, [
        currentValue,
        type,
        _setValue,
        setTouched,
        formikOnChange,
        onChange,
    ]);

    const handleError = useBoundCallback(
        (setTouched, onError, errorOrReason) => {
            const { message = errorOrReason } = errorOrReason || {};
            const error = onErrorReason(message);
            customError.current = error;
        },
        [setTouched, onError]
    );

    const memoErrorArray = useMemo(() => {
        const currentError = providedError || error;
        const errorArray = castArray(currentError)
            .reduce(validationErrorReducer, [])
            .filter(Boolean)
            .reduce((acc, error, i, errors) => {
                acc.push(
                    React.isValidElement(error) ? (
                        React.cloneElement(error, { key: i })
                    ) : (
                        <Locale key={i} path={error} translationOptions={translationOptions} />
                    )
                );
                if (i < errors.length - 1) {
                    acc.push(<br key={`${i}-br`} />);
                }
                return acc;
            }, []);
        return errorArray.length ? errorArray : undefined;
    }, [providedError, error]);

    const memoChildren = useMemo(() => {
        if (!children) {
            if (type === 'select' && options) {
                return options.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ));
            }
        }
        return children || null;
    }, [children, options, type]);

    // Only render an error if the field has been marked `touched`, the form has been submitted, or changes are made
    const showError = (touched || submitCount > 0) && !isValid;

    useLayoutEffect(() => {
        if (type === 'checkbox' && providedValue && !get(values, name)) {
            // Cast checkbox values to empty arrays instead of empty if a value is provided
            _setValue(emptyArray);
        }
    }, [type, currentValue, providedValue]);

    useLayoutEffect(() => {
        if (shouldRender) {
            _setValue(dependsValue, false);
        } else {
            storeDependsValue(currentValue);
            _setValue(undefined, false);
        }
    }, [shouldRender]);

    return shouldRender ? (
        <>
            <Component
                id={id.current}
                ref={ref}
                fullWidth={type !== 'hidden' && !!fullWidth}
                error={showError ? !!(providedError || error) : false}
                type={type}
                label={type !== 'hidden' ? label ?? startCase(name) : undefined}
                aria-describedby={helperText ? helperId.current : undefined}
                defaultValue={initialValue}
                placeholder={placeholder}
                helperText={showError ? memoErrorArray : undefined}
                indeterminate={indeterminate}
                multiple={multiple}
                required={!!required}
                translationOptions={translationOptions}
                options={options}
                {...remain}
                {...slotProps?.component}
                {...formikInputProps}
                value={maskValue(
                    (multiple || type === 'date-range'
                        ? formikInputProps.value || emptyArray
                        : formikInputProps.value) ??
                        (type === 'checkbox' && providedValue
                            ? providedValue
                            : multiple || type === 'file'
                              ? emptyArray
                              : type === 'autocomplete'
                                ? null
                                : '')
                )}
                onChange={handleChange}
                onError={handleError}
            >
                {memoChildren}
            </Component>
            {/*
                If you have helperText, it should not be provided to the Component, because it will inherit
                error or disabled formatting from `FormControl`. Rather, we render it outside the Input field.
            */}
            {helperText && type !== 'hidden' ? (
                <FormHelperText
                    id={helperId.current}
                    {...slotProps?.helperText}
                    sx={{ marginTop: -0.5, ...slotProps?.helperText?.sx }}
                >
                    {React.isValidElement(helperText) ? (
                        helperText
                    ) : (
                        <Locale path={helperText} options={translationOptions} />
                    )}
                </FormHelperText>
            ) : null}
        </>
    ) : null;
});

FormikField.propTypes = {
    children: PropTypes.node,
    component: PropTypes.elementType,
    depends: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.any]))
        ),
    ]),
    error: PropTypes.string,
    format: PropTypes.func,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    hiddenLabel: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    mask: PropTypes.func,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    slotProps: PropTypes.shape({
        component: PropTypes.object,
        helperText: PropTypes.object,
    }),
    translationOptions: PropTypes.object,
    type: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.any,
};

/**
 * @template T
 * @param {React.ForwardRefExoticComponent<T>} FormControl The form control to wrap with Formik
 * @param {{ [P in keyof T]: T[P] }} overrideProps The form control props to provide defaults to
 * @return {React.FunctionComponent<T & FormControlProps>}
 */
export function withFormikField(FormControl, overrideProps) {
    return React.forwardRef(function (props, ref) {
        return <FormikField {...props} {...overrideProps} component={FormControl} ref={ref} />;
    });
}
