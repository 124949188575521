export const createCollectionPayloadFromState = state => {
    const { logo } = state;
    if (logo instanceof FileList) {
        // Since we have a file upload for logo, we need to use multipart/form-data
        const formData = new FormData();
        for (const [key, value] of Object.entries(state)) {
            if (value !== undefined) {
                switch (key) {
                    case 'logo': {
                        /** @type {FileList | string} */
                        const fileListOrUrl = value;
                        if (typeof fileListOrUrl === 'string') {
                            formData.append(key, fileListOrUrl);
                        } else {
                            const file = fileListOrUrl[0];
                            formData.append(key, file);
                        }
                        break;
                    }
                    default: {
                        if (typeof value === 'object') {
                            formData.append(key, JSON.stringify(value));
                        } else {
                            formData.append(key, value);
                        }
                    }
                }
            }
        }
        return formData;
    }

    return state;
};
