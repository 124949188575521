import { REPORTS, TODOS } from '../constants';

import { DOMAIN } from './Dashboard.constants';
import fromState from '/b2b/common/state/selectors';
import get from 'lodash/get';

const isResourceAllowed = (state, { customerPermission, rolePermission }) => {
    // If neither permission is defined, deny access
    if (!customerPermission && !rolePermission) {
        return false;
    }

    // Check both permissions if they exist
    const hasCustomerPermission = customerPermission
        ? fromState.Auth.customer.hasPermission(state, customerPermission)
        : true;

    const hasRolePermission = rolePermission
        ? fromState.Auth.user.hasPermission(state, rolePermission)
        : true;

    // If both permissions exist, both must pass; otherwise, the existing one must pass
    return hasCustomerPermission && hasRolePermission;
};

const getAllowedResources = (state, resources) =>
    Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(resources).filter(([_, resource]) => isResourceAllowed(state, resource))
    );

export const getStore = state => get(state, DOMAIN) || {};

export const getData = state => getStore(state).data || {};
export const getError = state => getStore(state).error;
export const getPreferences = state => getStore(state).preferences || {};

export const getConsentData = state => getData(state).consent;
export const getStatsData = state => getData(state).stats;
export const getTrafficData = state => getData(state).traffic;
export const getAllowedReports = state => getAllowedResources(state, REPORTS);
export const getAllowedTodos = state => getAllowedResources(state, TODOS);
export const getFilteredTodos = (state, todos = {}) =>
    Object.fromEntries(
        Object.entries(todos)
            .map(([product, productTodos]) => {
                const filteredTodos = Object.fromEntries(
                    Object.entries(productTodos).filter(([todo]) =>
                        isResourceAllowed(state, TODOS[todo] || {})
                    )
                );

                return [product, filteredTodos];
            })
            .filter(([, filteredTodos]) => Object.keys(filteredTodos).length > 0)
    );

export const isLoading = state => getStore(state).loading;
