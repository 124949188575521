import {
    confirmFilters,
    confirmSearch,
    getParamsFromQueryString,
    resetFilters,
    resetSearch,
    setLimit,
    setPage,
    setSort,
    updateFilterModel,
    updateFilters,
    updateSearch,
} from './ORMResults.commands';
import { initState, initialState } from './ORMResults.reducer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryStringUpdate, useThunkReducer } from '..';

import Actions from './ORMResults.actions';
import UIActions from '../../../state/ui/UI.actions';
import { canRequestResults } from './ORMResults.selectors';
import castArray from 'lodash/castArray';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import reducer from './ORMResults.reducer';
import uniqueId from 'lodash/uniqueId';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const emptyObject = {};

/**
 * @callback FetchDetailsService
 * @param {string} id The id for the schema entity
 * @param {Object} params Any additional params used by the service
 * @returns {Promise}
 */

/**
 * @callback UpdateDetailsService
 * @param {string} id The id for the schema entity
 * @param {Object} details The updated properties to provide to the service
 * @returns {Promise}
 */

/**
 * @typedef {Object} PlanData
 * @property {number} [limit] The limit for the plan
 * @property {number} [total] The total used for the plan
 */

/**
 * @typedef {Object} ServiceParams
 * @property {string} [id] The id for the schema entity
 * @property {number} [offset] The offset for the results
 * @property {number} [limit] The limit for the results
 * @property {string} [sort] The sort field for the results
 * @property {'asc'|'desc'|'none'} [sortDirection] The sort direction for the results
 * @property {string} [query] The search query for the results
 * @property {Object} [filters] The filters for the results
 */

/**
    @template R
    @typedef ORMResultsHookProps
    @type {Object}
    @property {(params: ServiceParams) => Promise<any>|void} [fetchResultsService] The service to request the ORM results
    @property {(deletedIds: string[]) => Promise<any>|void} [deleteResultsService] The service to delete rows
    @property {({ id: string, body: any }) => Promise<any>|void} [updateResultsService] The service to update fields on the result row
    @property {Object} [filters] The initial filters for the results
    @property {string|number} id The ID for the ORM schema
    @property {Object} [manualRefresh] Request params that force the results to be downloaded again
    @property {(filters: Object) => Object} [mapFilters] The function to map the filters to the query string
    @property {(filters: Object) => Promise<any>|void} onFilterConfirm The callback fired when the filters are confirmed. This is provided the filters object, or mapped filters if `mapFilters` is provided
    @property {(query: string) => Promise<any>|void} onSearchConfirm The callback fired when the search is confirmed. This is provided the search query
    @property {boolean|'infinite'} [pagination] Indicates that the results should be paginated
    @property {string} [query] The initial search query for the results
    @property {boolean} [requestOnLoad=true] Indicates that the results should be initially requested
    @property {Object} schema The ORM schema for the details
    @property {boolean} [updateQueryString=true] Indicates that the browser url query string should be updated when the search or filters change
*/

/**
    @typedef UseORMResultsState
    @type {Object}
    @property {Object} currentFilters The current filters applied to the results
    @property {string} currentQuery The current search query applied to the results
    @property {string|null} error The error string for the results, or null if no error
    @property {Object} filters The selected, but not applied, filters for the results
    @property {number} limit The number of results to return per page
    @property {string} filterModel The current filter model for the results
    @property {boolean} loading Indicates that the results are currently loading
    @property {number} offset The offset for the results
    @property {PlanData} planData The plan data associated with the results
    @property {string} query The search query for the results
    @property {boolean} requireSearch Indicates that the results require a search query before requesting
    @property {any[]} results The results for the request
    @property {number} resultCount The total number of results for the request
    @property {string} sort The sort field for the results
    @property {'asc'|'desc'|'none'} sortDirection The sort direction for the results
    @property {Record<string,boolean>} expanded The expanded rows for the results. The key is the ID of the row, and the value is a boolean indicating if the row is expanded
    @property {Record<string,boolean>} selected The selected rows for the results. The key is the ID of the row, and the value is a boolean indicating if the row is selected
    @property {Record<string,boolean|string>} statusDelete The status of the delete for the results. The key is the ID of the row, and the value is a boolean indicating if the row is being deleted, or an error message if the delete failed
    @property {Record<string,boolean|string>} statusUpdate The status of the update for the results. The key is the ID of the row, and the value is a boolean indicating if the row is being updated, or an error message if the update failed
*/

/**
    @template R
    @typedef UseORMResultsHandlers
    @type {Object}
    @property {(count: number) => void} decrementPlanData The handler for decrementing the plan data
    @property {(count: number) => void} incrementPlanData The handler for incrementing the plan data
    @property {(R|R[]) => Promise<any>|void} onUpdate The handler for updating the results. This can be a single result, or an array of results, with new properties for each result. The new properties will be merged with the existing properties
    @property {(R|R[]) => Promise<any>|void} onDelete The handler for deleting the results. This can be a single result, or an array of results.
    @property {(filters: Object) => void} onFilterChange The handler for changing a filter or multiple filters
    @property {(filters: Object) => void} onFilterConfirm The handler for confirming a filter or multiple filters
    @property {(filters: Record<string,any|any[]) => void} onFilterRemove The handler for removing a filter or multiple filters
    @property {() => void} onFilterReset The handler for resetting the filters
    @property {(page: number) => Promise<any>|void} onPageChange The handler for changing the page
    @property {(page: number) => Promise<any>|void} onPageSizeChange The handler for changing the page size
    @property {(query: string) => void} onSearchChange The handler for changing the search
    @property {(query: string) => Promise<any>|void} onSearchConfirm The handler for confirming the search
    @property {(query: string) => Promise<any>|void} onSearchReset The handler for resetting the search
    @property {(column: string, direction: 'asc'|'desc'|'none') => Promise<any>|void} onSortChange The handler for changing the sort
*/

/**
 * useORMResults
 * @template R
 *
 * @export
 * @param {ORMResultsHookProps<R>} props
 * @param {string|undefined} requestName The name of the request to use for the ORM results when more than one useORMResults hook is used in the same page
 * @returns {[resultsState: UseORMResultsState, resultsHandlers: UseORMResultsHandlers<R>]}
 */
const useORMResults = (props, requestName) => {
    const {
        deleteResultsService,
        fetchResultsService,
        updateResultsService,
        filters: passedFilters = emptyObject,
        id,
        manualRefresh,
        mapFilters,
        onFilterConfirm,
        onSearchConfirm,
        pagination,
        query: passedQuery,
        requestOnLoad = true,
        schema,
        updateQueryString = true,
        useFilterModel = false,
    } = props;

    const uniqueRequestName = useRef(requestName || uniqueId());
    const location = useLocation();
    const reduxDispatch = useDispatch();
    const [initialLoad, setInitialLoad] = useState(false);

    const [state, localDispatch, getState] = useThunkReducer(
        reducer,
        initialState,
        // Provide the query string to the init function, but not to the default props
        state => {
            const queryParams = getParamsFromQueryString(location.search);
            /**
             *  When merging the queryParams with provided props:
             *  - provided items should override query params
             *  - manualRefresh overrides all
             */
            return initState(state, merge(queryParams, props, manualRefresh));
        }
    );
    const dispatch = useCallback(
        action => {
            // Always dispatch Redux actions first to populate ORM before local callbacks
            reduxDispatch(action);
            return localDispatch(action);
        },
        [localDispatch, reduxDispatch]
    );

    const { currentFilters, currentQuery, limit, offset, sort, sortDirection, filterModel } = state;

    const linkOperator =
        filterModel?.items?.length > 1 && Object.keys(currentFilters).length > 1
            ? filterModel.linkOperator
            : undefined;

    const handleRequestResults = useCallback(
        overrideParams => {
            if (canRequestResults(state) && fetchResultsService) {
                const {
                    offset,
                    limit,
                    sort,
                    sortDirection,
                    query: currentQuery,
                    filters: currentFilters,
                } = getState();
                const { offset: currentOffset } = overrideParams || {};
                const update =
                    pagination === 'infinite' && offset !== 0
                        ? 'append'
                        : // If we already paged, then we don't want existing results to disappear
                          offset === currentOffset
                          ? 'replace'
                          : 'persist';
                const meta = {
                    schema,
                    update,
                    requestName: uniqueRequestName.current,
                };

                const params = {
                    id,
                    offset,
                    limit,
                    linkOperator,
                    sort,
                    sortDirection,
                    query: currentQuery,
                    ...overrideParams,
                    filters:
                        (mapFilters && mapFilters(overrideParams?.filters || currentFilters)) ||
                        overrideParams?.filters ||
                        currentFilters,
                };
                dispatch(Actions.fetchResultsBegin(meta, params));
                const requestPromise = fetchResultsService(params) || Promise.resolve();
                try {
                    requestPromise
                        .then(response => {
                            dispatch(Actions.fetchResultsSuccess(meta, params, response));
                            return response;
                        })
                        .catch(error => {
                            const { message } = error;
                            dispatch(Actions.fetchResultsFailure(meta, params, message));
                            throw error;
                        });
                } catch (error) {
                    // Returned value might not be a promise
                    const action = Actions.fetchResultsSuccess(meta, params, requestPromise);
                    dispatch(action);
                }
                return requestPromise;
            }
        },
        [dispatch, id, fetchResultsService, mapFilters, pagination, schema, linkOperator]
    );

    const handleUpdate = useCallback(
        updatedResults => {
            const updatedIds = (castArray(updatedResults) || []).map(result =>
                schema ? schema.idAttribute(result) || result : result
            );
            if (updateResultsService) {
                const params = schema ? schema.idAttribute(id) : id;
                const meta = { schema, id: updatedIds };
                dispatch(Actions.updateBegin(meta, params));
                const requestPromise =
                    updateResultsService({ id, body: updatedResults }) || Promise.resolve();
                try {
                    requestPromise
                        .catch(error => {
                            const { /* code, */ message: errorMessage } = error || {};
                            const { message = errorMessage, roadblocks } = errorMessage || {};
                            dispatch(Actions.updateFailure(meta, params, error));
                            // If there is a roadblock...do not show a snack
                            !roadblocks &&
                                message &&
                                reduxDispatch(UIActions.createSnack(message, { variant: 'error' }));
                            if (errorMessage) {
                                throw errorMessage;
                            }
                        })
                        .then(response => {
                            if (response) {
                                dispatch(Actions.updateSuccess(meta, params, response));
                                return response;
                            }

                            castArray(updatedResults).forEach(result => {
                                const updatedId = schema
                                    ? schema.idAttribute(result) || result
                                    : result;
                                dispatch(
                                    Actions.updateSuccess({ schema, id: updatedId }, params, result)
                                );
                            });
                            return updatedResults;
                        });
                } catch (err) {
                    // Returned value might not be a promise
                    const action = Actions.updateSuccess(meta, params, requestPromise);
                    dispatch(action);
                }
                return requestPromise;
            }
            return Promise.resolve();
        },
        [dispatch, id, updateResultsService, reduxDispatch, schema]
    );

    const handleDelete = useCallback(
        deletedResults => {
            if (deleteResultsService) {
                const deletedIds = (deletedResults || []).map(result =>
                    schema ? schema.idAttribute(result) || result : result
                );
                const params = deletedIds;
                const meta = { schema, id: deletedIds };
                dispatch(Actions.deleteBegin(meta, params));
                const requestPromise = deleteResultsService(deletedIds) || Promise.resolve();
                try {
                    requestPromise
                        .catch(error => {
                            const { /* code, */ message: errorMessage } = error || {};
                            const { message = errorMessage, roadblocks } = errorMessage || {};
                            dispatch(Actions.deleteFailure(meta, params, error));
                            // If there is a roadblock...do not show a snack
                            !roadblocks &&
                                message &&
                                reduxDispatch(UIActions.createSnack(message, { variant: 'error' }));
                            if (errorMessage) {
                                throw errorMessage;
                            }
                        })
                        .then(response => {
                            dispatch(Actions.deleteSuccess(meta, params, response));
                            return response;
                        });
                } catch (error) {
                    // Returned value might not be a promise
                    const action = Actions.deleteSuccess(meta, params, requestPromise);
                    dispatch(action);
                }
                return requestPromise;
            }
            return Promise.resolve();
        },
        [dispatch, deleteResultsService, reduxDispatch, schema]
    );

    const handlePageSizeChange = useCallback(
        limit => localDispatch(setLimit(limit)),
        [localDispatch]
    );
    const handlePageChange = useCallback(page => localDispatch(setPage(page)), [localDispatch]);
    const handleSortChange = useCallback(
        (column, direction) => localDispatch(setSort(column, direction)),
        [localDispatch]
    );

    const handleSearchReset = useCallback(() => {
        localDispatch(resetSearch(passedQuery));
        onSearchConfirm && onSearchConfirm(passedQuery);
    }, [localDispatch, onSearchConfirm, passedQuery]);

    // Filters and Search can update without submit
    const handleSearchChange = useCallback(
        newQuery => {
            localDispatch(updateSearch(newQuery));
        },
        [localDispatch]
    );

    // Filters and Search will confirm BEFORE update, so they need to set here too
    const handleSearchConfirm = useCallback(
        newQuery => {
            localDispatch(confirmSearch(newQuery));
            onSearchConfirm && onSearchConfirm(newQuery);
        },
        [localDispatch, onSearchConfirm]
    );

    // ## Filters
    const handleFilterChange = useCallback(
        (newFilters, filterModel) => {
            localDispatch(updateFilterModel(filterModel));
            localDispatch(updateFilters(newFilters));
        },
        [localDispatch]
    );

    /**
     * @param {MappedFilters} mappedFilters
     * @returns
     */
    const handleFilterConfirm = useCallback(
        mappedFilters => {
            localDispatch(confirmFilters(mappedFilters, passedFilters));
            onFilterConfirm && onFilterConfirm(mappedFilters);
        },
        [localDispatch, onFilterConfirm, passedFilters]
    );

    const handleFilterReset = useCallback(() => {
        localDispatch(resetFilters(passedFilters));
        onFilterConfirm && onFilterConfirm(passedFilters);
    }, [localDispatch, passedFilters, onFilterConfirm]);

    const handleFilterRemove = useCallback(
        mappedFilters => {
            const { currentFilters } = getState();
            const newFilters = { ...currentFilters };
            // This is only called when you remove single filters at a time
            Object.entries(mappedFilters).forEach(([key, valueToBeRemoved]) => {
                if (isArray(newFilters[key])) {
                    newFilters[key] = newFilters[key].filter(
                        value => value !== valueToBeRemoved && value?.value !== valueToBeRemoved
                    );
                } else if (typeof newFilters[key] === 'boolean' && newFilters[key] === true) {
                    delete newFilters[key];
                } else if (newFilters[key] === valueToBeRemoved) {
                    delete newFilters[key];
                }
            });
            handleFilterConfirm(newFilters);
        },
        [handleFilterConfirm]
    );

    // ## PlanData
    const incrementPlanData = useCallback(
        count => localDispatch(Actions.incrementPlanData(count)),
        [localDispatch]
    );
    const decrementPlanData = useCallback(
        count => localDispatch(Actions.decrementPlanData(count)),
        [localDispatch]
    );

    // TODO: we should add some validation to ensure `updateQueryString`
    // never changes for any one mounted component and thus this hook
    // is either *always* called or *never* called.
    updateQueryString &&
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useQueryStringUpdate({
            // 'autocomplete' filters may have the form { value: 'foo', label: 'Foo' }
            // so we need to extract the value before passing it to the query string
            filters: Object.entries(currentFilters).reduce((acc, [key, value]) => {
                const isMultiFilter =
                    typeof value === 'object' &&
                    Object.prototype.hasOwnProperty.call(value, 'operatorValue');

                if (isMultiFilter) {
                    let { columnField, operatorValue, value: filterValue } = value;

                    // Don't add empty values to the query string
                    if (!filterValue || (Array.isArray(filterValue) && filterValue.length === 0)) {
                        return acc;
                    }

                    if (isArray(filterValue)) {
                        filterValue = filterValue
                            .map(filter =>
                                typeof filter === 'object' && filter.value ? filter.value : filter
                            )
                            .join(',');
                    }

                    if (acc[columnField]) {
                        acc[columnField].push(`${operatorValue}:${filterValue}`);
                    } else {
                        acc[columnField] = [`${operatorValue}:${filterValue}`];
                    }
                } else if (isArray(value)) {
                    acc[key] = value.map(v => v?.value || v);
                } else {
                    acc[key] = value?.value || value;
                }
                return acc;
            }, {}),
            query: currentQuery,
            limit,
            offset,
            sort,
            sortDirection,
            linkOperator,
        });

    useEffect(() => {
        initialLoad && handleRequestResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilters, currentQuery, offset, limit, sort, sortDirection]);

    useEffect(() => {
        initialLoad &&
            handleRequestResults(typeof manualRefresh === 'object' ? manualRefresh : undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualRefresh]);

    useEffect(
        () => {
            !initialLoad && requestOnLoad && handleRequestResults();
            setInitialLoad(true);
        },
        /* ONLY ON FIRST LOAD */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return [
        {
            ...state,
            filterModel: useFilterModel ? filterModel : undefined,
        },
        {
            decrementPlanData,
            incrementPlanData,
            // Handlers
            onUpdate: handleUpdate,
            onDelete: handleDelete,
            onFilterChange: handleFilterChange,
            onFilterConfirm: handleFilterConfirm,
            onFilterRemove: handleFilterRemove,
            onFilterReset: handleFilterReset,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            onSearchChange: handleSearchChange,
            onSearchConfirm: handleSearchConfirm,
            onSearchReset: handleSearchReset,
            onSortChange: handleSortChange,
        },
    ];
};

export default useORMResults;
