import { Grid, Typography } from '@mui/material';

import Locale from '/b2b/common/components/Locale';
import PropTypes from 'prop-types';
import React from 'react';
import { deepKoamaruLight } from '/b2b/common/colors';
import moment from 'moment';

export const Greeting = ({ firstName }) => {
    const currentHour = moment().format('HH');

    let greeting = 'views.Dashboard.greeting.default';

    if (currentHour < 12) {
        greeting = 'views.Dashboard.greeting.morning';
    } else if (currentHour >= 12 && currentHour < 18) {
        greeting = 'views.Dashboard.greeting.afternoon';
    } else if (currentHour >= 18) {
        greeting = 'views.Dashboard.greeting.evening';
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography color={deepKoamaruLight} variant="body1">
                    {moment().format('dddd, MMMM D')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography fontWeight={500} variant="h1">
                    <Locale path={greeting} options={{ name: firstName }} />
                </Typography>
            </Grid>
        </Grid>
    );
};

Greeting.propTypes = {
    firstName: PropTypes.string.isRequired,
};

export default Greeting;
