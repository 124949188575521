/* eslint-disable camelcase */
import {
    TYPE_ON_PREM_DATASET,
    TYPE_ON_PREM_FIELD,
    TYPE_ON_PREM_SCHEMA,
    TYPE_SAAS_DATASET,
    TYPE_SAAS_DATASET_ALT,
    TYPE_SAAS_FIELD,
    TYPE_SAAS_FIELD_SET,
    TYPE_SAAS_SCHEMA,
} from '/b2b/dataStores/constants';

import castArray from 'lodash/castArray';
import { formatUser } from '/b2b/users/format';
import { makeResultsFormatter } from '../common/services/helpers';
import set from 'lodash/set';
import snakeCase from 'lodash/snakeCase';

export const formatDataProvider = data => {
    const {
        provider_id,
        providerId = provider_id,
        provider_name,
        name = provider_name,
        provider_description,
        description = provider_description,
        provider_type = 'saas',
        type = provider_type, //can be 'saas', 'internal', or 'onprem'
    } = data;

    return {
        providerId,
        name,
        description,
        type,
    };
};
export const formatDataProviderResults = makeResultsFormatter(formatDataProvider);

export const formatDataStoreAgent = data => {
    const {
        agent_id,
        agentId = agent_id,
        agent_name,
        name = agent_name,
        agent_description,
        description = agent_description,
        agent_type,
        type = agent_type,
        agent_version,
        version = agent_version,
        agent_schema,
        schema = agent_schema,
        agent_created,
        agent_on_prem, // Deprecated, use provider_type
        created = agent_created,
        agent_updated,
        updated = agent_updated,

        provider_id,
        provider_name,
        provider_description,
        provider_type,
        provider: providedProvider,
        documentation_url,
        documentationUrl = documentation_url,
        onPrem = agent_on_prem,
    } = data;

    const provider = formatDataProvider({
        providerId: provider_id,
        name: provider_name,
        description: provider_description,
        type: provider_type,
        ...providedProvider,
    });

    return {
        agentId,
        type,
        // TODO: remove post DISCO / DSAR OKR
        authType: type,
        name,
        description,
        schema,
        version,
        onPrem: onPrem || provider.type === 'onprem',
        created,
        updated,
        provider,
        documentationUrl,
    };
};
export const formatDataStoreAgentResults = makeResultsFormatter(formatDataStoreAgent);

export const formatVendorRelationships = (data = []) =>
    data?.map(
        ({
            association_type: associationType,
            email,
            vendor_association_id: associationId,
            vendor_id: vendorId,
            vendor_name: vendorName,
        }) => ({
            associationId,
            associationType,
            email,
            vendorId,
            vendorName,
        })
    );

export const formatDataStore = data => {
    const {
        actions_to_complete = [],
        actionsToComplete = actions_to_complete,

        agent_id,
        agent_name,
        agent_description,
        agent_version,
        agent_schema,
        agent_on_prem,
        agent_created,
        agent_updated,
        agent_type,
        agent: providedAgent,

        assessments,

        provider_id,
        provider_name,
        provider_description,
        provider_type,
        provider: providedProvider,

        customer_id,
        customerId = customer_id,

        connection_id,
        connectionId = connection_id,
        connection_description,
        description = connection_description,
        connection_name,
        name = connection_name,
        connection_nickname,
        nickname = connection_nickname,
        connection_last_agent_version,
        lastAgentVersion = connection_last_agent_version,
        connection_status,
        status = connection_status,
        connection_status_message,
        statusMessage = connection_status_message,
        connection_last_sync,
        lastSync = connection_last_sync,
        connection_samples_url,
        samplesUrl = connection_samples_url,
        connection_total_fields,
        totalFieldCount = connection_total_fields,
        connection_unclassified_fields,
        unclassifiedFieldCount = connection_unclassified_fields,
        connection_credentials,
        credentials = connection_credentials,
        connection_created,
        created = connection_created,
        connection_updated,
        updated = connection_updated,
        connection_owners = [],
        connection_primary_owners = [],
        owners: providedAssignees = connection_owners,
        primaryOwners: providedOwners = connection_primary_owners,
        automation_preferences,
        automationPreferences = automation_preferences,
        automated_summary_eligible,
        automatedSummaryEligible = automated_summary_eligible,
        automated_deletion_eligible,
        automatedDeletionEligible = automated_deletion_eligible,
        provider_search_enabled,
        isProviderSearchEnabled = provider_search_enabled,
        provider_deletion_enabled,
        isProviderDeletionEnabled = provider_deletion_enabled,
        product,
        vendor,
        vendor_associations = [],
        vendorRelationships = vendor_associations,
        sso_applications = [],
        ssoApplications = sso_applications,
        source_connections = [],
        sourceConnections = source_connections,
        linked_apps,
        linkedApps = linked_apps,
        organizations = [],
        organization_owners = [],
        organizationOwners = organization_owners,
        location = {},
        labels = [],
        privacy_risk_score,
        privacyRiskScore = privacy_risk_score,
        purposes_of_processing = [],
        purposesOfProcessing = purposes_of_processing,
        assessment_fields = [],
        assessmentFields = assessment_fields,
    } = data;

    const provider = {
        providerId: provider_id,
        name: provider_name,
        description: provider_description,
        type: provider_type,
        ...providedProvider,
    };

    const agent = {
        agentId: agent_id,
        name: agent_name,
        description: agent_description,
        authType: agent_type, // TODO: Deprecated, please remove after Data Store migration
        type: agent_type,
        version: agent_version,
        schema: agent_schema,
        onPrem: agent_on_prem,
        created: agent_created,
        updated: agent_updated,
        provider,
        ...providedAgent,
    };

    const lookup = {};
    const assignees = castArray(providedAssignees)
        .filter(o => {
            const { email } = o || {};
            const isUnique = email && !lookup[`${email}`];
            if (isUnique) {
                lookup[`${email}`] = true;
                return true;
            }
            return false;
        })
        .map(formatUser);

    const primaryOwners = assignees.filter(({ is_primary }) => is_primary);
    const owners = providedOwners.map(formatUser);
    const orgOwners = organizationOwners.map(formatUser);
    const defaultAssignees = primaryOwners;
    return {
        actionsToComplete,
        agent: formatDataStoreAgent(agent),
        assessments,
        assessmentFields,
        assignees,
        customer: customerId,
        connectionId,
        created,
        credentials,
        defaultAssignees: defaultAssignees ? formatUser(defaultAssignees) : orgOwners,
        description,
        lastAgentVersion,
        lastSync,
        linkedApps,
        labels,
        location,
        name,
        nickname,
        organizations,
        organizationOwners: orgOwners,
        owners,
        primaryOwners,
        privacyRiskScore,
        purposesOfProcessing,
        samplesUrl,
        ssoApplications,
        sourceConnections,
        status,
        statusMessage,
        totalFieldCount: parseInt(totalFieldCount, 10) || 0,
        unclassifiedFieldCount,
        updated,
        automationPreferences,
        automatedSummaryEligible,
        automatedDeletionEligible,
        isProviderSearchEnabled,
        isProviderDeletionEnabled,
        product,
        vendor,
        vendorRelationships: formatVendorRelationships(vendorRelationships),
    };
};

export const formatDataStoreResults = makeResultsFormatter(formatDataStore);

const formatDataStoreConnectionSamplesLegacy = schema => {
    const parsedSchema = {};

    function traverse(item, path) {
        if (item) {
            if (Array.isArray(item)) {
                item.forEach(value => traverse(value, path));
            } else if (typeof item === 'object') {
                const { data, metadata = {} } = item;
                const { dataType, name, piiType, samples, type } = metadata;

                const isSchema = [TYPE_ON_PREM_SCHEMA, TYPE_SAAS_SCHEMA].includes(type);
                const isDataset = [
                    TYPE_ON_PREM_DATASET,
                    TYPE_SAAS_DATASET,
                    TYPE_SAAS_DATASET_ALT,
                    TYPE_SAAS_FIELD_SET,
                ].includes(dataType);
                const isField = [TYPE_ON_PREM_FIELD, TYPE_SAAS_FIELD].includes(type) || !!piiType;

                if (isSchema || isDataset || isField) {
                    path = [...path.split('.'), snakeCase(name)].filter(Boolean).join('.');

                    if (isField) {
                        return set(parsedSchema, path, samples || []);
                    }
                }

                return traverse(data, path);
            }
        }
    }

    traverse(schema, '');

    return parsedSchema;
};

export const formatDataStoreConnectionSamples = schema => {
    if (Object.prototype.hasOwnProperty.call(schema, 'data') && Array.isArray(schema['data'])) {
        // to be removed once objects are reformatted
        return formatDataStoreConnectionSamplesLegacy(schema);
    } else {
        return schema;
    }
};

export const formatDataStoreClassification = classification => classification;
export const formatDataStoreClassificationResults = makeResultsFormatter(
    formatDataStoreClassification
);

export const formatDataStoreRequestAction = requestAction => {
    const { id = requestAction, name, ...remain } = requestAction;
    return {
        id,
        name,
        ...remain,
    };
};
export const formatDataStoreRequestActionResults = makeResultsFormatter(
    formatDataStoreRequestAction
);

export const formatDataStoreFieldRequestAction = requestAction => {
    const {
        different_from_default = false,
        request_action_is_default = !different_from_default,
        isDefault = request_action_is_default,
        request_action_id,
        requestActionId = request_action_id,
        request_type_id,
        requestTypeId = request_type_id,
        ...remain
    } = requestAction;
    return {
        requestType: requestTypeId,
        requestAction: requestActionId,
        isDefault,
        ...remain,
    };
};

const formatDefaultRequestTypeAction = requestTypeAction => {
    const {
        classification_id,
        classificationId = classification_id,
        classification_name,
        classificationName = classification_name,
        request_action,
        requestAction = request_action,
        request_action_id,
        requestActionId = request_action_id,
        request_action_is_default,
        isDefault = request_action_is_default,
        request_action_name,
        requestActionName = request_action_name,
        request_type,
        requestType = request_type,
        request_type_id,
        requestTypeId = request_type_id,
        request_type_name,
        requestTypeName = request_type_name,
        default_request_action_id,
        defaultRequestActionId = default_request_action_id,
        default_request_action,
        defaultRequestAction = default_request_action,
        default_request_action_name,
        defaultRequestActionName = default_request_action_name,
    } = requestTypeAction;

    return {
        classificationId,
        classificationName,
        defaultRequestAction,
        defaultRequestActionId,
        defaultRequestActionName,
        requestAction,
        requestActionId,
        requestActionName,
        requestType,
        requestTypeId,
        requestTypeName,
        isDefault,
    };
};

export const formatDefaultRequestTypeActionResults = makeResultsFormatter(
    formatDefaultRequestTypeAction
);

export const formatDataStoreField = data => {
    const {
        field_id,
        fieldId = field_id,
        field_name,
        name = field_name,
        field_notes,
        notes = field_notes,
        field_classifications,
        classifications = field_classifications,
        field_classification = classifications[0],
        classification = field_classification,
        field_request_actions,
        request_actions = field_request_actions,
        requestActions = request_actions,
        field_samples_path,
        samplesPath = field_samples_path,
        field_description,
        description = field_description,
        field_path,
        path = field_path,
        field_manually_classified,
        manuallyClassified = field_manually_classified,
        field_data_found,
        dataFound = field_data_found,
        field_classified_by,
        classifiedBy = field_classified_by,
        field_classified_date,
        classifiedDate = field_classified_date,
        field_agent_classification,
        agentClassification = field_agent_classification,
        field_regex_classification,
        regexClassification = field_regex_classification,
        field_content_classification,
        contentClassification = field_content_classification,
        field_content_classification_scores,
        contentClassificationScores = field_content_classification_scores,
        ...remain
    } = data;

    return {
        ...remain,
        agentClassification,
        classification: formatDataStoreClassification(classification),
        classifiedBy,
        classifiedDate,
        contentClassification,
        contentClassificationScores,
        dataFound,
        description,
        fieldId,
        manuallyClassified,
        name,
        notes,
        path,
        regexClassification,
        // You should not default to an empty array or it will erase the requestActions in the ORM
        requestActions: requestActions && requestActions.map(formatDataStoreFieldRequestAction),
        samplesPath,
    };
};
export const formatDataStoreFieldResults = makeResultsFormatter(formatDataStoreField);

const formatDataStoreRelationships = ({ targetName, ...data }) => ({
    ...data,
    targetName,
    name: targetName,
});

export const formatDataStoreRelationshipsResults = makeResultsFormatter(
    formatDataStoreRelationships
);
