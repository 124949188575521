/* eslint-disable camelcase */
// @ts-check
import React from 'react';

/** @type {React.Context<import('/b2b/common/hoc/withAsyncData').HocAsyncDataContext<CmpReportsData>>}>} */
export const ConsentDashboardContext = React.createContext({
    loading: false,
    error: null,
    load: () => Promise.reject(new Error('Not implemented')),
    data: {
        errors: {},
        dateRange: [0, 0],
        platformVisitorRange: [0, 0],
        platformConsentRange: [0, 0],
        timeVisitorRange: [0, 0],
        timeConsentRange: [0, 0],
        consentRange: [0, 0],
        totalRange: [0, 0],
        classificationByConfig: { results: [], paging: { pageNumber: 1, pageSize: 25, total: 0 } },
        consentsByJurisdiction: [],
        consentsByPlatform: [],
        consentsOverTime: [],
        consentsByCategory: [],
        stats: {
            unpublishedChanges: 0,
            unclassifiedDiscoveries: 0,
            consentsRolling: 0,
            consentsRollingPrior: 0,
            consentsRollingPercentChange: 0,
            optOutRolling: 0,
            optOutRollingPrior: 0,
            optOutRollingPercentChange: 0,
        },
        traffic: [],
    },
});

export default ConsentDashboardContext;
