import {
    DSAR_DETAILS_FIELD_IDS,
    DSAR_DISPLAY_OPTIONS,
    DSAR_LEGACY_FIELD_IDS,
    DSAR_REQUEST_TYPE_VERIFICATION_LEVELS,
    DSAR_STANDARD_FIELDS_I18N,
} from '/b2b/dsarForms/constants';

import castArray from 'lodash/castArray';
import { dsarEmailUrl } from '@osano-b2b';
import { formatOrganizations } from '../organizations/format';
import { htmlDecode } from '../common/helpers/String';
import { makeResultsFormatter } from '../common/services/helpers';
import { v4 as uuid } from 'uuid';
import { validateEmail } from '../common/helpers/Email';

const { IDENTIFICATION_FILE, REQUEST_TYPE } = DSAR_DETAILS_FIELD_IDS;
const { EMAIL_AND_IDENTITY } = DSAR_REQUEST_TYPE_VERIFICATION_LEVELS;

export const formatIdVerificationField = (field, requestTypes = []) => {
    const verificationLevels = requestTypes.map(
        ({ verification_level, verificationLevel = verification_level }) => verificationLevel
    );

    const idVerifyAllRequestTypes = verificationLevels.every(level => level === EMAIL_AND_IDENTITY);
    const idVerifySomeRequestTypes =
        verificationLevels.some(level => level === EMAIL_AND_IDENTITY) && !idVerifyAllRequestTypes;
    const idVerifyNoRequestTypes = !verificationLevels.some(level => level === EMAIL_AND_IDENTITY);

    if (idVerifyAllRequestTypes) {
        // unexclude identity verification field and delete conditional
        delete field.conditional;
        field.excluded = false;
        field.displayOption = DSAR_DISPLAY_OPTIONS.DEFAULT;
    } else if (idVerifySomeRequestTypes) {
        // unexclude identity verification field and add conditional request type ids
        const displayOptionRequestTypes = requestTypes
            .filter(
                ({ verification_level, verificationLevel = verification_level } = {}) =>
                    verificationLevel === EMAIL_AND_IDENTITY
            )
            .map(
                ({
                    request_type_id,
                    requestTypeId: value = request_type_id,
                    name: label,
                } = {}) => ({
                    disabled: true,
                    label,
                    value,
                })
            );

        field.excluded = false;
        field.conditional = {
            id: REQUEST_TYPE,
            value: displayOptionRequestTypes.map(({ value }) => value),
        };
        field.displayOption = DSAR_DISPLAY_OPTIONS.ENABLED;
        field.displayOptionRequestTypes = displayOptionRequestTypes;
    } else if (idVerifyNoRequestTypes) {
        // exclude identity verification field and delete conditional
        delete field.conditional;
        field.excluded = true;
        field.displayOption = DSAR_DISPLAY_OPTIONS.DISABLED;
    }

    return field;
};

export const formatDataStoresForForm = (dataStores = []) => {
    return dataStores.map(({ name, connection_id: connectionId, value, ...remain }) => ({
        name,
        connectionId: connectionId ?? value,
        ...remain,
    }));
};

export const formatDSARForm = data => {
    const {
        active,
        allowListEmails = [],
        allowLocationSelect,
        customFields,
        customerId,
        selectedDataStores = [],
        dataStoreCount = 0,
        description,
        escalationEmails = [],
        form_id,
        formId = form_id,
        fromName,
        fromCompany,
        generatedInbox,
        identityVerify,
        completionContent,
        name,
        requestTypes = [],
        styling,
        orgIds = [],
        organizations = orgIds,
        geoFencing,
        duplicateReject,
        unverifiedEmailReject,
        internalDueDays = null,
        templates = [],
    } = data;

    const requiredFields = { ...DSAR_STANDARD_FIELDS_I18N };
    const defaultFields = Object.entries(requiredFields).map(([key, val]) => ({ ...val, id: key }));
    const newCustomFields = Object.entries(
        customFields?.length
            ? // TODO: we should be able to remove this filter once we purge these ids from the database
              customFields.filter(({ id }) => !DSAR_LEGACY_FIELD_IDS.includes(id))
            : defaultFields
    ).map(([key, value]) => {
        let val = typeof value === 'object' ? { ...value } : {};
        let { name = key, id } = val;

        if (id === IDENTIFICATION_FILE) {
            val = formatIdVerificationField(val, requestTypes);
        } else {
            Object.entries(val).forEach(([key, entry]) => {
                if (key === 'excluded' && entry === true) {
                    val.displayOption = DSAR_DISPLAY_OPTIONS.DISABLED;
                }
                if (key === 'conditional' && entry.id === REQUEST_TYPE && entry.value) {
                    const displayOptionRequestTypes = castArray(entry.value)
                        .map(val => {
                            const requestType = requestTypes.find(rt => rt.request_type_id === val);
                            return requestType
                                ? {
                                      label: requestType.name,
                                      value: val,
                                  }
                                : null;
                        })
                        .filter(Boolean);
                    val.displayOption = displayOptionRequestTypes.length
                        ? DSAR_DISPLAY_OPTIONS.ENABLED
                        : DSAR_DISPLAY_OPTIONS.DEFAULT;
                    val.displayOptionRequestTypes = displayOptionRequestTypes;
                }
            });
            if (!val.displayOption) {
                val.displayOption = DSAR_DISPLAY_OPTIONS.DEFAULT;
            }
        }

        if (requiredFields[name] && name.indexOf('o-') !== 0) {
            const mergedFields = {
                id: name,
                ...requiredFields[name],
                ...val,
            };
            delete requiredFields[name];
            return mergedFields;
        }
        if (name.indexOf('o-') !== 0) {
            name = `o-${name}`;
        }

        return {
            ...val,
            name,
            id: id || uuid('customFormField'),
        };
    });
    const fields = [
        ...newCustomFields,
        ...Object.entries(requiredFields).map(([name, field]) => ({
            name,
            id: name,
            ...field,
        })),
    ].reduce((fields, field, index) => {
        const { id } = field;
        fields[id] = { ...field, order: index };
        return fields;
    }, {});

    const customRequestTypes = requestTypes.map(
        ({
            request_type_id: requestTypeId,
            request_type: requestType,
            jurisdiction_exists: jurisdictionExists,
            verification_level: verificationLevel,
            ...remain
        }) => ({
            id: requestTypeId,
            requestTypeId,
            requestType,
            jurisdictionExists,
            verificationLevel,
            ...remain,
        })
    );

    const formattedRequestTypes = customRequestTypes.reduce((types, type) => {
        const { id } = type;
        types[id] = { ...type };
        return types;
    }, {});

    return {
        active,
        allowListEmails,
        allowLocationSelect,
        customer: customerId,
        customFields: newCustomFields,
        customRequestTypes,
        dataStoreCount,
        selectedDataStores: formatDataStoresForForm(selectedDataStores),
        defaultSettings:
            dataStoreCount && selectedDataStores?.length > 0
                ? 'selectedDataStores'
                : 'allDataStores',
        description,
        escalationEmails,
        geoFencing,
        duplicateReject,
        unverifiedEmailReject,
        internalDueItems: !internalDueDays ? 'internalDueDefault' : 'internalDueDays',
        internalDueDays,
        organizations: formatOrganizations(organizations),
        fields,
        formId,
        fromName,
        fromCompany: htmlDecode(fromCompany),
        generatedInbox: validateEmail(generatedInbox)
            ? generatedInbox
            : `${customerId}_${formId}@${dsarEmailUrl}`,
        name: htmlDecode(name),
        identityVerify,
        completionContent,
        requestTypes: formattedRequestTypes,
        styling,
        templates,
    };
};
export const formatDSARFormResults = makeResultsFormatter(formatDSARForm);
