const { LegendIcon } = require('/b2b/common/components/Charts');

import camelCase from 'lodash/camelCase';
import moment from 'moment';

export const FILTER_KEYS = ['dateRange', 'forms', 'jurisdictions', 'requestTypes'];

export const BAR_CHART_TOOLTIP = {
    confine: true,
    trigger: 'axis',
    axisPointer: {
        type: 'none',
    },
    formatter(params) {
        // For stacked bar charts, calculate the total and percentage for each series
        if (params.length > 1) {
            const values = {};
            let total = 0;
            let interval;

            params.forEach(param => {
                const valueKey = camelCase(param.seriesName);
                const value = param.data[valueKey];
                values[valueKey] = value;
                total += value;
                interval = param?.data?.interval || 'day';
            });

            // Format the date, check if dates are truncated by day, month, or year
            let dateFormat = 'MMM, YYYY';
            switch (interval) {
                case 'day':
                    dateFormat = 'MMM D, YYYY';
                    break;
                case 'month':
                    dateFormat = 'MMM, YYYY';
                    break;
                case 'year':
                    dateFormat = 'YYYY';
                    break;
            }

            let date;
            try {
                date = moment(params[0].axisValueLabel).format(dateFormat);
            } catch {
                date = params[0].axisValueLabel;
            }

            let tooltipText = `<b>${date} (${total})</b><br/>`;

            params.forEach(param => {
                const valueKey = camelCase(param.seriesName);
                const value = values[valueKey];
                const percent = total > 0 ? ((value / total) * 100).toFixed(0) : 0;
                tooltipText += `${param.marker} ${param.seriesName}: <b>${percent}%</b> (${value})<br/>`;
            });

            return tooltipText;
        }

        // If there's only one item, use the default formatter
        return `<b>${params[0].axisValueLabel}</b><br/>${params[0].marker} ${params[0].seriesName}: <b>${params[0].value}</b>`;
    },
};

export const COMMON_OPTIONS = {
    grid: {
        containLabel: true,
    },
    legend: {
        icon: LegendIcon,
        orient: 'horizontal',
        top: 'top',
    },
    xAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
    },
    yAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
    },
    useUTC: true,
};

export const TIME_XAXIS_OPTIONS = {
    xAxis: {
        type: 'time',
        axisLabel: {
            interval: 'auto',
            hideOverlap: true,
            formatter: {
                day: '{MMM}, {dd}',
                month: '{MMM}, {yyyy}',
                year: '{yyyy}',
            },
        },
    },
};

export const VERTICAL_YAXIS_OPTIONS = {
    grid: {
        containLabel: true,
    },
    yAxis: {
        minInterval: 1,
        nameLocation: 'middle',
        nameTextStyle: {
            fontWeight: 'bold',
        },
    },
};
