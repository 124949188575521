const { EChart, LegendIcon } = require('/b2b/common/components/Charts');

import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';

import DashboardContext from '../Dashboard.context';
import PropTypes from 'prop-types';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchUcReportsDashboard } from '/b2b/uc/services';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'changedConsents';

const staticOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'none',
        },
    },
    legend: {
        icon: LegendIcon,
        orient: 'horizontal',
        top: 'top',
    },
    grid: {
        containLabel: true,
    },
    xAxis: {
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        type: 'value',
    },
    yAxis: {
        type: 'category',
        axisLabel: { align: 'left', inside: true, overflow: 'break' },
        axisLine: { onZero: false, show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        boundaryGap: false,
    },
    series: [
        {
            name: 'uc.option.defaultAction.accept',
            type: 'bar',
            stack: 'total',
            label: {
                align: 'right',
                position: 'insideRight',
                show: true,
                formatter: params => {
                    const { dimensionNames, value } = params;
                    return Math.abs(value[dimensionNames[1]]);
                },
            },
            emphasis: {
                focus: 'series',
            },
            tooltip: {
                valueFormatter: value => Math.abs(value), // Make the value positive
            },
            dimensions: [
                { name: 'privacyProtocolId', type: 'ordinal' },
                { name: 'totalSwitchedApproved', type: 'int' },
            ],
        },
        {
            name: 'uc.option.defaultAction.reject',
            type: 'bar',
            stack: 'total',
            label: {
                align: 'left',
                position: 'insideLeft',
                show: true,
            },
            emphasis: {
                focus: 'series',
            },
            dimensions: [
                { name: 'privacyProtocolId', type: 'ordinal' },
                { name: 'totalSwitchedDenied', type: 'int' },
            ],
        },
    ],
};

const csvColumnMap = {
    name: 'common.fields.name.label',
    privacyProtocolId: 'uc.label.protocol',
    totalSwitchedApproved: 'uc.option.defaultAction.accept',
    totalSwitchedDenied: 'uc.option.defaultAction.reject',
};

const processRow = (row, columnNames, privacyProtocolNames) => {
    return columnNames.reduce((acc, column) => {
        if (column === 'name') {
            acc.push(privacyProtocolNames[row.privacyProtocolId]);
        } else {
            acc.push(row[column]);
        }
        return acc;
    }, []);
};

function makeCSV(data) {
    const { changedConsents, privacyProtocolNames } = data || {};
    const columnNames = Object.keys(csvColumnMap);
    // String CSV
    const csvFile = Object.values(changedConsents)
        .reduce(
            (acc, row) => {
                const rowArray = processRow(row, columnNames, privacyProtocolNames);
                acc.push(rowArray.join(','));
                return acc;
            },
            [columnNames.map(column => translate(csvColumnMap[column])).join(',')]
        )
        .join('\n');
    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

const prepareData = data => {
    // This is where we would prepare the data if we needed to
    return data;
};

const ChangedConsents = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data, error, load, loading } = useContext(DashboardContext);
    const { changedConsents = [], privacyProtocolNames } = data || {};
    const theme = useTheme();
    const noResults = changedConsents.length === 0;
    const isLoading = loading || providedLoading;
    const getProtocolName = useBoundCallback(
        (privacyProtocolNames, id) => {
            return privacyProtocolNames[`${id}`] || id;
        },
        [privacyProtocolNames]
    );

    const memoOption = useMemo(
        () =>
            merge({}, staticOptions, {
                tooltip: {
                    formatter(params) {
                        const { axisValueLabel } = params[0];
                        // eslint-disable-next-line xss/no-mixed-html
                        return `<b>${getProtocolName(axisValueLabel)}</b><hr style="margin:0.25em 0"/>${params
                            .map(({ dimensionNames, marker, seriesName, value }) => {
                                const count = value[dimensionNames[1]];
                                return `${marker} ${translate(seriesName)}: ${Math.abs(count)}`;
                            })
                            .join('<br>')}`;
                    },
                },
                legend: {
                    left: theme.spacing(1.5),
                },
                grid: {
                    left: 128 + parseInt(theme.spacing(4), 10),
                    right: parseInt(theme.spacing(2), 10),
                },
                yAxis: {
                    offset: 128 + parseInt(theme.spacing(4), 10),
                    axisLabel: {
                        formatter: getProtocolName,
                        margin: parseInt(theme.spacing(2), 10),
                        width: 128 + parseInt(theme.spacing(2), 10),
                    },
                },
                dataset: {
                    source: changedConsents.map(({ totalSwitchedApproved, ...remain }) => ({
                        ...remain,
                        // Invert the value so the bar is facing the correct direction
                        totalSwitchedApproved: totalSwitchedApproved * -1,
                    })),
                },
                series: staticOptions.series.map(series => {
                    return {
                        ...series,
                        name: translate(series.name),
                    };
                }),
            }),
        [changedConsents, shouldRetranslate]
    );

    /** @type {() => Promise<UcReportsData>} */
    const fetchData = useBoundCallback(
        filters =>
            fetchUcReportsDashboard({
                filters: { ...filters, requestedCharts: [REPORT_NAME] },
            }).then(prepareData),
        [filters]
    );

    const echartsInstance = useRef(null);
    const helpers = useMemo(
        () => ({
            makeCSV,
            name: 'changed-consents',
        }),
        []
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(prepareData(providedData))) : load(fetchData());
    }, [providedData, filters]);

    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={['csv', 'png']}
            echartsInstance={echartsInstance}
            error={error}
            title="uc.section.dashboards.title.changedConsents"
            tooltip="uc.section.dashboards.tooltip.changedConsents"
        >
            <EChart
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </WidgetContainer>
    );
};

ChangedConsents.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    filters: PropTypes.object,
    shouldRetranslate: PropTypes.string.isRequired,
};

export default withAsyncData(withRetranslate(ChangedConsents), DashboardContext);
