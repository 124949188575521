import {
    crudPagingReducers,
    crudStatusByIdReducer,
    genericReducer,
    passthroughReducer,
} from '../../ReduxHelpers';

import { types as ActionTypes } from './ORMResults.actions';
import { GridLinkOperator } from '@mui/x-data-grid';
import { combineReducers } from 'redux';
import difference from 'lodash/difference';
import reduceReducers from 'reduce-reducers';

const emptyArray = [];

export const initialState = {
    currentFilters: {},
    currentQuery: '',
    error: null,
    filterModel: {
        items: [],
        linkOperator: GridLinkOperator.And,
    },
    filters: {},
    limit: 25,
    loading: false,
    offset: 0,
    planData: {},
    query: '',
    requireSearch: false,
    results: emptyArray,
    resultCount: 0,
    sort: '',
    sortDirection: 'none',
    expanded: {},
    selected: {},
    statusDelete: {},
    statusUpdate: {},
};

export const createSearchParams = params => {
    const { filterModel, filters, limit, offset, query, sort, sortDirection } = params;
    const mergedFilters = {
        ...initialState.filters,
        ...(filters || {}),
    };
    const queryString = typeof query === 'string' ? query : '';
    const intLimit = parseInt(limit, 10);
    const intOffset = parseInt(offset, 10);
    return {
        filterModel: filterModel || initialState.filterModel,
        filters: mergedFilters,
        limit: !isNaN(intLimit) ? intLimit : initialState.limit,
        offset: !isNaN(intOffset) ? Math.max(0, intOffset) : initialState.offset,
        query: queryString || initialState.query || '',
        sort: sort || initialState.sort,
        sortDirection: sortDirection || initialState.sortDirection,
    };
};

export const initState = (state, params) => {
    const newParams = createSearchParams(params);
    return {
        ...state,
        ...newParams,
        currentFilters: {
            ...initialState.currentFilters,
            ...newParams.filters,
        },
        currentQuery: newParams.query,
    };
};

export const formatFilters = (filters, { columns, filterList }) =>
    filterList.reduce((newFilters, selectedFilters, index) => {
        const name = columns[index].name;
        if (difference(filters[name] || [], selectedFilters).length) {
            // Removal
            if (selectedFilters.length === 0) {
                // Clear
                newFilters[name] = [];
            } else {
                newFilters[name] = selectedFilters;
            }
        } else if (difference(selectedFilters, filters[name] || []).length) {
            // Addition
            newFilters[name] = selectedFilters;
        } else if (filters[name] && filters[name].length) {
            // Keep existing filters
            newFilters[name] = filters[name];
        }
        return newFilters;
    }, {});

export default reduceReducers(
    initialState,
    combineReducers({
        currentFilters: passthroughReducer(initialState.currentFilters),
        currentQuery: passthroughReducer(initialState.currentQuery),
        expanded: passthroughReducer(initialState.expanded),
        filterModel: genericReducer(initialState.filterModel, {
            [ActionTypes.setFilterModel]: (_state, filterModel) => {
                return filterModel;
            },
        }),
        filters: genericReducer(initialState.filters, {
            [ActionTypes.setFilters]: (state, filters) => {
                return filters
                    ? Object.entries(filters).reduce((nonEmptyFilters, [key, values]) => {
                          if (values !== undefined) {
                              if (!Array.isArray(values)) {
                                  switch (typeof values) {
                                      case 'string':
                                      case 'boolean': {
                                          if (!values) {
                                              return nonEmptyFilters;
                                          }
                                          break;
                                      }
                                  }
                                  nonEmptyFilters[`${key}`] = values;
                              } else if (values.length) {
                                  nonEmptyFilters[`${key}`] = values;
                              }
                          }
                          return nonEmptyFilters;
                      }, {})
                    : state;
            },
            [ActionTypes.updateFilters]: (state, filters) => {
                const newFilters = Object.entries(filters).reduce(
                    (nonEmptyFilters, [key, values]) => {
                        if (values !== undefined) {
                            nonEmptyFilters[`${key}`] = values;
                            if (!Array.isArray(values)) {
                                if (!values) {
                                    delete nonEmptyFilters[key];
                                }
                            } else if (values.length === 0) {
                                delete nonEmptyFilters[key];
                            }
                        }
                        return nonEmptyFilters;
                    },
                    { ...state }
                );
                return newFilters;
            },
        }),
        requireSearch: passthroughReducer(initialState.requireSearch),
        selected: passthroughReducer(initialState.selected),
        statusDelete: crudStatusByIdReducer(initialState.statusDelete, [
            ActionTypes.deleteBegin,
            ActionTypes.deleteSuccess,
            ActionTypes.deleteFailure,
        ]),
        statusUpdate: crudStatusByIdReducer(initialState.statusUpdate, [
            ActionTypes.updateBegin,
            ActionTypes.updateSuccess,
            ActionTypes.updateFailure,
        ]),
        ...crudPagingReducers(initialState, [
            ActionTypes.fetchResultsBegin,
            ActionTypes.fetchResultsSuccess,
            ActionTypes.fetchResultsFailure,
        ]),
    }),
    genericReducer(initialState, {
        [ActionTypes.reload]: initState,
        [ActionTypes.setQuery]: (state, query) => {
            return { ...state, query: typeof query === 'string' ? query : '' };
        },
        [ActionTypes.setPage]: (state, page) => {
            const intPage = parseInt(page, 10);
            const { currentFilters, currentQuery, limit, offset } = state;
            return {
                ...state,
                filters: currentFilters,
                query: currentQuery,
                offset: isNaN(intPage) ? offset : intPage * limit,
            };
        },
        [ActionTypes.setLimit]: (state, limit) => {
            const intLimit = parseInt(limit, 10);
            const { currentFilters, currentQuery, offset } = state;
            return {
                ...state,
                filters: currentFilters,
                query: currentQuery,
                limit: isNaN(intLimit) ? state.limit : intLimit,
                offset: isNaN(intLimit) ? offset : offset - (offset % intLimit),
            };
        },
        [ActionTypes.setSort]: (state, { column, direction }) => {
            const { currentFilters, currentQuery } = state;
            let sortDirection = 'none';
            if (['ascending', 'asc'].includes(direction)) {
                sortDirection = 'asc';
            } else if (['descending', 'desc'].includes(direction)) {
                sortDirection = 'desc';
            }
            return {
                ...state,
                filters: currentFilters,
                query: currentQuery,
                offset: 0,
                sort: column,
                sortDirection,
            };
        },
        [ActionTypes.submit]: (state, newParams = {}) => {
            const { filters, query } = state;
            const {
                filters: newFilters,
                query: newQuery,
                offset: newOffset,
                ...remain
            } = newParams;
            const currentFilters = newFilters || filters || state.currentFilters;
            const currentQuery = newQuery !== undefined ? newQuery : query;
            const startFromBeginning =
                state.currentFilters !== currentFilters || state.currentQuery !== currentQuery;
            const offset =
                newOffset === undefined ? (startFromBeginning ? 0 : state.offset) : newOffset;
            return {
                ...state,
                ...remain,
                currentFilters,
                currentQuery,
                filters: currentFilters,
                offset,
                query: currentQuery,
            };
        },
        [ActionTypes.incrementPlanData]: (state, incCount = 1) => ({
            ...state,
            planData: {
                ...state?.planData,
                total: Math.max(0, (state?.planData?.total || 0) + incCount),
            },
        }),
        [ActionTypes.decrementPlanData]: (state, decCount = 1) => ({
            ...state,
            planData: {
                ...state?.planData,
                total: Math.max(0, (state?.planData?.total || 0) - decCount),
            },
        }),
    })
);
