// @ts-check
import * as echarts from 'echarts/core';

import { fetchJSON } from '../../services/helpers';
import merge from 'lodash/merge';

/**
 * @typedef MapData
 * @property {[x1: number, y1: number, x2: number, y2: number]} area The [left, top, right, bottom] coordinates of the map geometry area in latitude,longitude
 * @property {[latitude: number, longitude: number]} center The center of the map area
 * @property {string|number} divisionCode The divisionCode for the map
 * @property {0|1|2} divisionLevel The division administration level for the map.
 *  - 0: Country
 *  - 1: State/Territory/Province
 *  - 2: County/Region/Municipality
 * @property {number} zoom The zoom level that would permit the largest area of the division to fill the map
 */

/** @type {Record<string,Promise<GeoJSON.FeatureCollection>>} */
const mapPromises = {};
/** @type {Record<string,MapData>} */
const mapData = {};

export function totalArea([x1 = 0, y1 = 0, x2 = 0, y2 = 0] = []) {
    return (x2 - x1) * (y2 - y1);
}

function findPolygonArea(coordinates) {
    return coordinates.reduce((acc, coords) => {
        let x1 = 0,
            y1 = 0,
            x2 = 0,
            y2 = 0;
        if (Array.isArray(coords[0])) {
            [x1, y1, x2, y2] = findPolygonArea(coords);
        } else {
            x1 = parseFloat(coords[0]);
            y1 = parseFloat(coords[1]);
            x2 = x1;
            y2 = y1;
        }
        return [
            Math.min(acc[0] ?? x1, x1),
            Math.min(acc[1] ?? y1, y1),
            Math.max(acc[2] ?? x2, x2),
            Math.max(acc[3] ?? y2, y2),
        ];
    }, []);
}

/** @param {GeoJSON.FeatureCollection<GeoJSON.Point|GeoJSON.MultiPolygon|GeoJSON.Polygon,MapData>} json */
function generateMapData(mapName, json) {
    const { features = [] } = json;
    // Merge the properties of the same division for easy lookup later
    features.forEach(({ geometry, properties }) => {
        const newProperties = { ...properties };
        const { type } = geometry;
        const { area, center, zoom, divisionCode } = properties;
        switch (type) {
            case 'Point': {
                if (!center) {
                    newProperties.center = /** @type {MapData['center']} */ (geometry.coordinates);
                }
                break;
            }
            case 'MultiPolygon': {
                for (const coords of geometry.coordinates) {
                    const newArea = findPolygonArea(coords);
                    if (!newProperties.area || totalArea(newArea) > totalArea(newProperties.area)) {
                        newProperties.area = newArea;
                        const [x1, y1, x2, y2] = newArea;
                        if (!center) {
                            newProperties.center = [(x1 + x2) / 2, (y1 + y2) / 2];
                        }
                        if (!zoom) {
                            newProperties.zoom = Math.min(
                                180 / Math.abs(x2 - x1),
                                360 / Math.abs(y2 - y1)
                            );
                        }
                    }
                }
                break;
            }
            case 'Polygon': {
                const [x1, y1, x2, y2] = findPolygonArea(geometry.coordinates);
                if (!area) {
                    newProperties.area = [x1, y1, x2, y2];
                }
                if (!center) {
                    newProperties.center = [(x1 + x2) / 2, (y1 + y2) / 2];
                }
                if (!zoom) {
                    // Since the world map has coordinates in the range of [-180, 180] and [-90, 90], we can use the area find a good zoom level
                    newProperties.zoom = Math.min(180 / Math.abs(x2 - x1), 360 / Math.abs(y2 - y1));
                }
                break;
            }
            default: {
                break;
            }
        }
        // We want to use the largest area of any division eg. mainland USA instead of Hawaii or Alaska
        const { area: oldArea } = mapData[divisionCode] || {};

        if (!mapData[divisionCode] || totalArea(area) > totalArea(oldArea)) {
            mapData[divisionCode] = merge(mapData[divisionCode] || {}, newProperties);
        }
    });
    echarts.registerMap(mapName, json);
    return json;
}

/** @type {(divisionCode: string|number) => MapData} */
export const getMapData = divisionCode =>
    mapData[divisionCode] || {
        area: [0, 0, 0, 0],
        center: [0, 0],
        divisionCode,
        divisionLevel: 0,
        zoom: 1,
    };

/** @type {(map: string, accuracy: string) => Promise<GeoJSON.FeatureCollection>} */
export const loadMap = (map, accuracy = '110m') => {
    const mapName = `${map}.${accuracy}`;
    if (!mapPromises[mapName]) {
        mapPromises[mapName] = fetchJSON(`/assets/json/${mapName}.json`).then(json =>
            generateMapData(mapName, json)
        );
    }
    return mapPromises[mapName];
};
