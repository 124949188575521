import Actions from './ORMResults.actions';
import { QUERY_PARAMS } from './ORMResults.constants';
import castArray from 'lodash/castArray';
import { v4 as uuid } from 'uuid';

const falsyValues = ['false', 'f', ''];
const truthyValues = ['true', 't'];
const boolValues = [...falsyValues, ...truthyValues];

const formatQueryValue = value => {
    if (typeof value === 'string') {
        const valueArray = value.split(',');
        if (valueArray.length > 1) {
            // Value is an array
            return valueArray;
        }
        if (boolValues.includes(value)) {
            // Value is boolean
            return truthyValues.includes(value);
        }
    }
    return value;
};

const formatOperatorQueryValue = (id, key, value) => {
    const [operator, val] = value.split(':');

    return {
        columnField: key,
        id,
        operatorValue: operator,
        value: formatQueryValue(val),
    };
};

export const getParamsFromQueryString = queryString => {
    const params = {};
    const filterModel = { items: [], linkOperator: 'and' };
    const searchParams = new URLSearchParams(queryString);
    // Sort the keys for predictable order
    searchParams.sort();
    for (let [encodedKey, encodedValue] of searchParams.entries()) {
        const key = decodeURIComponent(encodedKey);
        const value = decodeURIComponent(encodedValue);
        if (QUERY_PARAMS.includes(key)) {
            if (key === 'linkOperator') {
                filterModel.linkOperator = value;
            } else {
                params[key] = value;
            }
        } else {
            params.filters = params.filters || {};

            const isMultiFilterValue = value.includes(':');
            if (isMultiFilterValue) {
                const id = uuid();
                const formattedValue = formatOperatorQueryValue(id, key, value);
                filterModel.items.push(formattedValue);
                params.filters[id] = formattedValue; // Use a unique key as we can have multiple of the same filter
            } else {
                const formattedValue = formatQueryValue(value);
                if (params.filters[key]) {
                    // We already had a value defined. Multiple values means an array
                    params.filters[key] = castArray(params.filters[key]).concat(
                        castArray(formattedValue)
                    );
                } else {
                    params.filters[key] = formattedValue;
                }
            }
        }
    }

    // Only add filterModel if we have items
    if (filterModel.items.length > 0) {
        params.filterModel = filterModel;
    }

    return params;
};

export const submit = params => dispatch => {
    return dispatch(Actions.submit(params));
};

export const setLimit = limit => dispatch => {
    dispatch(Actions.setLimit(limit));
    dispatch(submit());
};

export const setPage = page => dispatch => {
    dispatch(Actions.setPage(page));
    dispatch(submit());
};

export const setSort = (column, direction) => dispatch => {
    dispatch(Actions.setSort(column, direction));
    dispatch(submit());
};

export const resetFilters = initialFilters => dispatch => {
    dispatch(Actions.setFilters(initialFilters));
    dispatch(submit());
};

export const revertFilters = () => (dispatch, getState) => {
    const { currentFilters } = getState();
    dispatch(Actions.setFilters(currentFilters));
};

export const resetSearch = initialQuery => dispatch => {
    dispatch(Actions.setQuery(initialQuery));
    dispatch(submit());
};

export const revertSearch = () => (dispatch, getState) => {
    const { currentQuery } = getState();
    dispatch(Actions.setQuery(currentQuery));
    dispatch(submit());
};

// Filters and Search can update without submit
export const updateFilterModel = filterModel => dispatch =>
    dispatch(Actions.setFilterModel(filterModel));
export const updateFilters = newFilters => dispatch => dispatch(Actions.updateFilters(newFilters));
export const updateSearch = newQuery => dispatch => dispatch(Actions.setQuery(newQuery));

// Filters and Search will confirm BEFORE update, so they need to set here too
export const confirmFilters = (newFilters, initialFilters) => (dispatch, getState) => {
    const { filters } = getState();
    let updatedFilters = newFilters || filters;
    if (Object.values(updatedFilters).every(valueArray => valueArray.length === 0)) {
        // If all filters were removed...go back to the initial filters
        updatedFilters = initialFilters;
    }
    dispatch(Actions.setFilters(updatedFilters));
    dispatch(submit());
};
export const confirmSearch = newQuery => (dispatch, getState) => {
    const { query } = getState();
    dispatch(Actions.setQuery(newQuery !== undefined ? newQuery : query));
    dispatch(submit());
};
