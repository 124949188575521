import {
    Alert,
    Card,
    CardHeader,
    CardMedia,
    IconButton,
    MenuItem,
    MenuList,
    Popover,
    Typography,
} from '@mui/material';
import React, { useCallback } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Locale from '/b2b/common/components/Locale';
import LocalizedTooltip from '/b2b/common/components/LocalizedTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';

/**
 * @template {string} T
 * @param {{
 *  children: React.ReactNode;
 *  downloadFormats: (T)[];
 *  downloadFormatLabels: Record<T, string>;
 *  echartsInstance: React.MutableRefObject<import('echarts/core').EChartsType>;
 *  error: string | object;
 *  title: string;
 *  tooltip: string;
 *  translationOptions?: Record<string, any>;
 * }} props
 * @return {JSX.Element}
 */
const WidgetContainer = props => {
    const {
        downloadFormats = [],
        downloadFormatLabels,
        children,
        disabled,
        echartsInstance,
        error,
        title,
        tooltip,
        translationOptions,
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleActionsClick = useBoundCallback(
        (open, event) => {
            setAnchorEl(open ? null : event.currentTarget);
        },
        [open]
    );

    const handleActionsClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDownload = useCallback(format => {
        if (echartsInstance.current) {
            echartsInstance.current.dispatchAction({
                type: 'download',
                format,
            });
        }
        setAnchorEl(null);
    }, []);

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {error ? (
                <Alert severity="error" sx={{ alignItems: 'center' }}>
                    <Typography variant="caption">{error}</Typography>
                </Alert>
            ) : null}
            <CardHeader
                action={
                    downloadFormats && downloadFormats.length ? (
                        <>
                            <LocalizedTooltip title="common.actions">
                                <span>
                                    <IconButton disabled={disabled} onClick={handleActionsClick}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </span>
                            </LocalizedTooltip>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleActionsClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuList>
                                    {downloadFormats.map(format => {
                                        if (typeof format === 'string') {
                                            return (
                                                <MenuItem
                                                    key={format}
                                                    onClick={() => handleDownload(format)}
                                                >
                                                    <Locale
                                                        path={downloadFormatLabels[`${format}`]}
                                                    />
                                                </MenuItem>
                                            );
                                        }
                                        const { label, onClick } = format;
                                        return (
                                            <MenuItem key={label} onClick={onClick}>
                                                <Locale path={label} />
                                            </MenuItem>
                                        );
                                    })}
                                </MenuList>
                            </Popover>
                        </>
                    ) : undefined
                }
                title={
                    <>
                        <Locale path={title} options={translationOptions} />
                        {tooltip ? (
                            <LocalizedTooltip title={tooltip} options={translationOptions}>
                                <InfoIcon
                                    sx={{
                                        color: theme => theme.palette.common.grey[400],
                                        cursor: 'help',
                                        fontSize: '1em',
                                        ml: 0.5,
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </LocalizedTooltip>
                        ) : null}
                    </>
                }
            />
            <CardMedia sx={{ height: '100%' }}>{children}</CardMedia>
        </Card>
    );
};

WidgetContainer.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    downloadFormats: PropTypes.arrayOf(PropTypes.oneOf(['csv', 'png'])),
    downloadFormatLabels: PropTypes.object,
    drilldownFilters: PropTypes.arrayOf(PropTypes.object),
    echartsInstance: PropTypes.any,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    tooltip: PropTypes.string,
    translationOptions: PropTypes.object,
};

WidgetContainer.defaultProps = {
    downloadFormats: [],
    downloadFormatLabels: {
        csv: 'common.table.downloadCsv',
        png: 'common.table.saveAsImage',
    },
    drilldownFilters: [],
};

export default WidgetContainer;
