import { Backdrop, Drawer, useMediaQuery } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Logo as OsanoLogo, Square as OsanoLogoSquare } from '/b2b/common/icons/Osano';
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import AuthenticatedPaths from '../../../routing/AuthenticatedRoutes/AuthenticatedRoutes.paths';
import IconButton from '@mui/material/IconButton';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import PropTypes from 'prop-types';
import Scrollbar from '../../components/Scrollbar';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import UnauthenticatedPaths from '../../../routing/UnauthenticatedRoutes/UnauthenticatedRoutes.paths';
import { addIdToElements } from '../../helpers/DOM';
import classNames from 'classnames';
import { createCounterObject } from '../../helpers/Number';
import createCustomMutationObserver from '../../helpers/CustomMutationObserver';
import makeStyles from '@mui/styles/makeStyles';
import styles from './DashboardLayout.styles';
import { useTheme } from '@mui/material/styles';
import { useThemeContext } from '/b2b/App.component';

const useStyles = makeStyles(styles);

const DashboardLayout = props => {
    const {
        blackout,
        children,
        customerId,
        drawerOpen,
        onAuthChange,
        onCloseDrawer,
        onToggleDrawer,
        permissionsDetermined,
        title,
        userId,
    } = props;
    const classes = useStyles(props);
    const theme = useTheme();
    const { toggleSidebarMinified } = useThemeContext();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const shiftContent = permissionsDetermined && (isDesktop || (drawerOpen && !isMobile));
    const displayMinified = isDesktop && theme.ui.sidebarMinified;

    const contentClass = useMemo(
        () =>
            classNames(classes.content, {
                [classes.contentShift]: !!shiftContent,
                [classes.contentShiftMini]: displayMinified,
            }),
        [
            classes.content,
            classes.contentShift,
            classes.contentShiftMini,
            shiftContent,
            isDesktop,
            theme.ui.sidebarMinified,
        ]
    );

    const allPaths = {
        ...AuthenticatedPaths,
        ...UnauthenticatedPaths,
    };

    const location = useLocation();
    const prefix =
        Object.values(allPaths).find(
            value =>
                matchPath(location.pathname, {
                    path: value,
                }) && value
        ) || location.pathname;
    useEffect(() => {
        customerId && userId && onAuthChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, userId]);

    const mainRef = useRef(null);
    const topBarRef = useRef(null);
    const drawerRef = useRef(null);

    useLayoutEffect(() => {
        const PATH_PREFIX = prefix.slice(1).replace(/-|\//g, '_');

        if (process.env.MODE !== 'prod') {
            const ELEMENT_TYPES_TO_ADD_ID = ['input', 'button', 'textarea', 'label', 'a'];
            const ELEMENTS_TO_OBSERVE = [
                { refElement: mainRef.current, prefixName: PATH_PREFIX },
                { refElement: topBarRef.current, prefixName: 'topbar' },
                { refElement: drawerRef.current, prefixName: 'drawer' },
            ]
                // Not all elements are visible at all resolutions
                .filter(({ refElement }) => !!refElement);
            const mutationObserverObjects = ELEMENTS_TO_OBSERVE.map(elementToObserve => {
                const elementCounterObj = createCounterObject(ELEMENT_TYPES_TO_ADD_ID);
                const { refElement, prefixName } = elementToObserve;
                return createCustomMutationObserver(refElement, () =>
                    addIdToElements(refElement, elementCounterObj, prefixName)
                );
            });

            return () => mutationObserverObjects.forEach(observer => observer.disconnect());
        }
    }, [location.pathname]);

    return (
        <div className={classes.root}>
            <Topbar
                className={classNames(classes.topbar, {
                    [classes.topbarShift]: shiftContent,
                    [classes.topbarShiftMini]: displayMinified,
                })}
                isSidebarOpen={drawerOpen} // for mobile drawer
                onToggleSidebar={onToggleDrawer} // for mobile drawer
                title={title}
                ref={topBarRef}
            />
            <Drawer
                anchor="left"
                classes={{
                    root: classes.drawerRoot,
                    paper: `${classes.drawerPaper} ${displayMinified ? 'minified' : ''}`,
                }}
                onClose={onCloseDrawer} // for mobile drawer
                open={permissionsDetermined && (isDesktop || drawerOpen)}
                variant={isMobile ? 'temporary' : 'persistent'}
                ref={drawerRef}
            >
                <div className={`${classes.logoWrapper} ${displayMinified ? 'minified' : ''}`}>
                    <Link className={classes.logoLink} to="/">
                        {displayMinified ? (
                            <OsanoLogoSquare className={classes.logoSquare} alt="Osano logo" />
                        ) : (
                            <OsanoLogo className={classes.logo} alt="Osano logo" />
                        )}
                    </Link>
                    {isDesktop ? (
                        <IconButton
                            className={
                                theme.ui.sidebarMinified
                                    ? classes.openDrawerToggle
                                    : classes.closeDrawerToggle
                            }
                            onClick={() => {
                                toggleSidebarMinified(!theme.ui.sidebarMinified);
                            }}
                        >
                            <KeyboardTabIcon />
                        </IconButton>
                    ) : null}
                </div>
                {!displayMinified && <div className={classes.logoDivider}></div>}
                <Scrollbar
                    data-testid="drawerScroll"
                    className={classes.drawerScroller}
                    noScrollX
                    dark
                >
                    <Sidebar className={classes.sidebar} minified={displayMinified} />
                </Scrollbar>
            </Drawer>
            <main ref={mainRef} className={contentClass}>
                <Scrollbar data-testid="contentScroll" className={classes.panel}>
                    {children}
                </Scrollbar>
            </main>
            <Backdrop open={blackout} classes={{ root: classes.blackout }} />
        </div>
    );
};

DashboardLayout.propTypes = {
    blackout: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    customerId: PropTypes.string,
    drawerOpen: PropTypes.bool,
    onAuthChange: PropTypes.func.isRequired,
    onCloseDrawer: PropTypes.func.isRequired,
    onToggleDrawer: PropTypes.func.isRequired,
    permissionsDetermined: PropTypes.bool.isRequired,
    title: PropTypes.string,
    isMobile: PropTypes.bool,
    shiftContent: PropTypes.bool,
    userId: PropTypes.string,
};

export default DashboardLayout;
