// @ts-check
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import {
    axisNameForTimeFormat,
    getTimeFormat,
    maxTimeInterval,
    timeLabelFormatter,
} from '/b2b/common/components/Charts/helpers';

import ConsentDashboardContext from '../ConsentDashboard.context';
import PropTypes from 'prop-types';
import WidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchConsentReportsDashboard } from '/b2b/consent/services';
import { makeDownloadBlob } from '/b2b/common/components/Reporting/helpers';
import merge from 'lodash/merge';
import moment from 'moment';
import { parseDate } from '/b2b/common/helpers/Date';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { useTheme } from '@mui/material/styles';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'consentsOverTime';

const { EChart, LegendIcon } = require('/b2b/common/components/Charts');

/** @type {import('echarts').EChartsOption} */
const staticOptions = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'none',
        },
        appendTo: document.body,
        formatter(params) {
            const { axisValueLabel } = params[0];
            const date = new Date(parseDate(axisValueLabel));
            /** @type {Intl.DateTimeFormatOptions} */
            const formatOptions = {
                timeZone: 'UTC',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            };
            // eslint-disable-next-line xss/no-mixed-html
            return `<b>${date.toLocaleString('default', formatOptions)}</b><hr style="margin:0.25em 0"/>${params
                .map(({ dimensionNames, marker, seriesName, value }) => {
                    const count = value[dimensionNames[1]];
                    return `${marker} ${translate(seriesName)}: ${count}`;
                })
                .join('<br>')}`;
        },
    },
    legend: {
        icon: LegendIcon,
        orient: 'horizontal',
        top: 'top',
    },
    grid: {
        containLabel: false,
    },
    xAxis: {
        axisLabel: {
            hideOverlap: true,
            showMaxLabel: false,
            showMinLabel: false,
        },
        axisLine: { onZero: false, show: false },
        axisTick: { show: false },
        minorTick: { show: false },
        nameGap: 32,
        nameLocation: 'middle',
        nameTextStyle: {
            verticalAlign: 'top',
        },
        splitLine: { show: false },
        type: 'time',
    },
    yAxis: {
        axisLabel: { align: 'right', margin: 0 },
        axisLine: { show: false },
        axisTick: { show: false },
        nameLocation: 'middle',
        nameRotate: 90,
        nameTextStyle: {
            verticalAlign: 'bottom',
        },
        splitLine: { show: false },
        splitNumber: 2,
        type: 'value',
    },
    series: [
        {
            name: 'common.visitors',
            type: 'line',
            emphasis: {
                focus: 'series',
            },
            dimensions: [
                { name: 'date', type: 'ordinal' },
                { name: 'totalVisitors', type: 'int' },
            ],
        },
        {
            name: 'common.consents',
            type: 'bar',
            emphasis: {
                focus: 'series',
            },
            dimensions: [
                { name: 'date', type: 'ordinal' },
                { name: 'totalConsents', type: 'int' },
            ],
        },
    ],
};

const csvColumnMap = {
    date: 'common.date',
    totalConsents: 'common.consents',
    totalVisitors: 'common.visitors',
};

/**
 * @param {Unpacked<CmpReportsData['consentsOverTime']>} row
 * @param {Array<keyof typeof csvColumnMap>} columnNames
 * @return
 */
const processRow = (row, columnNames) => {
    return columnNames.reduce((acc, column) => {
        switch (column) {
            case 'date':
                acc.push(moment(parseDate(row[column])).format('YYYY-MM-DD'));
                break;
            default:
                acc.push(row[column]);
                break;
        }
        return acc;
    }, []);
};

/**
 * @param {CmpReportsData} data
 * @returns {Blob}
 */
function makeCSV(data) {
    const { consentsOverTime } = data || {};
    const format = getTimeFormat(data.dateRange[0], data.dateRange[1]);
    const columnNames = /** @type {Array<keyof typeof csvColumnMap>} */ (Object.keys(csvColumnMap));
    // String CSV
    const csvFile = consentsOverTime
        .reduce(
            (acc, row) => {
                const rowArray = processRow(row, columnNames);
                acc.push(rowArray.join(','));
                return acc;
            },
            [
                columnNames
                    .map(column => {
                        switch (column) {
                            case 'date':
                                return (
                                    translate(axisNameForTimeFormat(format), { date: '' }) ||
                                    csvColumnMap[column]
                                );
                            default:
                                return translate(csvColumnMap[column], { delimiter: ' - ' });
                        }
                    })
                    .join(','),
            ]
        )
        .join('\n');
    return new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
}

export const ConsentsOverTime = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data, error, load, loading } = useContext(ConsentDashboardContext);
    /** @type {import('/b2b/common/theme').OsanoDefaultTheme} */
    const theme = useTheme();
    const { consentsOverTime = [], traffic = [] } = data || {};
    const consentExists = consentsOverTime.some(({ totalConsents }) => totalConsents > 0);
    const trafficExists = traffic.some(({ totalVisitors }) => totalVisitors > 0);
    const noResults =
        (consentsOverTime.length === 0 && traffic.length === 0) ||
        !(consentExists || trafficExists);
    const isLoading = loading || providedLoading;

    const echartsInstance = useRef(null);
    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    const memoOption = useMemo(() => {
        const minTotal = data.totalRange[0];
        let scale = 1;
        if (minTotal >= 1000) {
            scale = 1000;
        } else if (minTotal >= 1000000) {
            scale = 1000000;
        }
        const dates = consentsOverTime.map(({ date }) =>
            moment(parseDate(date)).format('YYYY-MM-DD')
        );
        const format = getTimeFormat(data.dateRange[0], data.dateRange[1]);
        return merge(
            {},
            staticOptions,
            /** @type {import('echarts').EChartsOption} */ ({
                legend: {
                    show: !filters?.jurisdictions?.length,
                    left: theme.spacing(1.5),
                    selected: {
                        'common.visitors': !filters?.jurisdictions?.length, // Hide visitors when jurisdictions are selected
                        'common.consents': true,
                    },
                },
                grid: {
                    left: theme.spacing(8.5),
                    right: theme.spacing(4.5),
                },
                xAxis: {
                    name: translate(axisNameForTimeFormat(format), { date: '' }).trim(),
                    axisLabel: {
                        formatter: timeLabelFormatter(data.dateRange[0], data.dateRange[1]),
                        customValues: dates,
                    },
                    max: data.dateRange[1],
                    min: data.dateRange[0],
                    maxInterval: maxTimeInterval(data.dateRange[0], data.dateRange[1]),
                },
                yAxis: {
                    show: consentsOverTime.length + traffic.length > 0,
                    name: translate('common.consents'),
                    nameGap: parseInt(theme.spacing(5), 10),
                    axisLabel: {
                        formatter: value => {
                            switch (scale) {
                                case 1000:
                                    return `${value / scale}k`;
                                case 1000000:
                                    return `${value / scale}m`;
                                default:
                                    return value;
                            }
                        },
                    },
                },
                dataset: {
                    source: consentsOverTime,
                },
                series: staticOptions.series
                    // @ts-ignore
                    .map(series => {
                        const visible =
                            series.name !== 'common.visitors' || !filters?.jurisdictions?.length;
                        const newSeries = {
                            ...series,
                            name: translate(series.name),
                        };
                        if (!visible) {
                            newSeries.data = [];
                        }
                        return newSeries;
                    }),
            })
        );
    }, [consentsOverTime, shouldRetranslate]);

    const fetchData = useBoundCallback(
        filters =>
            fetchConsentReportsDashboard({
                filters: {
                    ...filters,
                    requestedCharts: [REPORT_NAME],
                },
            }),
        [filters]
    );

    const helpers = useMemo(
        () => ({
            makeCSV,
            name: 'consents-over-time',
        }),
        []
    );
    const makeDownload = useBoundCallback(makeDownloadBlob, [echartsInstance, data, helpers]);

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData]);

    return (
        <WidgetContainer
            disabled={noResults}
            downloadFormats={['csv', 'png']}
            echartsInstance={echartsInstance}
            error={error}
            title="consent.dashboard.charts.title.consentsOverTime"
            tooltip="consent.dashboard.charts.tooltip.consentsOverTime"
        >
            <EChart
                loading={isLoading}
                makeDownload={makeDownload}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </WidgetContainer>
    );
};

ConsentsOverTime.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

/** @type {import('/b2b/common/hoc/withAsyncData').ComponentWithOverridenProps<CmpReportsData, { filters: CmpReportsQueryParams }>} */
const TranslatedComponent = withRetranslate(ConsentsOverTime);

export default withAsyncData(TranslatedComponent, ConsentDashboardContext);
