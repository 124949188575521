const { EChart } = require('/b2b/common/components/Charts');

import { ASSIGNMENTS_BY_STATUS_COLORS, PIE_CHART_OPTIONS } from './constants';
import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';

import AssessmentsReportingContext from '../AssessmentsReporting.context';
import PropTypes from 'prop-types';
import ReportingWidgetContainer from '/b2b/common/components/Reporting/WidgetContainer';
import { fetchAssessmentsReportingData } from '/b2b/assessments/routes/AssessmentsReporting/services';
import merge from 'lodash/merge';
import { translate } from '/b2b/common/helpers/i18n';
import useBoundCallback from '/b2b/common/helpers/Hooks/useBoundCallback';
import { withAsyncData } from '/b2b/common/hoc/withAsyncData';
import withRetranslate from '/b2b/common/hoc/withRetranslate';

export const REPORT_NAME = 'assignmentsByStatus';

export const FIELDS = {
    notStarted: 'notStarted',
    inProgress: 'inProgress',
    completed: 'completed',
    overdue: 'overdue',
};

const LABELS = {
    [FIELDS.notStarted]: 'views.AssessmentsReporting.charts.assignmentsByStatus.label.notStarted',
    [FIELDS.inProgress]: 'views.AssessmentsReporting.charts.assignmentsByStatus.label.inProgress',
    [FIELDS.completed]: 'views.AssessmentsReporting.charts.assignmentsByStatus.label.completed',
    [FIELDS.overdue]: 'views.AssessmentsReporting.charts.assignmentsByStatus.label.overdue',
};

const AssignmentsByStatus = props => {
    const { data: providedData, filters, loading: providedLoading, shouldRetranslate } = props;
    const { data = {}, error, load, loading } = useContext(AssessmentsReportingContext);
    const reportData = data[REPORT_NAME] || [];
    const noResults =
        reportData.reduce((accumulator, { value }) => (accumulator += value), 0) === 0;
    const isLoading = loading || providedLoading;

    const memoOption = useMemo(
        () =>
            merge({}, PIE_CHART_OPTIONS, {
                color: ASSIGNMENTS_BY_STATUS_COLORS,
                series: [
                    {
                        data: reportData.map(({ name, value }) => {
                            return { name: translate(LABELS[FIELDS[name]]), value };
                        }),
                        stillShowZeroSum:
                            !reportData.reduce(
                                (accumulator, { value }) => (accumulator += value),
                                0
                            ) === 0,
                    },
                ],
            }),
        [reportData, shouldRetranslate]
    );

    const fetchData = useBoundCallback(
        filters => fetchAssessmentsReportingData({ ...filters, reports: [REPORT_NAME] }),
        [filters]
    );

    useLayoutEffect(() => {
        providedData ? load(Promise.resolve(providedData)) : load(fetchData());
    }, [providedData, filters]);

    const echartsInstance = useRef(null);

    const onChartReady = useCallback(chart => {
        echartsInstance.current = chart;
    }, []);

    return (
        <ReportingWidgetContainer
            disabled={noResults}
            echartsInstance={echartsInstance}
            error={error}
            title="views.AssessmentsReporting.charts.assignmentsByStatus.title"
            tooltip=""
        >
            <EChart
                loading={isLoading}
                noResults={noResults}
                onChartReady={onChartReady}
                option={memoOption}
            />
        </ReportingWidgetContainer>
    );
};

AssignmentsByStatus.propTypes = {
    data: PropTypes.object,
    filters: PropTypes.object,
    loading: PropTypes.bool,
    shouldRetranslate: PropTypes.string,
};

export default withAsyncData(withRetranslate(AssignmentsByStatus), AssessmentsReportingContext);
