import { connect } from 'react-redux';
import { shouldRetranslate } from '../state/locale/Locale.selectors';

const mapStateToProps = state => ({
    shouldRetranslate: shouldRetranslate(state),
});

export const withRetranslate = Component => {
    return connect(mapStateToProps)(Component);
};

export default withRetranslate;
