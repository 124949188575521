import { jurisdictionLookupMap } from '/b2b/common/helpers/Jurisdiction';
import { makeResultsFormatter } from '/b2b/common/services/helpers';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

function formatActionItemByAssignee(data) {
    return {
        ...data,
        id: uuid(),
    };
}

export function formatDSARReportingData(data) {
    const { actionItemsByAssignee = {}, requestsByJurisdiction = [] } = data;
    return {
        ...data,
        ...(Object.keys(actionItemsByAssignee).length && {
            actionItemsByAssignee: makeResultsFormatter(formatActionItemByAssignee)(
                actionItemsByAssignee
            ),
        }),
        ...(requestsByJurisdiction.length && {
            requestsByJurisdiction: Object.values(
                requestsByJurisdiction.reduce(
                    (geoLookup, { jurisdiction: initialJurisdiction, requestTypes }) => {
                        // some requests are submitted with country / territory codes that don't exist in the jurisdiction lookup map
                        // so we prefer the more specific code, but use the country code as a fallback
                        const country = initialJurisdiction.split('-')[0];

                        const jurisdictionExists = !!jurisdictionLookupMap[initialJurisdiction];
                        const countryExists = !!jurisdictionLookupMap[country];

                        const jurisdiction = jurisdictionExists
                            ? initialJurisdiction
                            : countryExists
                              ? country
                              : 'common.unknown';

                        if (!geoLookup[jurisdiction]) {
                            geoLookup[jurisdiction] = {
                                jurisdiction,
                                requestTypes,
                            };
                        } else {
                            Object.keys(requestTypes).forEach(requestTypeId => {
                                if (!geoLookup[jurisdiction].requestTypes[requestTypeId]) {
                                    geoLookup[jurisdiction].requestTypes[requestTypeId] = 0;
                                }

                                geoLookup[jurisdiction].requestTypes[requestTypeId] +=
                                    requestTypes[requestTypeId];
                            });
                        }

                        return geoLookup;
                    },
                    {}
                )
            ),
        }),
    };
}

export function formatDSARReportingParams(filters = {}) {
    const { dateRange, forms, jurisdictions, reports, requestTypes, ...rest } = filters;

    const queryStringParameters = {
        ...rest,
    };

    if (dateRange) {
        if (dateRange[0]) {
            const utcStartDate = moment.utc(dateRange[0]);
            queryStringParameters.startDate = utcStartDate.valueOf();
        }
        if (dateRange[1]) {
            const utcEndDate = moment.utc(dateRange[1]);
            queryStringParameters.endDate = utcEndDate.valueOf();
        }
    }
    if (forms) {
        queryStringParameters.formIds = JSON.stringify(forms.map(({ value }) => value));
    }
    if (jurisdictions) {
        queryStringParameters.jurisdictions = JSON.stringify(
            jurisdictions.map(({ value }) => value)
        );
    }
    if (reports) {
        queryStringParameters.reports = JSON.stringify(reports);
    }
    if (requestTypes) {
        queryStringParameters.requestTypeIds = JSON.stringify(
            requestTypes.map(({ value }) => value)
        );
    }

    return queryStringParameters;
}
