/* eslint-disable camelcase */
// @ts-check
import React from 'react';

/** @type {React.Context<import('/b2b/common/hoc/withAsyncData').HocAsyncDataContext<UcReportsData>>}>} */
export const DashboardContext = React.createContext({
    loading: false,
    error: null,
    load: () => Promise.reject(new Error('Not implemented')),
    data: {
        configs: { total: 0, unpublished: 0 },
        integrations: { total: 0, errored: 0 },
        privacyProtocols: { total: 0, gpc: 0 },
        privacyProtocolNames: {},
    },
});

export default DashboardContext;
