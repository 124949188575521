import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import castArray from 'lodash/castArray';

export { default as useMemoCompare } from './useMemoCompare';
export { default as useORMDetails } from './useORMDetails';
export { default as useORMResults } from './useORMResults';

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

const isClient = typeof window === 'object';
function getSize() {
    return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
    };
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export const useQueryStringUpdate = params => {
    const history = useHistory();
    const { pathname, search } = useLocation();
    const {
        doUpdate = true,
        filters,
        limit,
        offset,
        query,
        sort,
        sortDirection,
        linkOperator,
    } = params;

    const newSearch = useMemo(() => {
        const searchParams = new URLSearchParams();
        query && searchParams.set('query', query);
        offset && searchParams.set('offset', offset);
        limit && limit !== 25 && searchParams.set('limit', limit);
        linkOperator && searchParams.set('linkOperator', linkOperator);
        sort && sortDirection !== 'none' && searchParams.set('sort', sort);
        sort && sortDirection !== 'none' && searchParams.set('sortDirection', sortDirection);
        Object.entries(filters).forEach(([key, value]) => {
            // If multiple of the same filter exist for multi-filtering then use multiple of the same query parameter key
            if (linkOperator && Array.isArray(value)) {
                for (const val of value) {
                    searchParams.append(key, val);
                }
            } else if (value && `${value}`.length) {
                searchParams.set(key, castArray(value).join(','));
            }
        });
        // Sort the keys for predictable order
        searchParams.sort();
        return searchParams.toString();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, JSON.stringify(filters), limit, linkOperator, offset, query, sort, sortDirection]);

    const oldUrl = `${pathname}${search}`;
    const newUrl = `${pathname}${newSearch ? `?${newSearch}` : ''}`;

    useEffect(() => {
        doUpdate && oldUrl !== newUrl && history.replace(newUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doUpdate, oldUrl, newUrl]);
};

export { default as useThunkReducer } from './useThunkReducer';
