import { Grid25, Grid50 } from '/b2b/common/components/Reporting/Grid';
import React, { useCallback, useEffect, useMemo } from 'react';

import ConsentGraph from './components/ConsentGraph';
import Greeting from './components/Greeting';
import { Grid } from '@mui/material';
import { LoadingCoordinator } from '/b2b/common/hoc/withAsyncData';
import { PRODUCT_ORDER } from '../constants';
import PropTypes from 'prop-types';
import Stats from './components/Stats';
import TodoList from './components/TodoList';
import TrafficGraph from './components/TrafficGraph';
import makeStyles from '@mui/styles/makeStyles';
import styles from './Dashboard.styles';

const useStyles = makeStyles(styles);

const Dashboard = props => {
    const classes = useStyles(props);

    const { allowedReports = {}, fetchDashboard, isDashboardV2Enabled } = props;

    useEffect(() => {
        // TODO: Remove once ENABLE_DASHBOARD_V2 feature flag is removed
        !isDashboardV2Enabled && fetchDashboard();
    }, [isDashboardV2Enabled]);

    const reports = useMemo(
        () =>
            Object.entries(allowedReports)
                .filter(([_, { default: isDefault }]) => isDefault)
                .sort(
                    ([_, { product: productA }], [__, { product: productB }]) =>
                        PRODUCT_ORDER.indexOf(productA) - PRODUCT_ORDER.indexOf(productB)
                ),
        [allowedReports]
    );

    const getGridItem = useCallback(
        index => {
            if (reports.length === 1) {
                return {
                    Component: Grid50,
                    gridProps: {
                        xs: 12,
                        lg: 12,
                    },
                };
            }

            const isLastReport = index === reports.length - 1;
            const isOddCount = reports.length % 2 === 1;

            if (isLastReport && isOddCount) {
                return {
                    Component: Grid25,
                    gridProps: {
                        xs: 12,
                        lg: 12,
                    },
                };
            }

            return {
                Component: Grid25,
                gridProps: {
                    xs: 12,
                    lg: 6,
                },
            };
        },
        [reports.length]
    );

    return isDashboardV2Enabled ? (
        <LoadingCoordinator>
            <Grid container spacing={2} className={classes.root} sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Greeting />
                </Grid>
                <TodoList componentId="todoList" />
                {Object.keys(allowedReports).length > 0 && (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {reports.map(
                                ([report, { Component: ReportComponent, filters }], index) => {
                                    const { Component: GridComponent, gridProps = {} } =
                                        getGridItem(index);
                                    return (
                                        <GridComponent key={report} item {...gridProps}>
                                            <ReportComponent
                                                componentId={report}
                                                filters={filters}
                                            />
                                        </GridComponent>
                                    );
                                }
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </LoadingCoordinator>
    ) : (
        <Grid container spacing={4} className={classes.root}>
            <Grid item xs={12}>
                <Stats />
            </Grid>
            <Grid item xs={12} md={6}>
                <TrafficGraph />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConsentGraph />
            </Grid>
        </Grid>
    );
};

Dashboard.propTypes = {
    allowedReports: PropTypes.object.isRequired,
    fetchDashboard: PropTypes.func.isRequired,
    isDashboardV2Enabled: PropTypes.bool.isRequired,
};

export default Dashboard;
