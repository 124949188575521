import { formatAssessmentsReportingData, formatAssessmentsReportingParams } from './format';
import { formatPagingParams, handleError } from '/b2b/common/services/helpers';

import API from '@aws-amplify/api';
import { apiName } from '@osano-b2b';

export const fetchAssessmentsReportingData = queryStringParameters => {
    const options = {
        queryStringParameters: {
            ...formatPagingParams(queryStringParameters),
            ...formatAssessmentsReportingParams(queryStringParameters),
        },
    };

    return API.get(apiName, `/assessments-reporting`, options)
        .catch(handleError)
        .then(formatAssessmentsReportingData);
};
