import * as Auth from '../Auth.selectors';
import * as AuthUserCustomers from './customers/AuthUserCustomers.selectors';
import * as AuthUserNotifications from './notifications/AuthUserNotifications.selectors';
import * as DB from '../../../common/state/db/DB.selectors';

import { DOMAIN } from './AuthUser.constants';
import { ROLES } from '../../constants';
import castArray from 'lodash/castArray';
import { denormalize } from 'normalizr';
import get from 'lodash/get';
import { getStore as getDB } from '../../../common/state/db/DB.selectors';
import { isAllowedTo } from '../../../common/helpers/Permissions';
import schema from '/b2b/users/state/db/User.schema';

export const customers = AuthUserCustomers;
export const notifications = AuthUserNotifications;

export const getStore = state => get(state, DOMAIN) || {};

export const getEntityById = (state, id) => denormalize(id, schema, getDB(state)) || {};
export const getEntitiesForIds = (state, ids) => denormalize(ids, [schema], getDB(state));

export const getError = state => getStore(state).error;
export const getId = state => getStore(state).id;
export const getPassword = state => getStore(state).password;
export const getBeaconSignature = state => getStore(state).signature || '';
export const getEmail = state => `${getStore(state).id || ''}`.toLowerCase();
export const getEmailVerified = state => getStore(state).emailVerified;
export const getFeatureFlags = state => getStore(state).featureFlags || [];
export const getPermissions = state => getStore(state).permissions;
export const getPhone = state => getStore(state).phone;
export const getPhoneVerified = state => getStore(state).phoneVerified;
export const getOrganizations = state =>
    get(getEntityById(state, getId(state)), 'organizations') || [];
export const getRoles = state => getEntityById(state, getId(state)).roles || [];
export const getRolesString = state =>
    castArray(getRoles(state))
        .map(({ role } = {}) => role)
        .join(',');

export const hasPermission = (state, { toPerform: action, on: contentType, onAny: anyType }) => {
    const customer = Auth.getCustomer(state);
    const { roles = [] } = DB.user.getEntityById(state, getId(state));
    if (anyType && anyType.length) {
        return anyType.some(type =>
            isAllowedTo(roles, customer, { [type]: { [action]: true } }, getPermissions(state))
        );
    }
    return isAllowedTo(
        roles,
        customer,
        { [contentType]: { [action]: true } },
        getPermissions(state)
    );
};
export const hasRole = (state, rolesToCheck) => {
    const { roles = [] } = DB.user.getEntityById(state, getId(state));
    return roles.some(({ role } = {}) => rolesToCheck.includes(role)) || false;
};

export const canChangeConsentOrgs = state => hasRole(state, [ROLES.ADMIN, ROLES.CONSENT_MANAGER]);
export const canChangeDSAROrgs = state => hasRole(state, [ROLES.ADMIN, ROLES.DATA_STORE_OWNER]);
export const canChangeUserOrgs = state => hasRole(state, [ROLES.ADMIN]);
export const canChangeUserRoles = state => hasRole(state, [ROLES.ADMIN]);
export const canManageAssessments = state =>
    hasRole(state, [ROLES.ADMIN, ROLES.ASSESSMENT_ADMIN, ROLES.ASSESSMENT_MANAGER]);
export const canManageDataMapping = state =>
    hasRole(state, [ROLES.ADMIN, ROLES.DATA_MAPPING_MANAGER]);
export const canReadDataMapping = state =>
    hasRole(state, [ROLES.ADMIN, ROLES.DATA_MAPPING_MANAGER, ROLES.DATA_MAPPING_VIEWER]);
export const canManageSubjectRights = state =>
    hasRole(state, [ROLES.ADMIN, ROLES.DATA_REQUESTS_MANAGER]);
export const canManageVendors = state => hasRole(state, [ROLES.ADMIN, ROLES.MONITORING_MANAGER]);
export const canManageVendorGuidance = state =>
    hasRole(state, [ROLES.ADMIN, ROLES.MONITORING_MANAGER]);
export const canViewAssessments = state =>
    hasRole(state, [
        ROLES.ADMIN,
        ROLES.ASSESSMENT_ADMIN,
        ROLES.ASSESSMENT_MANAGER,
        ROLES.ASSESSMENT_VIEWER,
    ]);
export const canCreateAssessmentTemplates = state =>
    hasRole(state, [ROLES.ADMIN, ROLES.ASSESSMENT_ADMIN, ROLES.ASSESSMENT_MANAGER]);

export const isAdmin = state => hasRole(state, [ROLES.ADMIN]);
export const isEmailVerified = state => getStore(state).emailVerified || false;
export const isLoading = state => getStore(state).loading || false;
export const isPhoneVerified = state => getStore(state).phoneVerified || false;
