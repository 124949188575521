import { CircularProgress, Grid } from '@mui/material';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
// Amplify
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { checkUser, deleteSnack, fetchLocale, setUserIdle } from './App.commands';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
// Redux
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Routes from './routing';
import { SnackbarProvider } from 'notistack';
import Userpilot from './common/components/Userpilot';
import fromState from './common/state/selectors';
import makeStyles from '@mui/styles/makeStyles';
import styles from './App.styles';

const useStyles = makeStyles(styles);

const USER_IDLE_TIME = 1000 * 60 * 20; // 20min

const ThemeContext = createContext({
    toggleSidebarMinified: () => {},
});

export const useThemeContext = () => useContext(ThemeContext);

const App = props => {
    const { history, store, theme: defaultTheme } = props;
    const classes = useStyles(props);
    const [authenticated, setAuth] = useState(null);
    const [locale, setLocale] = useState(null);
    const [theme, setTheme] = useState(defaultTheme);
    const idleTimer = useRef(0);
    const startTimer = useCallback(() => {
        idleTimer.current = setTimeout(() => {
            store.dispatch(setUserIdle(true));
        }, USER_IDLE_TIME);
    }, [store]);

    const toggleSidebarMinified = sidebarMinified => {
        setTheme(prevState => ({
            ...prevState,
            ui: {
                sidebarMinified,
            },
        }));
        // persist the user's selected persistant drawer state upon refresh
        // eslint-disable-next-line scanjs-rules/property_localStorage
        window.localStorage && window.localStorage.setItem('sidebarMinified', sidebarMinified);
    };

    useEffect(() => {
        if (authenticated === null) {
            checkUser()(store.dispatch, store.getState)
                .then(() => {
                    setAuth(true);
                })
                .catch(() => {
                    setAuth(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    useEffect(() => {
        if (locale === null) {
            fetchLocale()(store.dispatch, store.getState)
                .then(() => {
                    setLocale(true);
                })
                .catch(() => {
                    setLocale(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(name => {
            document.addEventListener(
                name,
                () => {
                    if (fromState.UI.isUserIdle(store.getState())) {
                        store.dispatch(setUserIdle(false));
                    }
                    clearTimeout(idleTimer.current);
                    startTimer();
                },
                true
            );
        });
        startTimer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    const notistackRef = useRef(null);
    const handleCloseSnackbar = key => () => {
        notistackRef.current && notistackRef.current.closeSnackbar(key);
        deleteSnack(key)(store.dispatch);
    };

    useEffect(() => {
        if (window._hsq && window._hsq.push) {
            const observer = new MutationObserver(mutations => {
                let refreshTracking = false;
                mutations.forEach(mutation => {
                    if (refreshTracking) {
                        return;
                    }
                    const { addedNodes = [], oldValue, target, type } = mutation;
                    const tracksAdded = Array.from(addedNodes).some(
                        node => node?.dataset?.track !== undefined
                    );
                    const tracksModified =
                        type === 'attributes' && target?.dataset?.track !== oldValue;

                    if (tracksAdded || tracksModified) {
                        refreshTracking = true;
                    }
                });

                if (refreshTracking) {
                    window._hsq.push(['refreshPageHandlers']);
                }
            });
            observer.observe(document.body, {
                attributes: true,
                attributeFilter: ['data-track'],
                attributeOldValue: true,
                childList: true,
                subtree: true,
            });
            return () => {
                observer.disconnect();
            };
        }
    }, [window._hsq]);

    useEffect(() => {
        if (history && window.Cypress) {
            window.cyHistory = history;
        }
    }, [history, window.Cypress]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <StyledEngineProvider injectFirst>
                <ThemeContext.Provider value={{ toggleSidebarMinified }}>
                    <ThemeProvider theme={theme}>
                        <Provider store={store}>
                            <Userpilot />
                            {authenticated === null || locale === null ? (
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '100vh' }}
                                >
                                    <Grid item xs={3}>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Router history={history}>
                                    <SnackbarProvider
                                        ref={notistackRef}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        action={snackKey => (
                                            <IconButton
                                                className={classes.closeIcon}
                                                onClick={handleCloseSnackbar(snackKey)}
                                                color="default"
                                                size="large"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                        classes={{
                                            variantSuccess: classes.success,
                                            variantError: classes.error,
                                            variantWarning: classes.warning,
                                            variantInfo: classes.info,
                                        }}
                                    >
                                        <Routes />
                                    </SnackbarProvider>
                                </Router>
                            )}
                        </Provider>
                    </ThemeProvider>
                </ThemeContext.Provider>
            </StyledEngineProvider>
        </LocalizationProvider>
    );
};

App.propTypes = {
    history: PropTypes.object,
    store: PropTypes.object,
    theme: PropTypes.object,
    closeSnackbar: PropTypes.func,
};

export default App;
